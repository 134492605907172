import React, { useEffect, useState, } from 'react';

import {
  Button,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpload } from "@fortawesome/free-solid-svg-icons";
import fileDialog from 'file-dialog';


const ImageUploader = (props) => {

  const { onChange, img, round } = props;

  const [image, setImage] = useState(img);

  useEffect(() => {
    setImage(img);
  }, [img]);

  const handleUpload = () => {
    fileDialog({ accept: 'image/*' })
      .then(file => {
        let reader = new FileReader();

        reader.onload = (e) => {
          setImage([reader.result]);
        }

        reader.readAsDataURL(file[0]);

        onChange(file[0]);
      });
  }


  return (
    <div className="d-flex flex-column align-items-center">
      {
        round ?
          <div
            className="rounded-circle img-responsive mt-2"
            style={{ width: 128, height: 128, backgroundImage: `url(${image})`, backgroundSize: '100% 100%', margin: '0 auto', backgroundColor: 'gray' }}
          />
          :
          <div
            className="img-responsive mt-2"
            style={{ borderRadius: '6px', width: 200, height: 136, backgroundImage: `url(${image})`, backgroundSize: '100% 100%', backgroundColor: 'gray', margin: '0 auto', }}
          />
      }

      <div className="mt-2">
        <Button color="primary" onClick={() => handleUpload()}>
          <FontAwesomeIcon icon={faUpload} /> Upload
        </Button>
      </div>
    </div>
  );
}

export default ImageUploader;