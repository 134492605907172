import React from 'react';
import { Button } from 'reactstrap';
import {
  FaFacebook,
  FaGoogle,
  FaApple,
} from 'react-icons/fa';


const SocialSignButton = (props) => {
  const { google, facebook, apple, style, bgColor, children, onClick } = props;

  return (
    <Button className="social-sign-button" color={bgColor} onClick={onClick}>
      {google ? <FaGoogle size={18} /> : null}
      {facebook ? <FaFacebook size={18} /> : null}
      {apple ? <FaApple size={18} /> : null}
      <span style={{ width: 8 }}></span>
      {children}
    </Button>
  );
}

export default SocialSignButton;