import * as types from "../constants";

export function sessionLogin(userId, token) {
  return {
    type: types.SESSION_LOGIN,
    userId: userId,
    token: token,
  };
}

export function sessionLogout() {
  return {
    type: types.SESSION_LOGOUT,
  };
}

export function filterRecipes() {
  return {
    type: types.RECIPES_FILTER,
  };
}

export function filterRecipeOptions(filterOptions) {
  return {
    type: types.RECIPES_OPTION,
    filterOptions: filterOptions,
  };
}

export function updateKeyword(keyword) {
  return {
    type: types.KEYWORD_UPDATE,
    keyword: keyword,
  };
}

export function updateProfile(profile) {
  return {
    type: types.PROFILE_UPDATE,
    profile: profile,
  }
}
