import React, { useState } from 'react';
import {
  Button,
  Row,
  Form,
  Input,
} from 'reactstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRight,
  faArrowLeft,
} from "@fortawesome/free-solid-svg-icons";
import {
  ChevronRight as ChevronRightIcon,
  ChevronLeft as ChevronLeftIcon,
} from "react-feather";


const Pagination = (props) => {

  const { value, maxPage, onChange, } = props;

  const [page, setPage] = useState(value);

  const handlePageChange = (page) => {
    if (/^\d*$/g.test(page)) {
        setPage(page);
    }
  }

  const handlePrev = () => {
    if (page <= 1)
      return;

    let nval = parseInt(page);
    setPage(nval !== NaN ? nval - 1 : 1);
    onChange(nval !== NaN ? nval - 1 : 1);
  }

  const handleNext = () => {
    if (page >= maxPage)
      return;

    let nval = parseInt(page);
    setPage(nval !== NaN ? nval + 1 : 1);
    onChange(nval !== NaN ? nval + 1 : 1);
  }

  const handleSubmit = (e) => {
    let nval = 1;
    if (page !== "")
      nval = parseInt(page);
    if (page >= 1 && page <= maxPage)
      onChange(nval !== NaN ? nval : 1);

    e.preventDefault();
  }
  return (
    <Form className="d-flex justify-content-end align-items-center mt-4" onSubmit={e => handleSubmit(e)}>
      {/* <Button color="info mr-4" style={{ width: 210, height: 50, color: '#3490DD', borderRadius: 100, }}>
        <FontAwesomeIcon icon={faArrowLeft} className="mr-3" />
        Previous page
      </Button>
      <Button color="primary" style={{ width: 210, height: 50, marginRight: 30, borderRadius: 100, }}>
        Next page
        <FontAwesomeIcon icon={faArrowRight} className="ml-3" />
      </Button> */}
      {/* <div className="d-flex align-items-center" style={{ minWidth: 160, }}> */}
      Page
      <Input
        type="text"
        placeholder="Search Food"
        aria-label="Search"
        className="form-control-no-border p-0 text-center"
        style={{ margin: '0 10px', color: 'black', width: 28, borderRadius: 6, fontSize: 12, }}
        value={page}
        onChange={(val) => handlePageChange(val.target.value)}
      />
      of {maxPage}
      <Button className="d-flex justify-content-center align-items-center p-0 ml-1" color="warning" style={{ width: 20, height: 24, borderRadius: '6px 0 0 6px' }} onClick={handlePrev}>
        <ChevronLeftIcon className="align-middle" size={14} />
      </Button>
      <Button className="d-flex justify-content-center align-items-center p-0" color="warning" style={{ width: 20, height: 24, borderRadius: '0 6px 6px 0' }} onClick={handleNext}>
        <ChevronRightIcon className="align-middle" size={14} />
      </Button>
      {/* </div> */}
    </Form>
  );
}

export default Pagination;