import Login from "../pages/auth/Login";
import Signup from "../pages/auth/Signup";
import MealPlanner from "../pages/mealplanner";
import Groceries from "../pages/groceries";
import Preferences from "../pages/preferences";
import Parameters from "../pages/parameters";
import { Ingredients, IngredientDetails } from "../pages/ingredients";
import { RDDs, RDDDetails } from "../pages/rdds";
import { Recipes, RecipeDetails, Evaluation } from "../pages/recipes";
import NeedHelp from "../pages/needhelp";
import ShareWithFriends from "../pages/sharewithfriends";
import Votes from "../pages/votes";
import { Users } from '../pages/users';
import { Evaluations } from '../pages/evaluations';

import ForgotPassword from "../pages/auth/ForgotPassword";
import ConfirmUser from "../pages/auth/ConfirmUser";
import ResetPassword from "../pages/auth/ResetPassword";
import Page404 from "../pages/auth/Page404";
import Page500 from "../pages/auth/Page500";

import MealPlannerIcon from '../assets/img/meal-planner.png';
import GroceriesIcon from '../assets/img/groceries.png';
import PreferencesIcon from '../assets/img/preferences.png';
import NeedHelpIcon from '../assets/img/need-help.png';
import ShareWithFriendsIcon from '../assets/img/share-with-friends.png';
import IngredientsIcon from '../assets/img/ingredients.png';
import RDDIcon from '../assets/img/rdd.png';
import RecipesIcon from '../assets/img/recipes.png';

// Routes

const authRoutes = {
  path: "/admin/auth",
  name: "Auth",
  children: [
    {
      path: "/admin/auth/sign-in",
      name: "Login",
      exact: true,
      component: Login,
    },

    {
      path: "/admin/auth/sign-up",
      name: "Signup",
      exact: true,
      component: Signup,
    },

    {
      path: "/admin/auth/forgot-password",
      name: "Forgot Password",
      exact: true,
      component: ForgotPassword
    },

    {
      path: "/admin/auth/confirm-user",
      name: "Confirm Email",
      exact: false,
      component: ConfirmUser
    },

    {
      path: "/admin/auth/reset-password",
      name: "Reset Password",
      exact: false,
      component: ResetPassword
    },

    {
      path: "/admin/auth/404",
      name: "404 Page",
      exact: true,
      component: Page404
    },
    {
      path: "/admin/auth/500",
      name: "500 Page",
      exact: true,
      component: Page500
    }
  ]
};

const mealPlannerRoute = {
  path: "/admin/mealplanner",
  name: "Meal Planner",
  badgeColor: "primary",
  component: MealPlanner,
  icon: MealPlannerIcon,
  containsHome: true,
  exact: true,
};

const groceriesRoute = {
  path: "/admin/groceries",
  name: "Groceries",
  badgeColor: "primary",
  component: Groceries,
  icon: GroceriesIcon,
  exact: true,
};

const preferencesRoute = {
  path: "/admin/preferences",
  name: "Preferences",
  component: Preferences,
  icon: PreferencesIcon,
  exact: true,
  // children: [
  //   {
  //     path: "/admin/preferences/diet&nutrition",
  //     name: "Diet & Nutrition",
  //     exact: true,
  //     component: Preferences
  //   },
  //   {
  //     path: "/admin/preferences/meals&layout",
  //     name: "Meals & Layout",
  //     exact: true,
  //     component: Preferences
  //   },
  //   {
  //     path: "/admin/preferences/savedplans&ratings",
  //     name: "Saved Plans & Ratings",
  //     exact: true,
  //     component: Preferences
  //   }
  // ]
};

const parametersRoute = {
  path: "/admin/parameters",
  name: "Parameters",
  component: Parameters,
  icon: IngredientsIcon,
  exact: true,
};

const ingredientsRoute = {
  path: "/admin/ingredients",
  name: "Ingredients",
  badgeColor: "primary",
  component: Ingredients,
  icon: IngredientsIcon,
  exact: true,
};

const ingredientDetailsRoute = {
  path: "/admin/ingredient/detail/:id?",
  name: "IngredientDetails",
  component: IngredientDetails,
  exact: false,
};

const rddsRoute = {
  path: "/admin/rdds",
  name: "RDDs",
  badgeColor: "primary",
  component: RDDs,
  icon: RDDIcon,
  exact: true,
};

const rddDetailsRoute = {
  path: "/admin/rdd/detail/:id?-:type?",
  name: "RDDDetails",
  component: RDDDetails,
  exact: true,
};

const recipesRoute = {
  path: "/admin/recipes",
  name: "Recipes",
  badgeColor: "primary",
  component: Recipes,
  icon: RecipesIcon,
  exact: true,
  divider: true,
  // search: true,
};

const recipeDetailsRoute = {
  path: "/admin/recipe/detail/:id?",
  name: "RecipeDetails",
  component: RecipeDetails,
  exact: true,
};

const recipeEvalDetailsRoute = {
  path: "/admin/recipe/evaluation/:id?",
  name: "RecipeEvalDetails",
  component: Evaluation,
  exact: true,
};

const searchRoute = {
  path: "/admin/search",
  name: "Groceries",
  badgeColor: "primary",
  component: Groceries,
  icon: GroceriesIcon,
  exact: true,
};

const needHelpRoute = {
  path: "/admin/help",
  name: "Need help?",
  badgeColor: "primary",
  component: NeedHelp,
  icon: NeedHelpIcon,
  exact: true,
};

const shareWithFriendsRoute = {
  path: "/admin/share-with-friends",
  name: "Share With Friends",
  badgeColor: "primary",
  component: ShareWithFriends,
  icon: ShareWithFriendsIcon,
  exact: true,
};

const votesRoute = {
  path: "/admin/recipe/detail/:id?/votes",
  name: "Votes",
  component: Votes,
  icon: ShareWithFriendsIcon,
  exact: false,
};

const usersRoute = {
  path: "/admin/users",
  name: "Users",
  component: Users,
  icon: ShareWithFriendsIcon,
  exact: true,
};

const evaluationsRoute = {
  path: "/admin/evaluations",
  name: "Evaluations",
  component: Evaluations,
  icon: ShareWithFriendsIcon,
  exact: true,
};

// Dashboard specific routes
export const sidebar = [
  // mealPlannerRoute,
  parametersRoute,
  // groceriesRoute,
  // preferencesRoute,
  ingredientsRoute,
  {...ingredientsRoute, path: '/admin/ingredients/page=:page?'},
  ingredientDetailsRoute,
  rddsRoute,
  {...rddsRoute, path: '/admin/rdds/page=:page?'},
  rddDetailsRoute,
  recipesRoute,
  {...recipesRoute, path: '/admin/recipes/page=:page?'},
  recipeDetailsRoute,
  needHelpRoute,
  shareWithFriendsRoute,
  usersRoute,
  {...usersRoute, path: '/admin/users/page=:page?'},
  evaluationsRoute,
  votesRoute,
  recipeEvalDetailsRoute
];

// Home specific routes
export const auth = [authRoutes];

// All routes
export default [
  // mealPlannerRoute,
  parametersRoute,
  {...usersRoute, path: '/admin/users/page=1'},
  evaluationsRoute,
  // groceriesRoute,
  // preferencesRoute,
  {...ingredientsRoute, path: '/admin/ingredients/page=1'},
  {...rddsRoute, path: '/admin/rdds/page=1'},
  {...recipesRoute, path: '/admin/recipes/page=1'},
  needHelpRoute,
  shareWithFriendsRoute,
];
