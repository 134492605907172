import React, { useEffect } from "react";
import { BrowserRouter as Router, Redirect, Route, Switch } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { ApiService } from "../services/apiService";
import {
  auth as authRoutes,
  sidebar as sidebarRoutes,
} from "./index";
import {
  setFields,
  setLanguages,
  setCalcTypes,
  setTabs,
  setGroups,
  setGroceryTypes,
  setCuisines,
  setVegetarianTypes,
  setDifficultyLevels,
  setCountries,
  setMealLevels,
  setRDDInfo,
} from "../redux/actions/apiActions";
import DashboardLayout from "../layouts/Dashboard";
import HomeLayout from "../layouts/Home";
import AuthLayout from "../layouts/Auth";
import Page404 from "../pages/auth/Page404";
import ScrollToTop from "../components/ScrollToTop";
import { Auth } from "aws-amplify";
import { sessionLogin } from "../redux/actions/sessionActions";


const childRoutes = (Layout, routes) => (
  routes.map(({ children, path, exact, component: Component }, index) =>
    children ? (
      // Route item with children
      children.map(({ path, exact, component: Component }, index) => (
        <Route
          key={index}
          path={path}
          exact={exact}
          render={props => (
            <Layout>
              <Component {...props} />
            </Layout>
          )}
        />
      ))
    ) : (
      // Route item without children
      <Route
        key={index}
        path={path}
        exact={exact}
        render={props => (
          <Layout>
            <Component {...props} />
          </Layout>
        )}
      />
    )
  )
);


const Routes = () => {

  const userId = useSelector(state => state.session.userId);

  const dispatch = useDispatch();

  console.log('routes', userId);

  useEffect(() => {
    console.log("restoring session");
    Auth.currentSession()
      .then(sess => {
        sessionLogin(sess.idToken.payload.id, 'Bearer ' + sess.idToken.jwtToken);
      })
  }, []);

  useEffect(() => {
    console.log('routes========================');
    if (userId) {
      ApiService.getFields()
        .then(data => {
          console.log('fields:', data);
          dispatch(setFields(data));
        })
        .catch((err) => {
          console.log('fields error:', err);
        });

      ApiService.getGroceryTypes()
        .then(data => {
          console.log('groceryTypes:', data);
          dispatch(setGroceryTypes(data));
        })
        .catch((err) => {
          console.log('groceries error:', err);
        });

      ApiService.getLanguages()
        .then(data => {
          console.log('languages: ', data);
          dispatch(setLanguages(data));
        })
        .catch((err) => {
          console.log('languages error:', err);
        });

      ApiService.getCalcTypes()
        .then(data => {
          console.log('calcTypes: ', data);
          dispatch(setCalcTypes(data));
        })
        .catch((err) => {
          console.log('calcTypes error:', err);
        });

      ApiService.getTabs()
        .then(data => {
          console.log('tabs:', data);
          dispatch(setTabs(data));
        })
        .catch((err) => {
          console.log('tabs error:', err);
        });

      ApiService.getGroups()
        .then(data => {
          console.log('groups:', data);
          dispatch(setGroups(data));
        })
        .catch((err) => {
          console.log('groups error:', err);
        });

      ApiService.getCuisines()
        .then(data => {
          console.log('cuisines:', data);
          dispatch(setCuisines(data));
        })
        .catch((err) => {
          console.log('cuisines error:', err);
        });

      ApiService.getVegetarianTypes()
        .then(data => {
          console.log('vegetarianTypes:', data);
          dispatch(setVegetarianTypes(data));
        })
        .catch((err) => {
          console.log('vegetarianTypes error:', err);
        });

      ApiService.getDifficultyLevels()
        .then(data => {
          console.log('difficultyLevels:', data);
          dispatch(setDifficultyLevels(data));
        })
        .catch((err) => {
          console.log('difficultyLevels error:', err);
        });

      ApiService.getCountries()
        .then(data => {
          console.log('countries:', data);
          dispatch(setCountries(data));
        })
        .catch((err) => {
          console.log('countries error:', err);
        });

        ApiService.getMealLevels()
        .then(data => {
          console.log('mealLevels:', data);
          dispatch(setMealLevels(data));
        })
        .catch((err) => {
          console.log('mealLevels error:', err);
        });

        ApiService.getRDDs()
        .then(data => {
          console.log('RDDs:', data);
          dispatch(setRDDInfo(data));
        })
        .catch((err) => {
          console.log('RDDs error:', err);
        });
    }
  }, [userId]);

  return (
    <Router>
      <ScrollToTop>
        <Switch>
          {userId ? childRoutes(DashboardLayout, sidebarRoutes) : childRoutes(HomeLayout, authRoutes)}
          <Redirect from="/" to={userId ? "/admin/parameters" : "/admin/auth/sign-in"} exact={false} />
          <Route
            render={() => (
              <AuthLayout>
                <Page404 />
              </AuthLayout>
            )}
          />
        </Switch>
      </ScrollToTop>
    </Router>
  );
}
export default Routes;
