import React, { useState, useEffect } from "react";
import classnames from "classnames";
import { useSelector, useDispatch } from 'react-redux';
import {
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Container,
  Spinner,
} from "reactstrap";
import { useHistory } from "react-router";

import { ApiService } from "../../services/apiService";
import BackButton from '../../components/BackButton';
import { showToastr } from '../../services/themeService';

import { sessionLogout } from '../../redux/actions/sessionActions';

import Image from '../../assets/img/tmp/coconut-milk.png';


const IngredientDetails = (props) => {

  const dispatch = useDispatch();
  const history = useHistory();

  const { id } = props.match.params;
  const page = props.history.location.state?.page;
  const sortCol = props.history.location.state?.sortCol;
  const sortDirection = props.history.location.state?.sortDirection;

  console.log(sortCol, sortDirection);

  const ingredientInfo = useSelector((state) => state.api.ingredientInfo);
  const fields = useSelector((state) => state.api.fields);
  const tabs = useSelector((state) => state.api.tabs);
  const groups = useSelector((state) => state.api.groups);
  const groceries = useSelector((state) => state.api.groceryTypes);

  const [ingredient, setIngredient] = useState(null);
  const [activeTab, setActiveTab] = useState(1);

  const defaultLangId = 1;

  const bigVersion = (imageUrl) => {
    if (!imageUrl)
      return imageUrl;
      
    const lastIndex = imageUrl.lastIndexOf(".");
    const name = imageUrl.slice(0, lastIndex);
    const extension = imageUrl.slice(lastIndex, imageUrl.length);
    return name + "-big" + extension;
  }

  useEffect(() => {
    
    if (!ingredientInfo || ingredientInfo.ingredients.find(ingredient => ingredient.code === id) === undefined) {
      ApiService.getIngredient(id)
        .then(data => {
          // if (data && data.message === "Unauthenticated.") {
          //   history.push('/admin');

          //   return;
          // }
          setIngredient(data);
        })
        .catch((err) => {
          console.log('catch');
          console.error(err);
          if (err.response.status === 401) {
            showToastr('warning', 'Authorization Error', err.response.data.message);
            dispatch(sessionLogout());

            history.push('/');
            return;
          }
        });
    } else {
      setIngredient(ingredientInfo.ingredients.find(ingredient => ingredient.code === id));
    }
  }, [ingredientInfo]);

  const getFieldNamed = (fieldContainer, fieldName) => {
    let fieldKey = Object.keys(fields).find(key => fields[key].name === fieldName);
    if (fieldKey === undefined)
      return undefined;
    
    let result = fieldContainer.fields.find(f => f.id == fieldKey);
    if (result === undefined)
      return undefined; 
    return result.value;
  }

  if (!ingredient || !fields || !tabs) {
    return (
      <Container fluid className="vh-50 d-flex justify-content-center align-items-center">
        <Spinner color="primary" size="lg" className="mr-2" />
      </Container>
    );
  }

  let tabArray = new Array();
  let groupArray = new Array();
  let tabId, tabName;
  let groupId, groupName;
  ingredient.fields.filter(field => {
    tabId = fields[field.id].tabId;
    tabName = tabs[tabId][defaultLangId];
    if (tabName === 'Base' || tabName === 'Micro' || tabName === 'Others' || tabArray.find(tab => tab.id === tabId) !== undefined)
      return false;

    groupArray = [];
    Object.values(fields).filter(field => field.tabId === tabId)
      .map(field => {
        groupId = field.groupId;
        groupName = groups[groupId][defaultLangId];

        if (groupArray.find(group => group.id === groupId) === undefined) {
          groupArray.push({
            id: groupId,
            name: groupName,
          });
        }
      });

    tabArray.push({
      id: tabId,
      name: tabName,
      group: groupArray,
    });

    return true;
  });


  return (
    <div style={{ padding: '0 30px' }}>
      <BackButton to={`/admin/ingredients/page=${page ? page : 1}`} params={{ sortCol, sortDirection }} />
      <Nav tabs>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === 1 })}
            onClick={() => {
              setActiveTab(1);
            }}
          >
            Ingredient Base Information
          </NavLink>
        </NavItem>
        {
          tabArray.map((tab, index) => (
            <NavItem key={index}>
              <NavLink
                className={classnames({ active: activeTab === tab.id })}
                onClick={() => {
                  setActiveTab(tab.id);
                }}
              >
                {tab.name}
              </NavLink>
            </NavItem>
          ))
        }
      </Nav>

      <TabContent activeTab={activeTab} style={{ padding: '50px 0' }}>
        <TabPane tabId={1}>
          <div style={{ padding: 20, backgroundColor: 'white', display: 'inline-block', borderRadius: 6, }}>
            <img src={bigVersion(ingredient.image) ?? Image} style={{ borderRadius: 6, maxHeight: 200, }} onError={function(e) { e.target.onError = null; e.target.src = ingredient.image;}}></img>
            <h3 className="tab-title mt-4 mb-3">
              {ingredient.description[defaultLangId]}
            </h3>
            <div style={{ color: '#6B6C72', fontSize: 14, fontWeight: 400, }}>
              <p className="mb-3">
                <span style={{ color: '#3490DD', marginRight: 5, }}>
                  Database:
                </span>
                <span>
                  {ingredient.sourceDatabase}
                </span>
              </p>
              <p className="mb-3">
                <span style={{ color: '#3490DD', marginRight: 5, }}>
                  Code:
                </span>
                <span>
                  {ingredient.code}
                </span>
              </p>
              <p className="mb-3">
                <span style={{ color: '#3490DD', marginRight: 5, }}>
                  Friendly Description:
                </span>
                <span>
                  {ingredient.friendlyDescription[defaultLangId]}
                </span>
              </p>
              <p className="mb-3">
                <span style={{ color: '#3490DD', marginRight: 5, }}>
                  Groceries Description:
                </span>
                <span>
                  {groceries?.find?.(grocery => grocery.id == ingredient.groceryTypeId)?.description ?? "N/A"}
                </span>
              </p>
              <p className="mb-3">
                <span style={{ color: '#3490DD', marginRight: 5, }}>
                  Category ID:
                </span>
                <span>
                  {ingredient.categoryCode}
                </span>
              </p>
              <p className="mb-4">
                <span style={{ marginRight: 5, }}>
                  Category:
                </span>
                <span style={{ fontWeight: 'bold', }}>
                  {ingredient.categoryDescription[defaultLangId]}
                </span>
              </p>
              <p className="mb-1">
                Calorie
              </p>
              <p style={{ fontSize: 30, color: 'black', fontWeight: 'bold', }}>
                {getFieldNamed(ingredient, "energy_cal")}
              </p>
            </div>
          </div>
        </TabPane>
        {
          tabArray.map((tab, index) => {
            return (
              <TabPane tabId={tab.id} key={index}>
                <div className="row m-0">
                  {
                    tab.group.map((group, index) => (
                      <div className="col-md-6" style={{ padding: '0 15px', }} key={index}>
                        <div style={{ padding: '20px 30px', backgroundColor: 'white', borderRadius: 6, color: '#6B6C72', fontSize: 14, fontWeight: 400, }}>
                          <p style={{ fontSize: 24, fontWeight: 600, color: 'black', padding: '0 30px' }}>
                            {group.name}
                          </p>
                          {
                            ingredient.fields.map((field, index) => (
                              fields[field.id].tabId === tab.id && fields[field.id].groupId === group.id ? (
                                <div className="d-flex justify-content-between" style={{ padding: '15px 30px', borderBottom: '1px solid rgba(7, 18, 46, 0.1)', }} key={index}>
                                  <span>
                                    {`${fields[field.id].display} (${fields[field.id].unit})`}
                                  </span>
                                  <span>
                                    {field.value}
                                  </span>
                                </div>
                              ) : null
                            ))
                          }
                        </div>
                      </div>
                    ))
                  }
                </div>
              </TabPane>
            );
          })
        }
      </TabContent>
    </div >
  );
}

export default IngredientDetails;
