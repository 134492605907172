import React, { useState } from "react";
import { useSelector } from 'react-redux';
import { Pie } from "react-chartjs-2";

import {
  Button,
  Container,
  Spinner,
} from "reactstrap";

import { ChevronDown, ChevronUp } from "react-feather";

const PieChart = (props) => {

  const { data } = props;
  const fields = useSelector((state) => state.api.fields);

  const [more, setMore] = useState(false);


  if (!fields) {
    return (
      <Container fluid className="d-flex justify-content-center align-items-center" style={{ height: 405, }}>
        <Spinner color="primary" size="lg" className="mr-2" />
      </Container>
    );
  }

  const energyId = parseInt(Object.entries(fields).find(field => field[1].name === 'energy_cal')?.[0]);
  const carbId = parseInt(Object.entries(fields).find(field => field[1].name === 'carbohydrate')?.[0]);
  const fatId = parseInt(Object.entries(fields).find(field => field[1].name === 'fat_total')?.[0]);
  const proteinId = parseInt(Object.entries(fields).find(field => field[1].name === 'protein')?.[0]);
  const sugarsId = parseInt(Object.entries(fields).find(field => field[1].name === 'sugars_total')?.[0]);
  const fiberId = parseInt(Object.entries(fields).find(field => field[1].name === 'fiber')?.[0]);

  const energy = Math.round(data?.find(item => item.fieldId === energyId)?.value);
  const carb = parseFloat(data?.find(item => item.fieldId === carbId)?.value?.toFixed(1));
  const fat = parseFloat(data?.find(item => item.fieldId === fatId)?.value?.toFixed(1));
  const protein = parseFloat(data?.find(item => item.fieldId === proteinId)?.value?.toFixed(1));
  const sugars = parseFloat(data?.find(item => item.fieldId === sugarsId)?.value?.toFixed(1));
  const fiber = parseFloat(data?.find(item => item.fieldId === fiberId)?.value?.toFixed(1));

  let other = 0;
  if (data) {
    data.filter(item => item.fieldId !== energyId && item.fieldId !== carbId && item.fieldId !== fatId && item.fieldId !== proteinId && item.fieldId !== sugarsId && item.fieldId !== fiberId)
      .map(item => {
        const unit = fields[item.fieldId].unit;
        // const unitVal = unit.includes('mc') ? 1000000 : unit.includes('m') ? 1000 : 1;
        const conversion = {
          "g": 1,
          "mg": 1000,
          "mcg": 1000000,
          "µg": 1000000
        };
        other += item.value / conversion[unit];
      })
  }
  other = parseFloat(other.toFixed(1));

  const option = {
    labels: ["Carbohydrate", "Fat", "Protein", "Fiber", "Other"],
    datasets: [
      {
        data: [
          carb,
          fat,
          protein,
          fiber,
          other,
        ],
        backgroundColor: [
          "#3490DD",
          "#FF7F87",
          "#354052",
          "#418200",
          "gray",
        ],
        borderWidth: 2
      }
    ]
  };

  const options = {
    maintainAspectRatio: false,
    cutoutPercentage: 70,
    legend: {
      display: false
    },
  };

  const field_order = [
    // "protein",
    // "fiber",
    // "carbohydrate",
    // "sugars_total",
    // "fat_total",
    "fatty_acids_saturated",
    "fatty_acids_monounsaturated",
    "fatty_acids_polyunsaturated",
    "vitamin_a_rae",
    "vitamin_c",
    "vitamin_d_d2andd3",
    "vitamin_e",
    "vitamin_k",
    "thiamin",
    "riboflavin",
    "niacin",
    "vitamin_b6",
    "folate",
    "vitamin_b12",
    "calcium",
    "iron",
    "magnesium",
    "phosphorus",
    "selenium",
    "zinc",
    "potassium",
    "sodium"
  ];

  console.log('data', data);
  if (!data) {
    return <div></div>
  }

  return (
    <div>
      <div className="align-self-center w-100" style={{ position: 'relative', }}>
        <div className="chart chart-md">
          <Pie data={option} options={options} />
        </div>
        <div className="d-inline-block" style={{ position: 'absolute', top: '50%', marginTop: -37, left: '50%', marginLeft: -27, }}>
          <p style={{ fontSize: 16, marginBottom: 5, }}>
            Calorie
          </p>
          <p style={{ fontSize: 30, fontWeight: 'bold', color: '#07122E', marginBottom: 0, textAlign: 'center', }}>
            {energy}
          </p>
        </div>
      </div>
      <div style={{ paddingLeft: 15, paddingRight: more ? 0 : 15, }}>
        {
          more ? (
            <div style={{ height: 500, overflowY: 'scroll', marginBottom: 15, }}>
              <p>
                <span style={{ fontSize: 16, fontWeight: 'bold', color: 'black', }}>
                  Calorie
                </span>
                <span className="float-right">
                  {energy}
                </span>
              </p>
              <p>
                <span style={{ fontWeight: 'bold', }}>
                  Protein
                </span>
                <span className="float-right">
                  {protein ? `${protein.toFixed(1)} ${fields[proteinId].unit}` : ''}
                </span>
              </p>
              <p style={{ color: '#418200', }}>
                <span style={{ fontWeight: 'bold', }}>
                  Fiber
                </span>
                <span className="float-right">
                  {fiber ? `${fiber.toFixed(1)} ${fields[fiberId].unit}` : ''}
                </span>
              </p>
              <p style={{ color: '#3490DD', }}>
                <span style={{ fontWeight: 'bold', }}>
                  Carbohydrate
                </span>
                <span className="float-right">
                  {carb ? `${carb.toFixed(1)} ${fields[carbId].unit}` : ''}
                </span>
              </p>
              <p>
                <span style={{ fontWeight: 'bold', }}>
                  Sugar
                </span>
                <span className="float-right">
                  {sugars ? `${sugars.toFixed(1)} ${fields[sugarsId].unit}` : ''}
                </span>
              </p>
              <p style={{ color: '#FF7F87', }}>
                <span style={{ fontWeight: 'bold', }}>
                  Fat
                </span>
                <span className="float-right">
                  {fat ? `${fat.toFixed(1)} ${fields[fatId].unit}` : ''}
                </span>
              </p>
              {
                /*
                data?.filter(item => item.fieldId !== energyId && item.fieldId !== carbId && item.fieldId !== fatId && item.fieldId !== proteinId)
                  .map((item, index) => (
                    <p key={index} style={{ color: 'gray', }}>
                      <span style={{ fontWeight: 'bold', }}>
                        {fields[item.fieldId].display}
                      </span>
                      <span className="float-right">
                        {`${item.value.toFixed(1)} ${fields[item.fieldId].unit}`}
                      </span>
                    </p>
                  ))
                  */
                field_order.map((field_name, index) => {
                  const [fieldId, field] = Object.entries(fields).find(([k, v]) => v.name == field_name);
                  const value = data.find(f => f.fieldId == fieldId).value;
                  return (
                    <p key={index} style={{ color: 'gray', }}>
                      <span style={{ fontWeight: 'bold', }}>
                        {field.display}
                      </span>
                      <span className="float-right">
                        {`${value.toFixed(1)} ${field.unit}`}
                      </span>
                    </p>
                  )
                })
              }
            </div>
          ) : (
            <div>
              <p>
                <span style={{ fontSize: 16, fontWeight: 'bold', color: 'black', }}>
                  Calorie
                </span>
                <span className="float-right">
                  {energy}
                </span>
              </p>
              <p>
                <span style={{ fontWeight: 'bold', }}>
                  Protein
                </span>
                <span className="float-right">
                  {protein ? `${protein.toFixed(1)} ${fields[proteinId].unit}` : ''}
                </span>
              </p>
              <p style={{ color: '#418200', }}>
                <span style={{ fontWeight: 'bold', }}>
                  Fiber
                </span>
                <span className="float-right">
                  {fiber ? `${fiber.toFixed(1)} ${fields[fiberId].unit}` : ''}
                </span>
              </p>
              <p style={{ color: '#3490DD', }}>
                <span style={{ fontWeight: 'bold', }}>
                  Carbohydrate
                </span>
                <span className="float-right">
                  {carb ? `${carb.toFixed(1)} ${fields[carbId].unit}` : ''}
                </span>
              </p>
              <p>
                <span style={{ fontWeight: 'bold', }}>
                  Sugar
                </span>
                <span className="float-right">
                  {sugars ? `${sugars.toFixed(1)} ${fields[sugarsId].unit}` : ''}
                </span>
              </p>
              <p style={{ color: '#FF7F87', }}>
                <span style={{ fontWeight: 'bold', }}>
                  Fat
                </span>
                <span className="float-right">
                  {fat ? `${fat.toFixed(1)} ${fields[fatId].unit}` : ''}
                </span>
              </p>
            </div>
          )
        }

        {
          more ? (
            <Button color="secondary" style={{ width: '100%', height: 34, marginRight: 30, borderRadius: 100, }} onClick={() => setMore(!more)}>
              View less
              <ChevronUp size={18} className="ml-2" />
            </Button>
          ) : (
            <Button color="secondary" style={{ width: '100%', height: 34, marginRight: 30, borderRadius: 100, }} onClick={() => setMore(!more)}>
              View more
              <ChevronDown size={18} className="ml-2" />
            </Button>
          )
        }

      </div>
    </div>
  );
};

export default PieChart;
