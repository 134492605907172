import { config } from '../config';
import { API, Auth } from 'aws-amplify';
import store from '../redux/store';

// function login(email, pass) {
//   const options = {
//     method: 'POST',
//     headers: {
//       'Accept': 'application/json',
//       'Content-Type': 'application/json;charset=UTF-8',
//       'X-Requested-With': 'XMLHttpRequest'
//     },
//     body: JSON.stringify({
//       type: 'admin',
//       name: email,
//       password: pass,
//     })
//   };

//   return fetch(config.API_URL + 'auth/login', options);
// }

// function logout(session) {
//   const options = {
//     method: 'GET',
//     headers: {
//       'Accept': 'application/json',
//       'Content-Type': 'application/json;charset=UTF-8',
//       'X-Requested-With': 'XMLHttpRequest',
//       'Authorization': session,
//     },
//   };

//   return fetch(config.API_URL + 'auth/logout', options);
// }

// function updateAdmin(session, profile) {
//   const data = new FormData();

//   console.log(profile.image);
//   console.log(typeof profile.image);
//   if (typeof profile.image != 'string' && profile.image)
//     data.append('file', profile.image);
//   data.append('id', profile.id);
//   data.append('name', profile.name);
//   data.append('email', profile.email);

//   if (config.DEV_MODE) {
//     const options = {
//       method: 'POST',
//       headers: {
//         'Accept': 'application/json',
//         'Authorization': session,
//       },
//       body: data
//     };

//     return fetch(config.API_URL + 'user/' + profile.id, options);
//   } else {
//     const apiName = 'dev-user-api';
//     const path = `/users/${profile.id}`;
//     const init = {
//       headers: {
//         'Accept': 'application/json',
//         'Content-Type': 'application/json;charset=UTF-8',
//         body: data
//       }
//     };

//     return API.post(apiName, path, init);
//   }
// }

// function forgotPass(email) {
//   const options = {
//     method: 'POST',
//     headers: {
//       'Accept': 'application/json',
//       'Content-Type': 'application/json;charset=UTF-8',
//       'X-Requested-With': 'XMLHttpRequest',
//     },
//     body: JSON.stringify({
//       email: email,
//     }),
//   };

//   return fetch(config.API_URL + 'pass/forgot', options);
// }

// function findToken(token) {
//   const options = {
//     method: 'GET',
//     headers: {
//       'Accept': 'application/json',
//       'Content-Type': 'application/json;charset=UTF-8',
//       'X-Requested-With': 'XMLHttpRequest',
//     },
//   };

//   return fetch(config.API_URL + 'pass/find/' + token, options);
// }

// function resetPass(email, pass, confirmPass, token) {
//   const options = {
//     method: 'POST',
//     headers: {
//       'Accept': 'application/json',
//       'Content-Type': 'application/json;charset=UTF-8',
//       'X-Requested-With': 'XMLHttpRequest',
//     },
//     body: JSON.stringify({
//       email: email,
//       password: pass,
//       password_confirmation: confirmPass,
//       token: token
//     }),
//   };

//   return fetch(config.API_URL + 'pass/reset', options);
// }

// function confirmEmail(token) {
//   const options = {
//     method: 'GET',
//     headers: {
//       'Accept': 'application/json',
//       'Content-Type': 'application/json;charset=UTF-8',
//       'X-Requested-With': 'XMLHttpRequest',
//     },
//   };

//   return fetch(config.API_URL + 'auth/confirm/' + token, options);
// }

async function getLanguages() {
	let token = store.getState().session.token;
	try { token = 'Bearer ' + (await Auth.currentSession()).idToken.jwtToken;} catch { }
  if (config.DEV_MODE) {
    const options = {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        'X-Requested-With': 'XMLHttpRequest',
        'Authorization': token,
      },
    };

    const response = await fetch(config.API_URL + 'languages/list', options);
    return response.json();
  } else {
    const apiName = 'dev-language-api';
    const path = '/languages/list';
    const init = {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        'Authorization': token,
      }
    };

    return API.get(apiName, path, init);
  }
}

async function createLanguage(name, code) {
	let token = store.getState().session.token;
	try { token = 'Bearer ' + (await Auth.currentSession()).idToken.jwtToken;} catch { }
  if (config.DEV_MODE) {
    const options = {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        'X-Requested-With': 'XMLHttpRequest',
        'Authorization': token,
      },
      body: JSON.stringify({
        name: name,
        code: code,
      })
    };

    const response = await fetch(config.API_URL + 'languages/create', options);
    return response.json();
  } else {
    const apiName = 'dev-language-api';
    const path = '/languages/create';
    const init = {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        'Authorization': token,
      },
      body: {
        name: name,
        code: code,
      }
    };

    return API.post(apiName, path, init);
  }
}

async function deleteLanguage(id) {
	let token = store.getState().session.token;
	try { token = 'Bearer ' + (await Auth.currentSession()).idToken.jwtToken;} catch { }
  if (config.DEV_MODE) {
    const options = {
      method: 'DELETE',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        'X-Requested-With': 'XMLHttpRequest',
        'Authorization': token,
      },
    };

    const response = await fetch(config.API_URL + 'languages/' + id, options);
    return response;
  } else {
    const apiName = 'dev-language-api';
    const path = `/languages/${id}`;
    const init = {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        'Authorization': token,
      }
    };

    return API.del(apiName, path, init);
  }
}

async function getFields() {
	let token = store.getState().session.token;
	try { token = 'Bearer ' + (await Auth.currentSession()).idToken.jwtToken;} catch { }
  if (config.DEV_MODE) {
    const options = {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        'X-Requested-With': 'XMLHttpRequest',
        'Authorization': token,
      },
    };

    const response = await fetch(config.API_URL + 'fields/list', options);
    return response.json();
  } else {
    const apiName = 'dev-field-api';
    const path = '/fields/list';
    const init = {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        'Authorization': token,
      }
    };

    return API.get(apiName, path, init);
  }
}

async function getTabs() {
	let token = store.getState().session.token;
	try { token = 'Bearer ' + (await Auth.currentSession()).idToken.jwtToken;} catch { }
  if (config.DEV_MODE) {
    const options = {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        'X-Requested-With': 'XMLHttpRequest',
        'Authorization': token,
      },
    };

    const response = await fetch(config.API_URL + 'tabs/list', options);
    return response.json();
  } else {
    const apiName = 'dev-tab-api';
    const path = '/tabs/list';
    const init = {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        'Authorization': token,
      }
    };

    return API.get(apiName, path, init);
  }
}

async function getGroups() {
	let token = store.getState().session.token;
	try { token = 'Bearer ' + (await Auth.currentSession()).idToken.jwtToken;} catch { }
  if (config.DEV_MODE) {
    const options = {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        'X-Requested-With': 'XMLHttpRequest',
        'Authorization': token,
      },
    };

    const response = await fetch(config.API_URL + 'groups/list', options);
    return response.json();
  } else {
    const apiName = 'dev-group-api';
    const path = '/groups/list';
    const init = {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        'Authorization': token,
      }
    };

    return API.get(apiName, path, init);
  }
}

async function getIngredients(page, count, sortField, sortDirection, filter, language) {
	let token = store.getState().session.token;
	try { token = 'Bearer ' + (await Auth.currentSession()).idToken.jwtToken;} catch { }
  if (config.DEV_MODE) {
    const options = {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        'X-Requested-With': 'XMLHttpRequest',
        'Authorization': token,
      },
      body: JSON.stringify({
        page: page,
        rowCount: count,
        sortFieldName: sortField,
        sortDirection: sortDirection,
        keyword: filter,
        language: language,
      })
    };

    const response = await fetch(config.API_URL + 'ingredients/list', options);
    return response.json();
  } else {
    const apiName = 'dev-ingredient-api';
    const path = '/ingredients/list';
    const init = {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        'Authorization': token,
      },
      body: {
        page: page,
        rowCount: count,
        sortFieldName: sortField,
        sortDirection: sortDirection,
        keyword: filter,
        language: language,
      }
    };

    return API.post(apiName, path, init);
  }
}

async function exportIngredients() {
	let token = store.getState().session.token;
	try { token = 'Bearer ' + (await Auth.currentSession()).idToken.jwtToken;} catch { }
  if (config.DEV_MODE) {
    const options = {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        'X-Requested-With': 'XMLHttpRequest',
        'Authorization': token,
      }
    };

    const response = await fetch(config.API_URL + 'ingredients/export', options);
    return response.json();
  } else {
    const apiName = 'dev-ingredient-api';
    const path = '/ingredients/export';
    const init = {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        'Authorization': token,
      }
    };

    return API.get(apiName, path, init);
  }
}

async function exportRecipes() {
	let token = store.getState().session.token;
	try { token = 'Bearer ' + (await Auth.currentSession()).idToken.jwtToken;} catch { }
  if (config.DEV_MODE) {
    const options = {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        'X-Requested-With': 'XMLHttpRequest',
        'Authorization': token,
      }
    };

    const response = await fetch(config.API_URL + 'meals/export', options);
    return response.json();
  } else {
    const apiName = 'dev-meal-api';
    const path = '/meals/export';
    const init = {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        'Authorization': token,
      }
    };

    return API.get(apiName, path, init);
  }
}

async function importIngredients(ingredients) {
	let token = store.getState().session.token;
	try { token = 'Bearer ' + (await Auth.currentSession()).idToken.jwtToken;} catch { }
  if (config.DEV_MODE) {
    const options = {
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        'X-Requested-With': 'XMLHttpRequest',
        'Authorization': token,
      },
      body: ingredients
    };

    const response = await fetch(config.API_URL + 'ingredients/import', options);
    return response.json();
  } else {
    const apiName = 'dev-ingredient-api';
    const path = '/ingredients/import';
    const init = {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        'Authorization': token,
      },
      body: ingredients
    };

    return API.put(apiName, path, init);
  }
}

async function importRecipes(recipes) {
	let token = store.getState().session.token;
	try { token = 'Bearer ' + (await Auth.currentSession()).idToken.jwtToken;} catch { }
  if (config.DEV_MODE) {
    const options = {
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        'X-Requested-With': 'XMLHttpRequest',
        'Authorization': token,
      },
      body: recipes
    };

    const response = await fetch(config.API_URL + 'meals/import', options);
    return response.json();
  } else {
    const apiName = 'dev-meal-api';
    const path = '/meals/import';
    const init = {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        'Authorization': token,
      },
      body: recipes
    };

    return API.put(apiName, path, init);
  }
}

async function getIngredient(code) {
	let token = store.getState().session.token;
	try { token = 'Bearer ' + (await Auth.currentSession()).idToken.jwtToken;} catch { }
  if (config.DEV_MODE) {
    const options = {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        'X-Requested-With': 'XMLHttpRequest',
        'Authorization': token,
      },
    };

    const response = await fetch(config.API_URL + 'ingredients/' + code, options);
    return response.json();
  } else {
    const apiName = 'dev-ingredient-api';
    const path = `/ingredients/${code}`;
    const init = {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        'Authorization': token,
      }
    };

    return API.get(apiName, path, init);
  }
}

async function deleteIngredient(code) {
	let token = store.getState().session.token;
	try { token = 'Bearer ' + (await Auth.currentSession()).idToken.jwtToken;} catch { }
  if (config.DEV_MODE) {
    const options = {
      method: 'DELETE',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        'X-Requested-With': 'XMLHttpRequest',
        'Authorization': token,
      },
    };

    const response = await fetch(config.API_URL + 'ingredients/' + code, options);
    return response.json();
  } else {
    const apiName = 'dev-ingredient-api';
    const path = `/ingredients/${code}`;
    const init = {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        'Authorization': token,
      }
    };

    return API.del(apiName, path, init);
  }
}

async function createIngredient(fieldValues) {
	let token = store.getState().session.token;
	try { token = 'Bearer ' + (await Auth.currentSession()).idToken.jwtToken;} catch { }
  if (config.DEV_MODE) {
    const options = {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        'X-Requested-With': 'XMLHttpRequest',
        'Authorization': token,
      },
      body: JSON.stringify(fieldValues)
    };

    const response = await fetch(config.API_URL + 'ingredients/create', options);
    return response.json();
  } else {
    const apiName = 'dev-ingredient-api';
    const path = `/ingredients/create`;
    const init = {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        'Authorization': token,
      },
      body: fieldValues
    };

    return API.post(apiName, path, init);
  }
}

async function updateIngredient(code, fieldValues) {
	let token = store.getState().session.token;
	try { token = 'Bearer ' + (await Auth.currentSession()).idToken.jwtToken;} catch { }
  if (config.DEV_MODE) {
    const options = {
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        'X-Requested-With': 'XMLHttpRequest',
        'Authorization': token,
      },
      body: JSON.stringify(fieldValues)
    };

    const response = await fetch(config.API_URL + 'ingredients/' + code, options);
    return response.json();
  } else {
    const apiName = 'dev-ingredient-api';
    const path = `/ingredients/${code}`;
    const init = {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        'Authorization': token,
      },
      body: fieldValues
    };

    return API.put(apiName, path, init);
  }
}

async function getRDDs(page, count, sortField, sortBy, filter) {
	let token = store.getState().session.token;
	try { token = 'Bearer ' + (await Auth.currentSession()).idToken.jwtToken;} catch { }
  if (config.DEV_MODE) {
    const options = {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        'X-Requested-With': 'XMLHttpRequest',
        'Authorization': token,
      },
      body: JSON.stringify({
        page: page,
        rowCount: count,
        sortFieldName: sortField,
        sortDirection: sortBy,
        keyword: filter,
      })
    };

    const response = await fetch(config.API_URL + 'rdds/list', options);
    return response.json();
  } else {
    const apiName = 'dev-rdd-api';
    const path = '/rdds/list';
    const init = {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        'Authorization': token,
      },
      body: {
        page: page,
        rowCount: count,
        sortFieldName: sortField,
        sortDirection: sortBy,
        keyword: filter,
      }
    };

    return API.post(apiName, path, init);
  }
}

async function getRDD(code, type) {
	let token = store.getState().session.token;
	try { token = 'Bearer ' + (await Auth.currentSession()).idToken.jwtToken;} catch { }
  if (config.DEV_MODE) {
    const options = {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        'X-Requested-With': 'XMLHttpRequest',
        'Authorization': token,
      },
      body: JSON.stringify({
        calcTypeId: type,
      }),
    };

    const response = await fetch(config.API_URL + 'rdds/' + code, options);
    return response.json();
  } else {
    const apiName = 'dev-rdd-api';
    const path = `/rdds/${code}`;
    const init = {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        'Authorization': token,
      },
      body: {
        calcTypeId: type
      }
    };

    return API.post(apiName, path, init);
  }
}

async function createRDD(rdd) {
	let token = store.getState().session.token;
	try { token = 'Bearer ' + (await Auth.currentSession()).idToken.jwtToken;} catch { }
  if (config.DEV_MODE) {
    const options = {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        'X-Requested-With': 'XMLHttpRequest',
        'Authorization': token,
      },
      body: JSON.stringify(rdd),
    };

    const response = await fetch(config.API_URL + 'rdds/create', options);
    return response.json();
  } else {
    const apiName = 'dev-rdd-api';
    const path = `/rdds/create`;
    const init = {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        'Authorization': token,
      },
      body: rdd
    };

    return API.post(apiName, path, init);
  }
}

async function deleteRDD(code) {
	let token = store.getState().session.token;
	try { token = 'Bearer ' + (await Auth.currentSession()).idToken.jwtToken;} catch { }
  if (config.DEV_MODE) {
    const options = {
      method: 'DELETE',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        'X-Requested-With': 'XMLHttpRequest',
        'Authorization': token,
      },
    };

    const response = await fetch(config.API_URL + 'rdds/' + code, options);
    return response.json();
  } else {
    const apiName = 'dev-rdd-api';
    const path = `/rdds/${code}`;
    const init = {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        'Authorization': token,
      },
    };

    return API.del(apiName, path, init);
  }
}

async function updateRDD(code, fieldValues) {
	let token = store.getState().session.token;
	try { token = 'Bearer ' + (await Auth.currentSession()).idToken.jwtToken;} catch { }
  if (config.DEV_MODE) {
    const options = {
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        'X-Requested-With': 'XMLHttpRequest',
        'Authorization': token,
      },
      body: JSON.stringify(fieldValues)
    };

    const response = await fetch(config.API_URL + 'rdds/' + code, options);
    return response.json();
  } else {
    const apiName = 'dev-rdd-api';
    const path = `/rdds/${code}`;
    const init = {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        'Authorization': token,
      },
      body: fieldValues
    };

    return API.put(apiName, path, init);
  }
}

async function getRecipes(page, count, sortField, sortBy, keyword, filter) {
	let token = store.getState().session.token;
	try { token = 'Bearer ' + (await Auth.currentSession()).idToken.jwtToken;} catch { }
  let data = {
    page: page,
    rowCount: count,
    sortFieldName: sortField,
    sortDirection: sortBy,
    keyword: keyword,
    ...filter,
  };

  if (config.DEV_MODE) {
    const options = {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        'X-Requested-With': 'XMLHttpRequest',
        'Authorization': token,
      },
      body: JSON.stringify(data)
    };

    const response = await fetch(config.API_URL + 'meals/list', options);
    return response.json();
  } else {
    const apiName = 'dev-meal-api';
    const path = '/meals/list';
    const init = {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        'Authorization': token,
      },
      body: data
    };

    return API.post(apiName, path, init);
  }
}

async function getRecipe(code) {
	let token = store.getState().session.token;
	try { token = 'Bearer ' + (await Auth.currentSession()).idToken.jwtToken;} catch { }
  if (config.DEV_MODE) {
    const options = {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        'X-Requested-With': 'XMLHttpRequest',
        'Authorization': token,
      },
    };

    const response = await fetch(config.API_URL + 'meals/' + code, options);
    return response.json();
  } else {
    const apiName = 'dev-meal-api';
    const path = `/meals/${code}`;
    const init = {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        'Authorization': token,
      },
    };

    return API.get(apiName, path, init);
  }
}

async function evaluateMeal(code, rdd, force) {
	let token = store.getState().session.token;
	try { token = 'Bearer ' + (await Auth.currentSession()).idToken.jwtToken;} catch { }
  if (config.DEV_MODE) {
    const options = {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        'X-Requested-With': 'XMLHttpRequest',
        'Authorization': token,
      },
      body: {
        force: force ?? true
      }
    };

    const response = await fetch(config.API_URL + `meals/${code}/evaluate/${rdd}`, options);
    return response.json();
  } else {
    const apiName = 'dev-meal-api';
    const path = `/meals/${code}/evaluate/${rdd}`;
    const init = {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        'Authorization': token,
      },
      body: {
        force: force ?? true
      }
    };

    return API.post(apiName, path, init);
  }
}

async function getRecipeEvaluationDetails(code) {
	let token = store.getState().session.token;
	try { token = 'Bearer ' + (await Auth.currentSession()).idToken.jwtToken;} catch { }
  if (config.DEV_MODE) {
    const options = {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        'X-Requested-With': 'XMLHttpRequest',
        'Authorization': token,
      },
    };

    const response = await fetch(config.API_URL + `meals/${code}/evaluation`, options);
    return response.json();
  } else {
    const apiName = 'dev-meal-api';
    const path = `/meals/${code}/evaluation`;
    const init = {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        'Authorization': token,
      },
    };

    return API.get(apiName, path, init);
  }
}

async function deleteRecipe(code) {
	let token = store.getState().session.token;
	try { token = 'Bearer ' + (await Auth.currentSession()).idToken.jwtToken;} catch { }
  if (config.DEV_MODE) {
    const options = {
      method: 'DELETE',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        'X-Requested-With': 'XMLHttpRequest',
        'Authorization': token,
      },
    };

    const response = await fetch(config.API_URL + 'meals/' + code, options);
    return response.json();
  } else {
    const apiName = 'dev-meal-api';
    const path = `/meals/${code}`;
    const init = {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        'Authorization': token,
      }
    };

    return API.del(apiName, path, init);
  }
}

async function createRecipe(recipe) {
	let token = store.getState().session.token;
	try { token = 'Bearer ' + (await Auth.currentSession()).idToken.jwtToken;} catch { }
  if (config.DEV_MODE) {
    const options = {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        'X-Requested-With': 'XMLHttpRequest',
        'Authorization': token,
      },
      body: JSON.stringify(recipe)
    };

    const response = await fetch(config.API_URL + 'meals/create', options);
    return response.json();
  } else {
    const apiName = 'dev-meal-api';
    const path = '/meals/create';
    const init = {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        'Authorization': token,
      },
      body: recipe
    };

    return API.post(apiName, path, init);
  }
}

async function updateRecipe(id, recipe) {
	let token = store.getState().session.token;
	try { token = 'Bearer ' + (await Auth.currentSession()).idToken.jwtToken;} catch { }
  if (config.DEV_MODE) {
    const options = {
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        'X-Requested-With': 'XMLHttpRequest',
        'Authorization': token,
      },
      body: JSON.stringify(recipe)
    };

    const response = await fetch(config.API_URL + 'meals/' + id, options);
    return response.json();
  } else {
    const apiName = 'dev-meal-api';
    const path = `/meals/${id}`;
    const init = {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        'Authorization': token,
      },
      body: recipe
    };

    return API.put(apiName, path, init);
  }
}

async function getCalcTypes() {
	let token = store.getState().session.token;
	try { token = 'Bearer ' + (await Auth.currentSession()).idToken.jwtToken;} catch { }
  if (config.DEV_MODE) {
    const options = {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        'X-Requested-With': 'XMLHttpRequest',
        'Authorization': token,
      },
    };

    const response = await fetch(config.API_URL + 'calculation-types/list', options);
    return response.json();
  } else {
    const apiName = 'dev-calculation-type-api';
    const path = '/calculation-types/list';
    const init = {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        'Authorization': token,
      }
    };

    return API.get(apiName, path, init);
  }
}

async function getCuisines() {
	let token = store.getState().session.token;
	try { token = 'Bearer ' + (await Auth.currentSession()).idToken.jwtToken;} catch { }
  if (config.DEV_MODE) {
    const options = {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        'X-Requested-With': 'XMLHttpRequest',
        'Authorization': token,
      },
    };

    const response = await fetch(config.API_URL + 'cuisines/list', options);
    return response.json();
  } else {
    const apiName = 'dev-cuisine-api';
    const path = '/cuisines/list';
    const init = {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        'Authorization': token,
      }
    };

    return API.get(apiName, path, init);
  }
}

async function getVegetarianTypes() {
	let token = store.getState().session.token;
	try { token = 'Bearer ' + (await Auth.currentSession()).idToken.jwtToken;} catch { }
  if (config.DEV_MODE) {
    const options = {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        'X-Requested-With': 'XMLHttpRequest',
        'Authorization': token,
      },
    };

    const response = await fetch(config.API_URL + 'vegetarian-types/list', options);
    return response.json();
  } else {
    const apiName = 'dev-vegetariantype-api';
    const path = '/vegetarian-types/list';
    const init = {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        'Authorization': token,
      }
    };

    return API.get(apiName, path, init);
  }
}

async function getDifficultyLevels() {
	let token = store.getState().session.token;
	try { token = 'Bearer ' + (await Auth.currentSession()).idToken.jwtToken;} catch { }
  if (config.DEV_MODE) {
    const options = {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        'X-Requested-With': 'XMLHttpRequest',
        'Authorization': token,
      },
    };

    const response = await fetch(config.API_URL + 'difficulty-levels/list', options);
    return response.json();
  } else {
    const apiName = 'dev-difficulty-level-api';
    const path = '/difficulty-levels/list';
    const init = {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        'Authorization': token,
      }
    };

    return API.get(apiName, path, init);
  }
}

async function getCountries() {
	let token = store.getState().session.token;
	try { token = 'Bearer ' + (await Auth.currentSession()).idToken.jwtToken;} catch { }
  if (config.DEV_MODE) {
    const options = {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        'X-Requested-With': 'XMLHttpRequest',
        'Authorization': token,
      },
    };

    const response = await fetch(config.API_URL + 'countries/list', options);
    return response.json();
  } else {
    const apiName = 'dev-country-api';
    const path = '/countries/list';
    const init = {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        'Authorization': token,
      }
    };

    return API.get(apiName, path, init);
  }
}

async function getUsers(page, count, sortField, sortBy, filter) {
	let token = store.getState().session.token;
	try { token = 'Bearer ' + (await Auth.currentSession()).idToken.jwtToken;} catch { }
  if (config.DEV_MODE) {
    const options = {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        'X-Requested-With': 'XMLHttpRequest',
        'Authorization': token,
      },
      body: JSON.stringify({
        page: page,
        rowCount: count,
        sortFieldName: sortField,
        sortDirection: sortBy,
        keyword: filter,
      })
    };

    const response = await fetch(config.API_URL + 'users/list', options);
    return response.json();
  } else {
    const apiName = 'dev-user-api';
    const path = '/users/list';
    const init = {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        'Authorization': token,
      },
      body: {
        page: page,
        rowCount: count,
        sortFieldName: sortField,
        sortDirection: sortBy,
        keyword: filter,
      },
    };

    return API.post(apiName, path, init);
  }
}

async function getProfile(id) {
	let token = store.getState().session.token;
	try { token = 'Bearer ' + (await Auth.currentSession()).idToken.jwtToken;} catch { }
  if (config.DEV_MODE) {
    const options = {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        'X-Requested-With': 'XMLHttpRequest',
        'Authorization': token,
      },
    };

    const response = await fetch(config.API_URL + 'users/' + id, options);
    return response.json();
  } else {
    const apiName = 'dev-user-api';
    const path = `/users/${id}`;
    const init = {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        'Authorization': token,
      },
    };

    return API.get(apiName, path, init);
  }
}

async function deleteUser(id) {
	let token = store.getState().session.token;
	try { token = 'Bearer ' + (await Auth.currentSession()).idToken.jwtToken;} catch { }
  if (config.DEV_MODE) {
    const options = {
      method: 'DELETE',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        'X-Requested-With': 'XMLHttpRequest',
        'Authorization': token,
      },
    };
    const response = await fetch(config.API_URL + 'users/' + id, options);
    return response.json();
  } else {
    const apiName = 'dev-user-api';
    const path = `/users/${id}`;
    const init = {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        'Authorization': token,
      }
    };

    return API.del(apiName, path, init);
  }
}

async function updateUser(id, data) {
	let token = store.getState().session.token;
	try { token = 'Bearer ' + (await Auth.currentSession()).idToken.jwtToken;} catch { }
  if (config.DEV_MODE) {
    const options = {
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        'X-Requested-With': 'XMLHttpRequest',
        'Authorization': token,
      },
      body: JSON.stringify(data)
    };

    const response = await fetch(config.API_URL + 'users/' + id, options);
    return response.json();
  } else {
    const apiName = 'dev-user-api';
    const path = `/users/${id}`;
    const init = {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        'Authorization': token,
      },
      body: data
    };

    return API.put(apiName, path, init);
  }
}

async function getGroceryTypes() {
	let token = store.getState().session.token;
	try { token = 'Bearer ' + (await Auth.currentSession()).idToken.jwtToken;} catch { }
  if (config.DEV_MODE) {
    const options = {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        'X-Requested-With': 'XMLHttpRequest',
        'Authorization': token,
      },
    };

    const response = await fetch(config.API_URL + 'groceries/types', options);
    console.log(response);
    return response.json();
  } else {
    const apiName = 'dev-groceries-api';
    const path = `/groceries/types`;
    const init = {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        'Authorization': token,
      }
    };

    return API.get(apiName, path, init);
  }
}

async function getFactors() {
	let token = store.getState().session.token;
	try { token = 'Bearer ' + (await Auth.currentSession()).idToken.jwtToken;} catch { }
  if (config.DEV_MODE) {
    const options = {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        'X-Requested-With': 'XMLHttpRequest',
        'Authorization': token,
      },
    };

    const response = await fetch(config.API_URL + 'global_params/list', options);
    console.log(response);
    return response.json();
  } else {
    const apiName = 'dev-global-params-api';
    const path = `/global_params/list`;
    const init = {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        'Authorization': token,
      }
    };

    return API.get(apiName, path, init);
  }
}

async function updateFactors(factors) {
	let token = store.getState().session.token;
	try { token = 'Bearer ' + (await Auth.currentSession()).idToken.jwtToken;} catch { }
  if (config.DEV_MODE) {
    const options = {
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        'X-Requested-With': 'XMLHttpRequest',
        'Authorization': token,
      },
      body: JSON.stringify(factors)
    };

    const response = await fetch(config.API_URL + 'global_params/list', options);
    return response.json();
  } else {
    const apiName = 'dev-global-params-api';
    const path = `/global_params/list`;
    const init = {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        'Authorization': token,
      },
      body: factors
    };

    return API.put(apiName, path, init);
  }
}

async function getContents() {
	let token = store.getState().session.token;
	try { token = 'Bearer ' + (await Auth.currentSession()).idToken.jwtToken;} catch { }
  if (config.DEV_MODE) {
    const options = {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        'X-Requested-With': 'XMLHttpRequest',
        'Authorization': token,
      },
    };

    const response = await fetch(config.API_URL + 'global_params/contents', options);
    return response.json();
  } else {
    const apiName = 'dev-global-params-api';
    const path = `/global_params/contents`;
    const init = {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        'Authorization': token,
      },
    };

    return API.get(apiName, path, init);
  }
}

async function updateContents(contents) {
	let token = store.getState().session.token;
	try { token = 'Bearer ' + (await Auth.currentSession()).idToken.jwtToken;} catch { }
  if (config.DEV_MODE) {
    const options = {
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        'X-Requested-With': 'XMLHttpRequest',
        'Authorization': token,
      },
      body: JSON.stringify(contents)
    };

    const response = await fetch(config.API_URL + 'global_params/contents', options);
    return response.json();
  } else {
    const apiName = 'dev-global-params-api';
    const path = `/global_params/contents`;
    const init = {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        'Authorization': token,
      },
      body: contents
    };

    return API.put(apiName, path, init);
  }
}

async function getEvaluations() {
	let token = store.getState().session.token;
	try { token = 'Bearer ' + (await Auth.currentSession()).idToken.jwtToken;} catch { }
  if (config.DEV_MODE) {
    const options = {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        'X-Requested-With': 'XMLHttpRequest',
        'Authorization': token,
      },
    };

    const response = await fetch(config.API_URL + 'global_params/evaluations', options);
    return response.json();
  } else {
    const apiName = 'dev-global-params-api';
    const path = `/global_params/evaluations`;
    const init = {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        'Authorization': token,
      },
    };

    return API.get(apiName, path, init);
  }
}

async function updateEvaluation(id, evaluations) {
	let token = store.getState().session.token;
	try { token = 'Bearer ' + (await Auth.currentSession()).idToken.jwtToken;} catch { }
  if (config.DEV_MODE) {
    const options = {
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        'X-Requested-With': 'XMLHttpRequest',
        'Authorization': token,
      },
      body: JSON.stringify(evaluations)
    };

    const response = await fetch(config.API_URL + 'global_params/evaluations/' + id, options);
    return response.json();
  } else {
    const apiName = 'dev-global-params-api';
    const path = `/global_params/evaluations/${id}`;
    const init = {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        'Authorization': token,
      },
      body: evaluations
    };

    return API.put(apiName, path, init);
  }
}

async function getEvaluationTypes() {
	let token = store.getState().session.token;
	try { token = 'Bearer ' + (await Auth.currentSession()).idToken.jwtToken;} catch { }
  if (config.DEV_MODE) {
    const options = {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        'X-Requested-With': 'XMLHttpRequest',
        'Authorization': token,
      },
    };

    const response = await fetch(config.API_URL + 'global_params/evaluation_types', options);
    return response.json();
  } else {
    const apiName = 'dev-global-params-api';
    const path = `/global_params/evaluation_types`;
    const init = {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        'Authorization': token,
      },
    };

    return API.get(apiName, path, init);
  }
}

async function getEvaluationFactors() {
	let token = store.getState().session.token;
	try { token = 'Bearer ' + (await Auth.currentSession()).idToken.jwtToken;} catch { }
  if (config.DEV_MODE) {
    const options = {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        'X-Requested-With': 'XMLHttpRequest',
        'Authorization': token,
      },
    };

    const response = await fetch(config.API_URL + 'global_params/evaluation_factors', options);
    return response.json();
  } else {
    const apiName = 'dev-global-params-api';
    const path = `/global_params/evaluation_factors`;
    const init = {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        'Authorization': token,
      },
    };

    return API.get(apiName, path, init);
  }
}

async function getEvaluationSubFactors() {
	let token = store.getState().session.token;
	try { token = 'Bearer ' + (await Auth.currentSession()).idToken.jwtToken;} catch { }
  if (config.DEV_MODE) {
    const options = {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        'X-Requested-With': 'XMLHttpRequest',
        'Authorization': token,
      },
    };

    const response = await fetch(config.API_URL + 'global_params/evaluation_subfactors', options);
    return response.json();
  } else {
    const apiName = 'dev-global-params-api';
    const path = `/global_params/evaluation_subfactors`;
    const init = {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        'Authorization': token,
      },
    };

    return API.get(apiName, path, init);
  }
}

async function getUserGroups() {
	let token = store.getState().session.token;
	try { token = 'Bearer ' + (await Auth.currentSession()).idToken.jwtToken;} catch { }
  if (config.DEV_MODE) {
    const options = {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        'X-Requested-With': 'XMLHttpRequest',
        'Authorization': token,
      },
    };

    const response = await fetch(config.API_URL + 'users/group_list', options);
    return response.json();
  } else {
    const apiName = 'dev-user-api';
    const path = `/users/group_list`;
    const init = {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        'Authorization': token,
      },
    };

    return API.get(apiName, path, init);
  }
}

async function getMealLevels() {
	let token = store.getState().session.token;
	try { token = 'Bearer ' + (await Auth.currentSession()).idToken.jwtToken;} catch { }
  if (config.DEV_MODE) {
    const options = {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        'X-Requested-With': 'XMLHttpRequest',
        'Authorization': token,
      },
    };

    const response = await fetch(config.API_URL + 'meal-levels/list', options);
    return response.json();
  } else {
    const apiName = 'dev-meal-level-api';
    const path = `/meal-levels/list`;
    const init = {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        'Authorization': token,
      },
    };

    return API.get(apiName, path, init);
  }
}

export const ApiService = {
  getLanguages,
  createLanguage,
  deleteLanguage,
  getFields,
  getTabs,
  getGroups,
  getGroceryTypes,
  getIngredient,
  getIngredients,
  exportIngredients,
  exportRecipes,
  importIngredients,
  importRecipes,
  createIngredient,
  deleteIngredient,
  updateIngredient,
  getRDD,
  getRDDs,
  createRDD,
  deleteRDD,
  updateRDD,
  getRecipe,
  getRecipes,
  createRecipe,
  updateRecipe,
  deleteRecipe,
  getCalcTypes,
  getCuisines,
  getVegetarianTypes,
  getDifficultyLevels,
  getCountries,
  getUsers,
  deleteUser,
  updateUser,
  getFactors,
  updateFactors,
  getContents,
  updateContents,
  getEvaluations,
  updateEvaluation,
  getEvaluationTypes,
  getEvaluationFactors,
  getEvaluationSubFactors,
  getUserGroups,
  getProfile,
  getMealLevels,
  getRecipeEvaluationDetails,
  evaluateMeal
}
