
export const config = {
  // API_URL: 'https://myperfectmeal:8080/',
  // UPLOAD_URL: 'https://myperfectmeal:8080/uploads/',
  // API_URL: 'http://192.168.141.130:8080/',
  // UPLOAD_URL: 'http://192.168.141.130:8080/uploads/',
  API_URL: 'http://192.168.85.129:8080/',
  UPLOAD_URL: 'http://192.168.85.129:8080/uploads/',

  DEV_MODE: false,
}