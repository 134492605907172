import React, { useState } from "react";
import { connect, useSelector } from "react-redux";
import { NavLink, useHistory, withRouter } from "react-router-dom";
import { useDispatch } from 'react-redux';
import {
  Badge,
  Collapse,
  Input,
} from "reactstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSearch,
  faFilter,
} from "@fortawesome/free-solid-svg-icons";

import routes from "../routes/index";
import { filterRecipes, updateKeyword, } from '../redux/actions/sessionActions';
import { noneRecipeInfo, setRecipeInfo, } from '../redux/actions/apiActions';
import { ApiService } from "../services/apiService";
import { showToastr } from '../services/themeService';
import { sessionLogout } from '../redux/actions/sessionActions';


const initOpenRoutes = (location) => {
  /* Open collapse element that matches current url */
  const pathName = location.pathname;

  let _routes = {};

  routes.forEach((route, index) => {
    const isActive = pathName.indexOf(route.path) === 0;
    const isOpen = route.open;
    const isHome = route.containsHome && pathName === "/" ? true : false;

    _routes = Object.assign({}, _routes, { [index]: isActive || isOpen || isHome })
  });

  return _routes;
};

const SidebarCategory = withRouter(
  ({
    name,
    badgeColor,
    badgeText,
    icon: Icon,
    isOpen,
    children,
    onClick,
    location,
    to
  }) => {
    const getSidebarItemClass = path => {
      return location.pathname.indexOf(path) !== -1 ||
        (location.pathname === "/" && path === "/dashboard")
        ? "active"
        : "";
    };

    return (
      <li className={"sidebar-item " + getSidebarItemClass(to)}>
        <span
          data-toggle="collapse"
          className={"sidebar-link " + (!isOpen ? "collapsed" : "")}
          onClick={onClick}
          aria-expanded={isOpen ? "true" : "false"}
        >
          <Icon size={18} className="align-middle mr-3" />
          <span className="align-middle">{name}</span>
          {badgeColor && badgeText ? (
            <Badge color={badgeColor} size={18} className="sidebar-badge">
              {badgeText}
            </Badge>
          ) : null}
        </span>
        <Collapse isOpen={isOpen}>
          <ul id="item" className={"sidebar-dropdown list-unstyled"}>
            {children}
          </ul>
        </Collapse>
      </li>
    );
  }
);

const SidebarItem = withRouter(
  ({ name, badgeColor, badgeText, icon: Icon, location, to, divider, search, }) => {
    const getSidebarItemClass = path => {
      return location.pathname === path ? "active" : "";
    };

    const isRangeEqual = (object1, object2) => {
      return object1.min === object2.min && object1.max === object2.max;
    }

    const dispatch = useDispatch();
    const history = useHistory();

    const filterOptions = useSelector((state) => state.session.filterOptions);
    const fields = useSelector((state) => state.api.fields);
    const keyword = useSelector(state => state.session.keyword);
    const token = useSelector(state => state.session.token);

    const handleFilter = () => {
      history.push('/admin/recipes');

      dispatch(filterRecipes());
    }

    const handleSearch = () => {
      history.push('/admin/recipes');

      dispatch(noneRecipeInfo());

      let filter = {};
      if (filterOptions.cuisines.length > 0)
        filter.cuisineList = filterOptions.cuisines;
      if (filterOptions.vegetarianTypes.length > 0)
        filter.vegetarianTypeList = filterOptions.vegetarianTypes;
      if (filterOptions.difficultyLevels.length > 0)
        filter.difficultyLevelList = filterOptions.difficultyLevels;
      filter.preparationTime = {
        from: filterOptions.preparationTime.value.min,
        to: filterOptions.preparationTime.value.max,
      };
      filter.serves = {
        from: filterOptions.serves.value.min,
        to: filterOptions.serves.value.max,
      };
      filter.voteStar = {
        from: filterOptions.userStars.value.min,
        to: filterOptions.userStars.value.max,
      };
      filter.evaluationStar = {
        from: filterOptions.evaluationStars.value.min,
        to: filterOptions.evaluationStars.value.max,
      };

      let ingredientRanges = [];
      if (!isRangeEqual(filterOptions.energy.value, filterOptions.energy.range)) {
        ingredientRanges.push({
          id: fields.find(field => field.display === 'Energy').id,
          from: filterOptions.energy.value.min,
          to: filterOptions.energy.value.max,
        });
      }
      if (!isRangeEqual(filterOptions.protein.value, filterOptions.protein.range)) {
        ingredientRanges.push({
          id: fields.find(field => field.display === 'Protein').id,
          from: filterOptions.protein.value.min,
          to: filterOptions.protein.value.max,
        });
      }
      if (!isRangeEqual(filterOptions.fat.value, filterOptions.fat.range)) {
        ingredientRanges.push({
          id: fields.find(field => field.display === 'Fat').id,
          from: filterOptions.fat.value.min,
          to: filterOptions.fat.value.max,
        });
      }
      if (!isRangeEqual(filterOptions.sugar.value, filterOptions.sugar.range)) {
        ingredientRanges.push({
          id: fields.find(field => field.display === 'Sugar').id,
          from: filterOptions.sugar.value.min,
          to: filterOptions.sugar.value.max,
        });
      }
      if (!isRangeEqual(filterOptions.carbohydrate.value, filterOptions.carbohydrate.range)) {
        ingredientRanges.push({
          id: fields.find(field => field.display === 'Carbohydrate').id,
          from: filterOptions.carbohydrate.value.min,
          to: filterOptions.carbohydrate.value.max,
        });
      }
      if (!isRangeEqual(filterOptions.fiber.value, filterOptions.fiber.range)) {
        ingredientRanges.push({
          id: fields.find(field => field.display === 'Fiber').id,
          from: filterOptions.fiber.value.min,
          to: filterOptions.fiber.value.max,
        });
      }
      filter.ingredientRanges = ingredientRanges;

      const count = 10;

      ApiService.getRecipes(1, count, 'id', 'asc', keyword, filter)
        .then(data => {
          // if (data && data.message === "Unauthenticated.") {
          //   history.push('/admin');

          //   return;
          // }
          console.log('then');
          console.log(data);
          dispatch(setRecipeInfo(data.meals, data.count, data.pages, 1));
        })
        .catch((err) => {
          console.log('catch');
          console.error(err);
          if (err.response.status === 401) {
            showToastr('warning', 'Authorization Error', err.response.data.message);
            dispatch(sessionLogout());
  
            history.push('/');
            return;
          }
        });
    }

    return (
      <React.Fragment>
        <li className={"sidebar-item " + getSidebarItemClass(to)}>
          <NavLink to={to} className="sidebar-link" activeClassName="active">
            <div className="d-flex align-items-center mr-3" style={{ width: 18 }}>
              {Icon ? <img src={Icon} /> : null}
            </div>
            {name}
            {badgeColor && badgeText ? (
              <Badge color={badgeColor} size={18} className="sidebar-badge">
                {badgeText}
              </Badge>
            ) : null}
          </NavLink>
        </li>

        {
          divider ? (
            <div className="sidebar-divider">

            </div>
          ) : null
        }

        {
          search ? (
            <div>
              <li className="sidebar-item">
                <div className="sidebar-link" style={{ backgroundColor: '#3490DD', }}>
                  <div style={{ display: 'flex', alignItems: 'center', width: '100%', }}>
                    <Input
                      type="text"
                      placeholder="Search Food"
                      aria-label="Search"
                      className="form-control-no-border mr-sm-1"
                      style={{ backgroundColor: '#3490DD', color: 'white', fontWeight: 700, }}
                      value={keyword}
                      onChange={(val) => dispatch(updateKeyword(val.target.value))}
                    />
                    <FontAwesomeIcon icon={faSearch} style={{ color: 'white', opacity: 1, fontSize: 16, }} className="mr-2" onClick={handleSearch} />
                    <FontAwesomeIcon icon={faFilter} style={{ color: 'white', opacity: 1, fontSize: 16, }} className="mr-0" onClick={handleFilter} />
                  </div>
                </div>
              </li>
              <div className="sidebar-divider">

              </div>
            </div>
          ) : null
        }

      </React.Fragment>
    );
  }
);

const Sidebar = ({ location, sidebar }) => {
  const [openRoutes, setOpenRoutes] = useState(() => initOpenRoutes(location));

  const toggle = index => {
    // Collapse all elements
    Object.keys(openRoutes).forEach(
      item => openRoutes[index] || setOpenRoutes(openRoutes => Object.assign({}, openRoutes, { [item]: false }))
    )

    // Toggle selected element
    setOpenRoutes(openRoutes => Object.assign({}, openRoutes, { [index]: !openRoutes[index] }));
  }

  return (
    <nav
      className={
        "sidebar" +
        (!sidebar.isOpen ? " toggled" : "") +
        (sidebar.isSticky ? " sidebar-sticky" : "")
      }
      style={{ position: 'relative', }}
    >
      <div className="sidebar-content">
        <PerfectScrollbar>
          <a className="sidebar-brand" href="/admin">
            <svg width="28" height="29" viewBox="0 0 28 29" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M25.5225 18.7613C21.5766 26.5594 12.484 28.4681 12.484 28.4681L17.1865 19.8579C17.1865 19.8579 12.4216 24.1058 11.9392 23.5713C10.5822 22.0651 9.82566 17.6473 12.5743 12.216C16.5202 4.41789 26.765 0.225586 26.765 0.225586C26.765 0.225586 29.465 10.9597 25.5225 18.7613Z" fill="#3490DD" />
              <path d="M0.683931 11.6468C2.48857 16.8352 7.98925 18.7856 7.98925 18.7856L5.78203 13.0281C5.78203 13.0281 8.39182 16.0717 8.73886 15.7801C9.71059 14.9542 10.5504 12.2681 9.29412 8.65531C7.48949 3.46698 1.46133 0 1.46133 0C1.46133 0 -1.11723 6.46198 0.683931 11.6468Z" fill="#FC9C52" />
            </svg>
            {" "}
            <span className="align-middle">My Perfect Meal</span>
          </a>

          <ul className="sidebar-nav">
            {routes.map((category, index) => {
              return (
                <React.Fragment key={index}>
                  {category.header ? (
                    <li className="sidebar-header">{category.header}</li>
                  ) : null}

                  {category.children ? (
                    <SidebarCategory
                      name={category.name}
                      badgeColor={category.badgeColor}
                      badgeText={category.badgeText}
                      icon={category.icon}
                      to={category.path}
                      isOpen={openRoutes[index]}
                      onClick={() => toggle(index)}
                    >
                      {category.children.map((route, index) => (
                        <SidebarItem
                          key={index}
                          name={route.name}
                          to={route.path}
                          badgeColor={route.badgeColor}
                          badgeText={route.badgeText}
                        />
                      ))}
                    </SidebarCategory>
                  ) : (
                      <SidebarItem
                        name={category.name}
                        to={category.path}
                        icon={category.icon}
                        badgeColor={category.badgeColor}
                        badgeText={category.badgeText}
                        divider={category.divider}
                        search={category.search}
                      />
                    )}
                </React.Fragment>
              );
            })}
          </ul>
        </PerfectScrollbar>
      </div>
      <div className="sidebar-bottom d-none d-lg-block">
        <p style={{ color: '#D3D8E4', opacity: 0.75, textAlign: 'center', margin: 0, }}>
          {`© My Perfect Meal ${new Date().getFullYear()}`}
        </p>
      </div>
    </nav>
  )
}

export default withRouter(
  connect(store => ({
    sidebar: store.sidebar,
    layout: store.layout
  }))(Sidebar)
);
