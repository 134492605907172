import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import Select from "react-select";
import Papa from "papaparse";
import {
  Button,
  Table,
  Input,
  Container,
  Spinner,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSearch,
  faPencilAlt,
  faTrashAlt,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";

import ImageUploader from '../../components/ImageUploader';
import { ApiService } from "../../services/apiService";
import Pagination from '../../components/Pagination';
import { ReactS3Client } from '../../config/aws-export';
import {
  setIngredientInfo,
  setIngredientFilter,
  noneIngredientInfo,
} from "../../redux/actions/apiActions";
import FilterIcon from '../../assets/img/filter.png';
import DefaultImage from '../../assets/img/tmp/coconut-milk.png';
import { showToastr } from '../../services/themeService';
import { sessionLogout } from '../../redux/actions/sessionActions';

import Resizer from "react-image-file-resizer";


const IMG_SIZE = {
  normal: [150, 150],
  big: [200, 200]
}

const fileToBlob = (file) => new Promise((resolve) => {
  const reader = new FileReader();
  reader.onload = (event) => {
    resolve(new Blob([event.target.result], {type: file.type}))
  };
  reader.readAsArrayBuffer(file);
})

const resizeFile = (filename, width, height, format, quality, rotation, type) =>
  new Promise((resolve) => {
    fileToBlob(filename)
      .then(file => {
        Resizer.imageFileResizer(
          file,
          width,
          height,
          format,
          quality,
          rotation,
          (uri) => {
            resolve(uri);
          },
          type
        );
    });
  });

const getFieldValue = (fieldid, ingredient) => {
  let value = ingredient?.fields?.find(f => (f.id == fieldid));
  return value !== undefined ? value.value : "";
}

const bigVersion = (imageUrl) => {
  if (!imageUrl)
    return imageUrl;

  if (imageUrl.name) {
    imageUrl = imageUrl.name;
  }
    
  console.log("imageUrl:", imageUrl);
  const lastIndex = imageUrl.lastIndexOf(".");
  const name = imageUrl.slice(0, lastIndex);
  const extension = imageUrl.slice(lastIndex, imageUrl.length);
  return name + "-big" + extension;
}

const customStyles = {
  container: (provided, state) => ({
    ...provided,
    display: 'inline-block',
    width: 80,
    marginLeft: 'auto',
    marginRight: 5,
  }),
};

const customStyles1 = {
  container: (provided, state) => ({
    ...provided,
    display: 'inline-block',
    width: 200,
    marginRight: 5,
  }),
  menu: (provided, state) => ({
    ...provided,
    zIndex: 9999,
    display: 'block',
  })
};

const isInt = (string) => {
  for (const i in string) {
    if (string.charCodeAt(i) < 48 || string.charCodeAt(i) > 57)
      return false;
  }
  return true;
}

const AddItem = ({ text, onClick, hidden }) => {

  return (
    <a style={{ color: '#3490DD', marginTop: 30, display: 'block', textAlign: 'center', }} onClick={onClick} hidden={hidden}>
      <FontAwesomeIcon icon={faPlus} style={{ marginRight: 10, fontSize: 16, fontWeight: 600, }} />
      {text}
    </a>
  )
}


const Ingredients = (props) => {

  const page = parseInt(props.match.params.page);

  const ingredientInfo = useSelector((state) => state.api.ingredientInfo);
  const fields = useSelector((state) => state.api.fields);
  const languages = useSelector((state) => state.api.languages);
  const groceries = useSelector((state) => state.api.groceryTypes);
  const filter = useSelector((state) => state.api.filter.ingredient);

  const history = useHistory();
  const dispatch = useDispatch();

  const defaultLangId = 1;

  const [currentIngredient, setCurrentIngredient] = useState({});
  const [updatedIngredient, setUpdatedIngredient] = useState({});
  const [language, setLanguage] = useState({
    base: 0,
    main: 0,
    category: 0,
    grocery: 0,
  });
  const [fieldOptions, setFieldOptions] = useState([]);
  const [createOpen, setCreateOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);

  const [sortCol, setSortCol] = useState(props.history.location.state?.sortCol ?? 'code');
  const [sortDirection, setSortDirection] = useState(props.history.location.state?.sortDirection ?? 'asc');

  const count = 10; 
  
  useEffect(() => {
    setSortCol(props.history.location.state?.sortCol);
    setSortDirection(props.history.location.state?.sortDirection);
  }, [props.history.location.state])

  useEffect(() => {
    handlePageChange(page);
  }, [page, sortCol, sortDirection]);

  const toggle = (type) => {
    if (type === 'create')
      setCreateOpen(!createOpen);
    else if (type === 'edit')
      setEditOpen(!editOpen);
    else
      setDeleteOpen(!deleteOpen);
  }

  const flipDirection = (col) => {
    if (sortCol == col) {
      if (sortDirection == "asc")
        setSortDirection("desc");
      else
        setSortDirection("asc");
    }
    else {
      setSortDirection("asc");
    }
  }

  const handlePageChange = (val) => {
    dispatch(noneIngredientInfo());

      ApiService.getIngredients(val, count, sortCol, sortDirection, filter, defaultLangId)
        .then(data => {
          // if (data && data.message === "Unauthenticated.") {
          //   history.push('/admin');
  
          //   return;
          // }
          console.log('ingredients: ', data);
          if (data.errorMessage) {
            dispatch(setIngredientInfo({count: 0, pages: 1, ingredients: []}));
          }
          else {
            dispatch(setIngredientInfo(data));
          }
        })
        .catch((err) => {
          console.log('ingredients error: ', err);
          if (err.response.status === 401) {
            showToastr('warning', 'Authorization Error', err.response.data.message);
            dispatch(sessionLogout());
  
            history.push('/');
            return;
          }
        });
  }

  const handleChangeImage = (img) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const image = new Image();
      image.src = e.target.result;

      image.onload = function() {
        const normal = 1;
        const ratio = this.width / this.height;
        const error = Math.abs(ratio - normal) / normal;
        console.log("error:", error);
        if (error > 0.15) {
          const percentage = Math.round(error * 100);
          showToastr("warning", `Image maladjusted by ${percentage}%`);
        }
        console.log("image ratio:", ratio);
      }
    } 
    reader.readAsDataURL(img);

    setUpdatedIngredient({ ...updatedIngredient, image: img, }); 
    setCurrentIngredient({ ...currentIngredient, image: img })
  }

  const verifyIngredientInfo = (ingredient) => {
    if (ingredient.code === undefined || ingredient.code === "") {
      return "Missing ingredient code";
    }
    if (!ingredient.code.match(/^\d+$/gm)) {
      return "Invalid ingredient code";
    }
    if (ingredient.fields !== undefined) {
      for (let i = 0; i < ingredient.fields.length; i++) {
        if (ingredient.fields[i].value !== undefined && ingredient.fields[i].value != "" && !ingredient.fields[i].value.match(/^\d+(?:.\d+)?$/gm)) {
          return `Invalid value for "${fields[ingredient.fields[i].id].display}"`;
        }
      }
    }
  }

  const getEnergy = (ingredient) => {
    let energyFieldKey = Object.keys(fields).find(key => fields[key].name === "energy_cal");
    if (energyFieldKey === undefined)
      return undefined;
    
    let energyField = ingredient.fields.find(f => f.id == energyFieldKey);
    if (energyField === undefined)
      return undefined; 
    return energyField.value;
  }

  const getEditIngredientModal = (toggleName, isOpen, title) => {
    return (
      <Modal
        isOpen={isOpen}
        toggle={() => toggle(toggleName)}
        centered
      >
        <ModalHeader toggle={() => toggle(toggleName)}>
          { title }
        </ModalHeader>
        <ModalBody className="text-center m-3">
          <ImageUploader img={bigVersion(currentIngredient?.image) ?? DefaultImage} onError={function(e) { e.target.onError = null; e.target.img = currentIngredient.image;}} onChange={(img) => handleChangeImage(img)} />
          <div style={{ color: '#6B6C72', fontSize: 14, fontWeight: 400, maxHeight: 400, overflowY: 'scroll', padding: '0 15%', }}>
            {
              <div className="m-0 d-flex justify-content-between align-items-center" style={{ borderTop: '1px solid #dee2e6', padding: '10px 0', }}>
                <span>
                  Code
                </span>
                <Input
                  type="number"
                  min="0"
                  onKeyPress={(event) => {return (event.charCode != 8 && event.charCode == 0 || (event.charCode >= 48 && event.charCode <= 57))}}
                  aria-label="Value"
                  className="p-0 text-right"
                  style={{ width: 70, height: 30, padding: '0 10px' }}
                  value={currentIngredient?.code ?? ''}
                  onChange={(val) => handleCodeChange(val.target.value)}
                />
              </div>
            }
            <div className="m-0 d-flex justify-content-between align-items-center" style={{ borderTop: '1px solid #dee2e6', padding: '10px 0', }}>
              <span>
                Database
              </span>
              <Input
                type="text"
                aria-label="Value"
                className="p-0 text-center"
                style={{ width: 150, height: 30, padding: '0 10px' }}
                value={currentIngredient?.sourceDatabase ?? ''}
                onChange={(val) => handleSourceDatabaseChange(val.target.value)}
              />
            </div>
            <div className="m-0 d-flex justify-content-between align-items-center" style={{ borderTop: '1px solid #dee2e6', padding: '10px 0', }}>
              <span>
                Description
              </span>
              <Select
                className="react-select-container"
                classNamePrefix="react-select"
                styles={customStyles}
                options={languageOptions}
                isSearchable={false}
                defaultValue={languageOptions[0]}
                onChange={(item) => setLanguage({ ...language, main: item.value, })}
              />
              <Input
                type="text"
                aria-label="Value"
                className="p-0 text-center"
                style={{ width: 150, height: 30, padding: '0 10px' }}
                value={currentIngredient?.description?.[language.main] ?? ''}
                onChange={(val) => handleDescriptionChange('main', val.target.value)}
              />
            </div>
            <div className="m-0 d-flex justify-content-between align-items-center" style={{ borderTop: '1px solid #dee2e6', padding: '10px 0', }}>
              <span>
                Category ID
              </span>
              <Input
                type="text"
                aria-label="Value"
                className="p-0 text-right"
                style={{ width: 70, height: 30, padding: '0 10px' }}
                value={currentIngredient?.categoryCode ?? ''}
                onChange={(val) => handleCategoryCodeChange(val.target.value)}
              />
            </div>
            <div className="m-0 d-flex justify-content-between align-items-center" style={{ borderTop: '1px solid #dee2e6', padding: '10px 0', }}>
              <span>
                Category
              </span>
              <Select
                className="react-select-container"
                classNamePrefix="react-select"
                styles={customStyles}
                options={languageOptions}
                isSearchable={false}
                defaultValue={languageOptions[0]}
                onChange={(item) => setLanguage({ ...language, category: item.value, })}
              />
              <Input
                type="text"
                aria-label="Value"
                className="p-0 text-center"
                style={{ width: 150, height: 30, padding: '0 10px' }}
                value={currentIngredient?.categoryDescription?.[language.category] ?? ''}
                onChange={(val) => handleDescriptionChange('category', val.target.value)}
              />
            </div>
            <div className="m-0 d-flex justify-content-between align-items-center" style={{ borderTop: '1px solid #dee2e6', padding: '10px 0', }}>
              <span>
                Groceries Description
              </span>
              {/* <Select
                className="react-select-container"
                classNamePrefix="react-select"
                styles={customStyles}
                options={languageOptions}
                isSearchable={false}
                defaultValue={languageOptions[0]}
                onChange={(item) => setLanguage({ ...language, grocery: item.value, })}
              /> */}
              {/* <Input
                type="text"
                aria-label="Value"
                className="p-0 text-center"
                style={{ width: 150, height: 30, padding: '0 10px' }}
                value={currentIngredient?.groceryDescription?.[language.grocery] ?? ''}
                onChange={(val) => handleDescriptionChange('grocery', val.target.value)}
              /> */}
              <Select
                className="react-select-container"
                classNamePrefix="react-select"
                styles={customStyles1}
                options={groceryOptions}
                isSearchable={false}
                defaultValue={groceryOptions.find(go => go.value == currentIngredient.groceryTypeId)}
                onChange={(val) => handleGroceryTypeChange(val.value)}
              />
            </div>
            <div className="m-0 d-flex justify-content-between align-items-center" style={{ borderTop: '1px solid #dee2e6', padding: '10px 0', }}>
              <span className="text-left">
                Friendly Description
              </span>
              <Select
                className="react-select-container"
                classNamePrefix="react-select"
                styles={customStyles}
                options={languageOptions}
                isSearchable={false}
                defaultValue={languageOptions[0]}
                onChange={(item) => setLanguage({ ...language, friendly: item.value, })}
              />
              <Input
                type="text"
                aria-label="Value"
                className="p-0 text-center"
                style={{ width: 150, height: 30, padding: '0 10px' }}
                value={currentIngredient?.friendlyDescription?.[language.friendly] ?? ''}
                onChange={(val) => handleDescriptionChange('friendly', val.target.value)}
              />
            </div>
            {
              Object.keys(fields).map((field, index) => {
                return fields[field].visible ? (
                  <div className="m-0 d-flex justify-content-between align-items-center" style={{ borderTop: '1px solid #dee2e6', padding: '10px 0', }} key={index}>
                    <span>
                      {`${fields[field].display} (${fields[field].unit})`}
                    </span>
                    <Input
                      type="text"
                      aria-label="Value"
                      className="p-0 text-right"
                      style={{ width: 70, height: 30, padding: '0 10px' }}
                      value={getFieldValue(field, currentIngredient)}
                      onChange={(val) => handleFieldValChange(field, val.target.value)}
                    />
                  </div>
                ) : null
              })
            }
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => toggle(toggleName)}>
            Cancel
          </Button>{" "}
          <Button color="primary" disabled={Object.keys(currentIngredient).length === 0 || currentIngredient.code === undefined || currentIngredient.code === "" || !isInt(currentIngredient.code) ? true : false} onClick={() => handleConfirm(toggleName)}>
            OK
          </Button>
        </ModalFooter>
      </Modal>
    )
  }

  const exportIngredients = () => {
    ApiService.exportIngredients()
      .then(data => {
        if (data.errorType) {
          console.error(data);
          showToastr("error", data.errorMessage);
        }
        else {
          const fileContent = Papa.unparse(data.content.map(ing => {
            let object = {};
            object["code"] = ing.id;
            for (const key in languages) {
              if (key in ing.friendlyDescription) {
                object[languages[key].name] = ing.friendlyDescription[key];
              }
              else {
                object[languages[key].name] = null;
              }
            }
            return object;
          }), {encoding: "ISO-8859-1"});
          const blob = new Blob([fileContent]);
          const fileDownloadUrl = URL.createObjectURL(blob);
          const element = document.createElement("a");
          element.href = fileDownloadUrl;
          element.download = "export.csv";
          document.body.appendChild(element);
          element.click();
          URL.revokeObjectURL(blob);
          
          showToastr("info", "Success!");

        }
      }).catch(error => {
        console.error(error);
      })
  }

  const handleImportFileChange = async (e) => {
    const files = e.target.files;
    if (files.length != 1) 
      return;

    Papa.parse(files[0], {
      header: true,
      encoding: "ISO-8859-1",
      skipEmptyLines: true,
      complete: (result) => {
        if (result.errors.length != 0) {
          showToastr("error", `${result.errors[0].message} (row ${result.errors[0].row})`);
        }
        else {
          const newIngredients = result.data.map(row => {
            const descriptions = {};
            for (const name in row) {
              if (name == "code")
                continue;
              const langKey = Object.keys(languages).find(k => languages[k].name == name);
              if (!langKey)
                continue;
              descriptions[langKey] = row[name];
            }
            return {
              id: parseInt(row["code"]),
              friendlyDescription: descriptions
            }
          });

          let success = true;
          for (const i in newIngredients) {
            const element = newIngredients[i];
            if (element.id === undefined || element.friendlyDescription === undefined) {
              success = false;
              console.log(element);
              showToastr("error", "Invalid data");
              break;
            }
          }
          if (success) {
            console.log(newIngredients);
            ApiService.importIngredients({content: newIngredients})
              .then(data => {
                if (data && data.errorType) {
                  console.error(data);
                  showToastr("error", data.errorMessage);
                }
                else 
                  showToastr("info", "Successfully imported new ingredient data");
              }).catch(error => {
                console.error(error);
              });
          }
        }
      }
    });
  }

  const importIngredients = (e) => {
    const fileUpload = e.target.parentElement.parentElement.children[0];
    fileUpload.click();
  }

  const handleCreate = () => {
    setLanguage({
      friendly: defaultLangId,
      main: defaultLangId,
      category: defaultLangId,
      grocery: defaultLangId,
    });

    setCurrentIngredient({});

    setUpdatedIngredient({});

    toggle('create');
  }

  const handleEdit = (ingredient) => {
    setLanguage({
      friendly: defaultLangId,
      main: defaultLangId,
      category: defaultLangId,
      grocery: defaultLangId,
    });

    setCurrentIngredient(ingredient);

    setUpdatedIngredient({});

    toggle('edit');
  }

  const handleDelete = (ingredient) => {
    setCurrentIngredient(ingredient);
    toggle('delete');
  }

  const handleConfirm = async (type) => {
    if (type === 'create') {
      let fields = updatedIngredient;

      // Verify if ingredient info is valid
      let error = verifyIngredientInfo(updatedIngredient);
      if (error !== undefined) {
        showToastr("error", error);
        return;
      }

      if (currentIngredient.image) {
        // const baseFileName = Date.now();
        // const fileExtension = currentIngredient.image.name.split('.').pop();
        
        // const normalSizeFile = await resizeFile(currentIngredient.image, IMG_SIZE.normal[0], IMG_SIZE.normal[1], "JPEG", 85, 0, "blob");
        // const bigSizeFile = await resizeFile(currentIngredient.image, IMG_SIZE.big[0], IMG_SIZE.big[1], "JPEG", 85, 0, "blob");
        
        // const location = await ReactS3Client.uploadFile(normalSizeFile, baseFileName + "." + fileExtension);
        // if (location == undefined) {
        //   showToastr('error', 'Error uploading image', `Error while trying to upload the image`);
        //   return;
        // }

        const baseFileName = Date.now();
        const fileExtension = currentIngredient.image.name.split('.').pop();
        const normalSizeFile = await resizeFile(currentIngredient.image, IMG_SIZE.normal[0], IMG_SIZE.normal[1], "JPEG", 85, 0, "blob");
        const smallSizeFile = await resizeFile(currentIngredient.image, IMG_SIZE.small[0], IMG_SIZE.small[1], "JPEG", 85, 0, "blob");
        
        const location = await ReactS3Client.uploadFile(normalSizeFile, baseFileName + "." + fileExtension);
        if (location == undefined) {
          showToastr('error', 'Error uploading image', `Error while trying to upload the image`);
          return;
        }
        fields.image = location;
        await ReactS3Client.uploadFile(smallSizeFile, baseFileName + "-small." + fileExtension);
        console.log('currentIngredient', currentIngredient);
      }

      ApiService.createIngredient(fields)
      .then((data) => {
        console.log(data);
        if (data.errorType) {
          showToastr("error", "An unknown error occurred while adding the ingredient");
        }
        else {
          var ingredients = JSON.parse(JSON.stringify(ingredientInfo.ingredients));
          ingredients.push(data);
          
          dispatch(setIngredientInfo({ ...ingredientInfo, ingredients: ingredients }));
          toggle('create');
        }
      })
      .catch((err) => {
        console.error(err);
        if (err?.response?.status === 401) {
          showToastr('warning', 'Authorization Error', err.response.data.message);
          dispatch(sessionLogout());
          
          history.push('/');
          return;
        }
        else {
          showToastr("error", "An unknown error occurred while adding the ingredient");
        }
      });
        
    } else if (type === 'edit') {
      let fields = updatedIngredient;

      if (updatedIngredient.image) {
        const baseFileName = Date.now();
        const fileExtension = currentIngredient.image.name.split('.').pop();
        
        const normalSizeFile = await resizeFile(currentIngredient.image, IMG_SIZE.normal[0], IMG_SIZE.normal[1], "JPEG", 85, 0, "blob");
        const bigSizeFile = await resizeFile(currentIngredient.image, IMG_SIZE.big[0], IMG_SIZE.big[1], "JPEG", 85, 0, "blob");
        
        const location = await ReactS3Client.uploadFile(normalSizeFile, baseFileName + "." + fileExtension);
        if (location == undefined) {
          showToastr('error', 'Error uploading image', `Error while trying to upload the image`);
          return;
        }
        await ReactS3Client.uploadFile(bigSizeFile, baseFileName + "-big." + fileExtension);
        console.log('currentIngredient', currentIngredient);
        fields.image = location;
      }

        ApiService.updateIngredient(currentIngredient.id, fields)
        .then((data) => {
          console.log(data);
          if (data.errorType) {
            showToastr("error", "Error", data.errorMessage);
          }
          else {
            var ingredients = JSON.parse(JSON.stringify(ingredientInfo.ingredients));
            let ingredient = ingredients.find(ingredient => ingredient.id == currentIngredient.id);
            let index = ingredients.indexOf(ingredient);
            
            ingredients[index] = data;
            
            console.log(ingredients);
            
            dispatch(setIngredientInfo({ ...ingredientInfo, ingredients: ingredients }));
            toggle('edit');
          }
        })
        .catch((err) => {
          console.error(err);
          if (err?.response?.status === 401) {
            showToastr('warning', 'Authorization Error', err.response.data.message);
            dispatch(sessionLogout());
            
            history.push('/');
            return;
          }
        });
    } else if (type === "delete") {
      ApiService.deleteIngredient(currentIngredient.id)
      .then((data) => {
        console.log(data);
        if (data.errorType) {
          showToastr("error", "An error occurred while deleting the ingredient");
        }
        else {
          showToastr("info", "Successfully deleted");
          var ingredients = JSON.parse(JSON.stringify(ingredientInfo.ingredients)).filter(ingredient => ingredient.id != currentIngredient.id);
          console.log(ingredients);
          dispatch(setIngredientInfo({ ...ingredientInfo, ingredients: ingredients }));
        } 
      })
      .catch((err) => {
        console.error(err);
        if (err?.response?.status === 401) {
          showToastr('warning', 'Authorization Error', err.response.data.message);
          dispatch(sessionLogout());
          
          history.push('/');
          return;
        }
      });
      toggle('delete');
    } else {
      // ApiService.deleteLanguage(session, currentLanguage.id)
      //   .then((data) => {
      //     console.log(data);
      //     let tmpLanguages = languages.slice();
      //     const curLanguage = languages.filter(language => language.id == currentLanguage.id);
      //     if (curLanguage.length > 0) {
      //       const index = tmpLanguages.indexOf(curLanguage[0]);
      //       console.log(curLanguage[0]);
      //       console.log(index);
      //       if (index > -1) {
      //         tmpLanguages.splice(index, 1);
      //       }
      //     }
      //     console.log('Delete');
      //     console.log(tmpLanguages);

      //     setLanguages(tmpLanguages);
      //   })
      //   .catch((err) => {
      //     console.error(err);
      //   });

      toggle('delete');
    }
  }

  const handleDescriptionChange = (type, val) => {
    let prevImg = updatedIngredient.image;
    let tmpUpdatedIngredient = JSON.parse(JSON.stringify(updatedIngredient));
    tmpUpdatedIngredient.image = prevImg;

    if (type === 'friendly') {
      let tmpFriendlyDescription = tmpUpdatedIngredient.friendlyDescription;
      if (!tmpFriendlyDescription)
        tmpFriendlyDescription = {};

      tmpFriendlyDescription[language.friendly] = val;

      tmpUpdatedIngredient.friendlyDescription = tmpFriendlyDescription;

      setUpdatedIngredient(tmpUpdatedIngredient);

      setCurrentIngredient({ ...currentIngredient, friendlyDescription: { ...currentIngredient.friendlyDescription, [language.friendly]: val } });
    } else if (type === 'main') {
      let tmpDescription = tmpUpdatedIngredient.description;
      if (!tmpDescription)
        tmpDescription = {};

      tmpDescription[language.main] = val;

      tmpUpdatedIngredient.description = tmpDescription;
      setUpdatedIngredient(tmpUpdatedIngredient);

      setCurrentIngredient({ ...currentIngredient, description: { ...currentIngredient.description, [language.main]: val } });
    } else if (type === 'category') {
      let tmpCategoryDescription = tmpUpdatedIngredient.categoryDescription;
      if (!tmpCategoryDescription)
        tmpCategoryDescription = {};

      tmpCategoryDescription[language.category] = val;

      tmpUpdatedIngredient.categoryDescription = tmpCategoryDescription;
      setUpdatedIngredient(tmpUpdatedIngredient);

      setCurrentIngredient({ ...currentIngredient, categoryDescription: { ...currentIngredient.categoryDescription, [language.category]: val } });
    }
  }

  const handleCodeChange = (val) => {
    let prevImg = updatedIngredient.image;
    let tmpUpdatedIngredient = JSON.parse(JSON.stringify(updatedIngredient));
    tmpUpdatedIngredient.image = prevImg;
    tmpUpdatedIngredient.code = val;

    setUpdatedIngredient(tmpUpdatedIngredient);

    setCurrentIngredient({ ...currentIngredient, code: val });
  }

  const handleGroceryTypeChange = (val) => {
    let prevImg = updatedIngredient.image;
    let tmpUpdatedIngredient = JSON.parse(JSON.stringify(updatedIngredient));
    tmpUpdatedIngredient.image = prevImg;
    tmpUpdatedIngredient.groceryTypeId = val;

    setUpdatedIngredient(tmpUpdatedIngredient);

    setCurrentIngredient({ ...currentIngredient, groceryTypeId: val });
  }

  const handleSourceDatabaseChange = (val) => {
    let prevImg = updatedIngredient.image;
    let tmpUpdatedIngredient = JSON.parse(JSON.stringify(updatedIngredient));
    tmpUpdatedIngredient.image = prevImg;
    tmpUpdatedIngredient.sourceDatabase = val;

    setUpdatedIngredient(tmpUpdatedIngredient);

    setCurrentIngredient({ ...currentIngredient, sourceDatabase: val });
  }

  const handleCategoryCodeChange = (val) => {
    let prevImg = updatedIngredient.image;
    let tmpUpdatedIngredient = JSON.parse(JSON.stringify(updatedIngredient));
    tmpUpdatedIngredient.image = prevImg;
    tmpUpdatedIngredient.categoryCode = val;

    setUpdatedIngredient(tmpUpdatedIngredient);

    setCurrentIngredient({ ...currentIngredient, categoryCode: val });
  }

  const handleFieldChange = (index, value) => {
    let prevImg = updatedIngredient.image;
    let tmpUpdatedIngredient = JSON.parse(JSON.stringify(updatedIngredient));
    tmpUpdatedIngredient.image = prevImg;
    let tmpFields = tmpUpdatedIngredient.fields;
    tmpFields[index].id = value;
    tmpUpdatedIngredient.fields = tmpFields;
    setUpdatedIngredient(tmpUpdatedIngredient);

    prevImg = currentIngredient.image;
    let curIngredient = JSON.parse(JSON.stringify(currentIngredient));
    curIngredient.image = prevImg;
    let fields = curIngredient.fields;
    fields[index].id = value;
    curIngredient.fields = fields;

    setCurrentIngredient(curIngredient);
  }

  const handleFieldValChange = (id, val) => {
    let prevImg = updatedIngredient.image;
    let tmpUpdatedIngredient = JSON.parse(JSON.stringify(updatedIngredient));
    tmpUpdatedIngredient.image = prevImg;
    let tmpFields = tmpUpdatedIngredient.fields;
    if (!tmpFields)
      tmpFields = [];

    if (!tmpFields.find(tmpField => tmpField.id == id)) {
      tmpFields.push({
        id: id,
        value: val,
      });
    } else {
      tmpFields.find(tmpField => tmpField.id == id).value = val;
    }

    tmpUpdatedIngredient.fields = tmpFields;
    setUpdatedIngredient(tmpUpdatedIngredient);

    prevImg = currentIngredient.image;
    let curIngredient = JSON.parse(JSON.stringify(currentIngredient));
    curIngredient.image = prevImg;
    
    if (curIngredient.fields === undefined) {
      curIngredient.fields = [];
    }

    let field = curIngredient.fields.find(field => field.id == id);
    if (field !== undefined) {
      field.value = val;
    }
    else {
      curIngredient.fields.push({ id: id, value: val });
    }

    setCurrentIngredient(curIngredient);
  }

  const handleAddField = () => {
    let tmpFieldOptions = [];
    for (const id in fields) {
      if (fields[id].visible && !currentIngredient?.fields?.find(field => field.id == id)) {
        tmpFieldOptions.push({
          value: parseInt(id),
          label: fields[id].display,
        });
      }
    }
    setFieldOptions(tmpFieldOptions);

    let prevImg = updatedIngredient.image;
    let tmpUpdatedIngredient = JSON.parse(JSON.stringify(updatedIngredient));
    tmpUpdatedIngredient.image = prevImg;
    let tmpFields = tmpUpdatedIngredient.fields;
    if (!tmpFields)
      tmpFields = [];

    tmpFields.push({
      id: parseInt(tmpFieldOptions[0].value),
      value: '',
    });

    tmpUpdatedIngredient.fields = tmpFields;
    setUpdatedIngredient(tmpUpdatedIngredient);

    prevImg = currentIngredient.image;
    let curIngredient = JSON.parse(JSON.stringify(currentIngredient));
    curIngredient.image = prevImg;
    let curFields = curIngredient.fields;
    if (!curFields)
      curFields = [];

    curFields.push({
      id: parseInt(tmpFieldOptions[0].value),
      value: '',
    });

    curIngredient.fields = curFields;
    setCurrentIngredient(curIngredient);
  }

  const handleFilter = (e) => {
    handlePageChange(1);
    props.match.params.page = 1;

    e.preventDefault();
  }


  if (!ingredientInfo || !languages || !fields) {
    return (
      <Container fluid className="vh-50 d-flex justify-content-center align-items-center">
        <Spinner color="primary" size="lg" className="mr-2" />
      </Container>
    );
  }

  let languageOptions = [];
  for (const id in languages) {
    languageOptions.push({
      value: parseInt(id),
      label: languages[id].code,
    });
  }

  let groceryOptions = Object.values(groceries).map(g => ({value: g.id, label: g.description}));

  return (
    <div>
      <div className="mt-4 d-flex align-items-center justify-content-between">
        <div>
          <h3 style={{ color: '#3490DD', }}>List of Ingredients</h3>
          <p>Total {ingredientInfo.count}</p>
        </div>
        <div className="d-flex" style={{ height: 50, width: '60%', }}>
          <input type="file" accept="text/csv" id="import-file" style={{display: 'none'}} onChange={handleImportFileChange} onClick={(e) => {e.target.value = null}}/>
          <label htmlFor="import-file">
            <Button variant="contained" component="span" color="warning" style={{ width: 146, minWidth: 100, height: '100%', marginRight: 25, }} onClick={importIngredients}>
              Import
            </Button>
          </label>
          <Button color="warning" style={{ width: 146, minWidth: 100, height: '100%', marginRight: 25, }} onClick={exportIngredients}>
            Export
          </Button>
          <Form className="d-flex" style={{ width: '100%', }} onSubmit={e => handleFilter(e)}>
            <div style={{ display: 'flex', backgroundColor: 'white', alignItems: 'center', marginRight: 30, width: '100%', }}>
              <Input
                type="text"
                placeholder="Search"
                aria-label="Search"
                className="form-control-no-border mr-sm-2"
                value={filter}
                onChange={val => dispatch(setIngredientFilter(val.target.value))}
              />
              <FontAwesomeIcon icon={faSearch} style={{ color: '#6B6C72' }} className="mr-3" />
            </div>
            <Button color="warning" style={{ width: 146, minWidth: 100, height: '100%', marginRight: 25, }}>
              <img src={FilterIcon} className="mr-2" /> Filter
            </Button>
          </Form>
          <Button color="primary" style={{ width: 161, minWidth: 120, height: '100%', }} onClick={handleCreate}>
            <FontAwesomeIcon icon={faPlus} className="mr-2" /> Insert New
          </Button>
        </div>
      </div>

      <Table responsive striped hover>
        <thead>
          <tr style={{ backgroundColor: '#6B6C72', color: 'white', cursor: 'pointer'}}>
            {/* <th scope="col">Description (base)</th> */}
            <th scope="col" onClick={() => { flipDirection('code'); setSortCol('code') }} style={{ borderRadius: '6px 0 0 0' }}>Code</th>
            <th scope="col" onClick={() => { flipDirection('sourceDatabase'); setSortCol('sourceDatabase'); }}>Database</th>
            <th scope="col" onClick={() => { flipDirection('description'); setSortCol('description'); }}>Description</th>
            <th scope="col" onClick={() => { flipDirection('categoryCode'); setSortCol('categoryCode'); }}>Category ID</th>
            <th scope="col" onClick={() => { flipDirection('categoryDescription'); setSortCol('categoryDescription'); }}>Category</th>
            <th scope="col" onClick={() => { flipDirection('friendlyDescription'); setSortCol('friendlyDescription'); }}>Friendly Description</th>
            <th scope="col" onClick={() => { flipDirection('groceryDescription'); setSortCol('groceryDescription'); }}>Groceries Description</th>
            <th scope="col" onClick={() => { flipDirection('energy_cal'); setSortCol('energy_cal'); }}>Energy (kcal)</th>
            <th scope="col" style={{ borderRadius: '0 6px 0 0' }}>Action</th>
          </tr>
        </thead>
        <tbody>
          {
            ingredientInfo.ingredients.map((ingredient, index) => (
              <tr onClick={() => history.push(`/admin/ingredient/detail/${ingredient.id}`, { page, sortCol, sortDirection })} key={index}>
                <td>
                  {ingredient.code}
                </td>
                {/* <td>
                  {ingredient.descriptionBase[defaultLangId]}
                </td> */}
                <td>
                  {ingredient.sourceDatabase}
                </td>
                <td>
                  {ingredient.description[defaultLangId]}
                </td>
                <td>
                  {ingredient.categoryCode}
                </td>
                <td>
                  {ingredient.categoryDescription[defaultLangId]}
                </td>
                <td>
                  {ingredient.friendlyDescription[defaultLangId]}
                </td>
                <td>
                  {Object.values(groceries).find(go => go.id == [ingredient?.groceryTypeId])?.description ?? ''}
                </td>
                <td>
                  {getEnergy(ingredient)}
                </td>
                <td onClick={e => e.stopPropagation()}>
                  <FontAwesomeIcon className="table-action-button" icon={faPencilAlt} style={{ color: '#FC9C52', fontSize: 16, marginRight: 10, }} onClick={() => handleEdit(ingredient)} />
                  <FontAwesomeIcon className="table-action-button" icon={faTrashAlt} style={{ color: '#FF2048', fontSize: 16, }} onClick={() => handleDelete(ingredient)} />
                </td>
              </tr>
            ))
          }
        </tbody>
      </Table>

      <Pagination
        value={page}
        maxPage={ingredientInfo.pages}
        onChange={(val) => history.push(`/admin/ingredients/page=${val}`)}
      />

      {/* <Modal
        isOpen={createOpen}
        toggle={() => toggle('create')}
        centered
      >
        <ModalHeader toggle={() => toggle('create')}>
          Add Ingredient
        </ModalHeader>
        <ModalBody className="text-center m-3">
          <ImageUploader img={currentIngredient?.image ?? Image} onChange={(img) => { setUpdatedIngredient({ ...updatedIngredient, image: true, }); setCurrentIngredient({ ...currentIngredient, image: img }) }} />
          <div className="mt-3" style={{ color: '#6B6C72', fontSize: 14, fontWeight: 400, maxHeight: 400, overflowY: 'scroll', padding: '0 15%', }}>
            <div className="m-0 d-flex justify-content-between align-items-center" style={{ borderTop: '1px solid #dee2e6', padding: '10px 0', }}>
              <span>
                Code
              </span>
              <Input
                type="text"
                aria-label="Value"
                className="p-0 text-right"
                style={{ width: 70, height: 30, padding: '0 10px' }}
                value={currentIngredient?.code ?? ''}
                onChange={(val) => handleCodeChange(val.target.value)}
              />
            </div>
            <div className="m-0 d-flex justify-content-between align-items-center" style={{ borderTop: '1px solid #dee2e6', padding: '10px 0', }}>
              <span>
                Description
              </span>
              <Select
                className="react-select-container"
                classNamePrefix="react-select"
                styles={customStyles}
                options={languageOptions}
                isSearchable={false}
                defaultValue={languageOptions[0]}
                onChange={(item) => setLanguage({ ...language, main: item.value, })}
              />
              <Input
                type="text"
                aria-label="Value"
                className="p-0 text-center"
                style={{ width: 150, height: 30, padding: '0 10px' }}
                value={currentIngredient?.description?.[language.main] ?? ''}
                onChange={(val) => handleDescriptionChange('main', val.target.value)}
              />
            </div>
            <div className="m-0 d-flex justify-content-between align-items-center" style={{ borderTop: '1px solid #dee2e6', padding: '10px 0', }}>
              <span>
                Category ID
              </span>
              <Input
                type="text"
                aria-label="Value"
                className="p-0 text-right"
                style={{ width: 70, height: 30, padding: '0 10px' }}
                value={currentIngredient?.categoryCode ?? ''}
                onChange={(val) => handleCategoryCodeChange(val.target.value)}
              />
            </div>
            <div className="m-0 d-flex justify-content-between align-items-center" style={{ borderTop: '1px solid #dee2e6', padding: '10px 0', }}>
              <span>
                Category
              </span>
              <Select
                className="react-select-container"
                classNamePrefix="react-select"
                styles={customStyles}
                options={languageOptions}
                isSearchable={false}
                defaultValue={languageOptions[0]}
                onChange={(item) => setLanguage({ ...language, category: item.value, })}
              />
              <Input
                type="text"
                aria-label="Value"
                className="p-0 text-center"
                style={{ width: 150, height: 30, padding: '0 10px' }}
                value={currentIngredient?.categoryDescription?.[language.category] ?? ''}
                onChange={(val) => handleDescriptionChange('category', val.target.value)}
              />
            </div>
            <div className="m-0 d-flex justify-content-between align-items-center" style={{ borderTop: '1px solid #dee2e6', borderBottom: '1px solid #dee2e6', padding: '10px 0', }}>
              <span className="text-left">
                Friendly Description
              </span>
              <Select
                className="react-select-container"
                classNamePrefix="react-select"
                styles={customStyles}
                options={languageOptions}
                isSearchable={false}
                defaultValue={languageOptions[0]}
                onChange={(item) => setLanguage({ ...language, friendly: item.value, })}
              />
              <Input
                type="text"
                aria-label="Value"
                className="p-0 text-center"
                style={{ width: 150, height: 30, padding: '0 10px' }}
                value={currentIngredient?.friendlyDescription?.[language.friendly] ?? ''}
                onChange={(val) => handleDescriptionChange('friendly', val.target.value)}
              />
            </div>
            {
              currentIngredient?.fields ?
                currentIngredient.fields.map((field, index) => (
                  fields[field.id].visible ? (
                    <div className="m-0 d-flex justify-content-between align-items-center" style={{ borderBottom: '1px solid #dee2e6', padding: '10px 0', }} key={index}>
                      <Select
                        className="react-select-container"
                        classNamePrefix="react-select"
                        styles={customStyles1}
                        options={fieldOptions}
                        isSearchable={true}
                        defaultValue={fieldOptions[0]}
                        onChange={(item) => handleFieldChange(index, item.value)}
                      />
                      <Input
                        type="text"
                        aria-label="Value"
                        className="p-0 text-right"
                        style={{ width: 70, height: 30, padding: '0 10px' }}
                        value={field.value}
                        onChange={(val) => handleFieldValChange(field.id, val.target.value)}
                      />
                    </div>
                  ) : null
                )) : null
            }
            <AddItem
              text="Add a Field"
              onClick={handleAddField}
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => toggle('create')}>
            Cancel
          </Button>{" "}
          <Button color="primary" disabled={Object.keys(updatedIngredient).length === 0 ? true : false} onClick={() => handleConfirm('create')}>
            OK
          </Button>
        </ModalFooter>
      </Modal> */}
      { getEditIngredientModal("create", createOpen, "Add Ingredient") }
      { getEditIngredientModal("edit", editOpen, "Edit Ingredient") }
      {
      /*<Modal
        isOpen={editOpen}
        toggle={() => toggle('edit')}
        centered
      >
        <ModalHeader toggle={() => toggle('edit')}>
          Edit Ingredient
        </ModalHeader>
        <ModalBody className="text-center m-3">
          <ImageUploader img={currentIngredient?.image ?? Image} onChange={(img) => { setUpdatedIngredient({ ...updatedIngredient, image: true, }); setCurrentIngredient({ ...currentIngredient, image: img }) }} />
          <p className="mb-3 mt-2">
            <span style={{ color: '#3490DD', marginRight: 5, }}>
              Code:
            </span>
            <span>
              {currentIngredient?.code}
            </span>
          </p>
          <div style={{ color: '#6B6C72', fontSize: 14, fontWeight: 400, maxHeight: 400, overflowY: 'scroll', padding: '0 15%', }}>
            <div className="m-0 d-flex justify-content-between align-items-center" style={{ borderTop: '1px solid #dee2e6', padding: '10px 0', }}>
              <span>
                Description
              </span>
              <Select
                className="react-select-container"
                classNamePrefix="react-select"
                styles={customStyles}
                options={languageOptions}
                isSearchable={false}
                defaultValue={languageOptions[0]}
                onChange={(item) => setLanguage({ ...language, main: item.value, })}
              />
              <Input
                type="text"
                aria-label="Value"
                className="p-0 text-center"
                style={{ width: 150, height: 30, padding: '0 10px' }}
                value={currentIngredient?.description?.[language.main] ?? ''}
                onChange={(val) => handleDescriptionChange('main', val.target.value)}
              />
            </div>
            <div className="m-0 d-flex justify-content-between align-items-center" style={{ borderTop: '1px solid #dee2e6', padding: '10px 0', }}>
              <span>
                Category ID
              </span>
              <Input
                type="text"
                aria-label="Value"
                className="p-0 text-right"
                style={{ width: 70, height: 30, padding: '0 10px' }}
                value={currentIngredient?.categoryCode ?? ''}
                onChange={(val) => handleCategoryCodeChange(val.target.value)}
              />
            </div>
            <div className="m-0 d-flex justify-content-between align-items-center" style={{ borderTop: '1px solid #dee2e6', padding: '10px 0', }}>
              <span>
                Category
              </span>
              <Select
                className="react-select-container"
                classNamePrefix="react-select"
                styles={customStyles}
                options={languageOptions}
                isSearchable={false}
                defaultValue={languageOptions[0]}
                onChange={(item) => setLanguage({ ...language, category: item.value, })}
              />
              <Input
                type="text"
                aria-label="Value"
                className="p-0 text-center"
                style={{ width: 150, height: 30, padding: '0 10px' }}
                value={currentIngredient?.categoryDescription?.[language.category] ?? ''}
                onChange={(val) => handleDescriptionChange('category', val.target.value)}
              />
            </div>
            <div className="m-0 d-flex justify-content-between align-items-center" style={{ borderTop: '1px solid #dee2e6', padding: '10px 0', }}>
              <span className="text-left">
                Friendly Description
              </span>
              <Select
                className="react-select-container"
                classNamePrefix="react-select"
                styles={customStyles}
                options={languageOptions}
                isSearchable={false}
                defaultValue={languageOptions[0]}
                onChange={(item) => setLanguage({ ...language, friendly: item.value, })}
              />
              <Input
                type="text"
                aria-label="Value"
                className="p-0 text-center"
                style={{ width: 150, height: 30, padding: '0 10px' }}
                value={currentIngredient?.friendlyDescription?.[language.friendly] ?? ''}
                onChange={(val) => handleDescriptionChange('friendly', val.target.value)}
              />
            </div>
            {
              Object.keys(fields).map((field, index) => {
                return fields[field].visible ? (
                  <div className="m-0 d-flex justify-content-between align-items-center" style={{ borderTop: '1px solid #dee2e6', padding: '10px 0', }} key={index}>
                    <span>
                      {`${fields[field].display} (${fields[field].unit})`}
                    </span>
                    <Input
                      type="text"
                      aria-label="Value"
                      className="p-0 text-right"
                      style={{ width: 70, height: 30, padding: '0 10px' }}
                      value={getFieldValue(field, currentIngredient)}
                      onChange={(val) => handleFieldValChange(field, val.target.value)}
                    />
                  </div>
                ) : null
              })
            }
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => toggle('edit')}>
            Cancel
          </Button>{" "}
          <Button color="primary" disabled={Object.keys(updatedIngredient).length === 0 ? true : false} onClick={() => handleConfirm('edit')}>
            OK
          </Button>
        </ModalFooter>
      </Modal>
          */
      }

      <Modal
        isOpen={deleteOpen}
        toggle={() => toggle('delete')}
        centered
      >
        <ModalHeader toggle={() => toggle('delete')}>
          Delete ingredient
        </ModalHeader>
        <ModalBody className="text-center m-3">
          <p className="mb-0">
            Are you sure want to delete this ingredient?
          </p>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => toggle('delete')}>
            Cancel
          </Button>{" "}
          <Button color="danger" onClick={() => handleConfirm('delete')}>
            Delete
          </Button>
        </ModalFooter>
      </Modal>
    </div >
  );
}

export default Ingredients;
