import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Auth } from "aws-amplify";

import {
  Button,
  Card,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input
} from "reactstrap";
import { ApiService } from "../../services/apiService";

import { showToastr } from '../../services/themeService';


const ResetPassword = (props) => {

  const history = useHistory();
  const [code, setCode] = useState('');
  const [pass, setPass] = useState('');
  const [confirmPass, setConfirmPass] = useState('');

  const handleReset = async () => {
    if (pass != confirmPass) {
      showToastr('error', 'Invalid Request', 'Password confirmation doesn\'t match Password');

      return;
    }

    try {
      const email = props.location.state.email;
      const data = await Auth.forgotPasswordSubmit(email, code, pass);
      console.log(data);
      history.push('/admin/auth/sign-in');
    } catch (error) {
      showToastr('error', error.name, error.message);
      console.log(error);
    }
  }


  return (
    <React.Fragment>
      <div className="text-center mt-4">
        <h1 className="h2">Reset password</h1>
        <p className="lead text-center">Enter code and password to reset your password.</p>
      </div>

      <Card>
        <CardBody>
          <div className="m-sm-4">
            <Form>
              <FormGroup>
                <Label>Code</Label>
                <Input
                  bsSize="lg"
                  type="text"
                  name="code"
                  placeholder="Enter code"
                  value={code}
                  onChange={val => setCode(val.target.value)}
                />
              </FormGroup>
              <FormGroup>
                <Label>Password</Label>
                <Input
                  bsSize="lg"
                  type="password"
                  name="pass"
                  placeholder="Enter your password"
                  value={pass}
                  onChange={val => setPass(val.target.value)}
                />
              </FormGroup>
              <FormGroup>
                <Label>Confirm Password</Label>
                <Input
                  bsSize="lg"
                  type="password"
                  name="confirm-pass"
                  placeholder="Confirm password"
                  value={confirmPass}
                  onChange={val => setConfirmPass(val.target.value)}
                />
              </FormGroup>
              <div className="text-center mt-3">
                <Button color="primary" size="lg" onClick={handleReset}>
                  Reset password
                </Button>
              </div>
            </Form>
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  );
}


export default ResetPassword;
