import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Auth } from "aws-amplify";

import {
  Button,
  Card,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input
} from "reactstrap";
import { ApiService } from "../../services/apiService";

import { showToastr } from '../../services/themeService';


const ForgotPassword = () => {

  const history = useHistory();
  const [email, setEmail] = useState('');

  const handleSend = async () => {
    try {
      const data = await Auth.forgotPassword(email);
      console.log(data);
      history.push('/admin/auth/reset-password', { email });
    } catch (error) {
      console.log(error);
    }
  }


  return (
    <React.Fragment>
      <div className="text-center mt-4">
        <h1 className="h2">Forgot password</h1>
        <p className="lead text-center">Enter your User Name to send your user name.</p>
      </div>

      <Card>
        <CardBody>
          <div className="m-sm-4">
            <Form>
              <FormGroup>
                <Label>Email</Label>
                <Input
                  bsSize="lg"
                  type="email"
                  name="email"
                  placeholder="Enter your email"
                  value={email}
                  onChange={val => setEmail(val.target.value)}
                />
              </FormGroup>
              <div className="text-center mt-3">
                <Button color="primary" size="lg" onClick={handleSend}>
                  Send
                </Button>
              </div>
            </Form>
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  );
}


export default ForgotPassword;
