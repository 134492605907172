import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Auth } from 'aws-amplify';

import {
  Button,
  Card,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input
} from "reactstrap";
import { ApiService } from "../../services/apiService";

import { showToastr } from '../../services/themeService';


const ConfirmUser = (props) => {

  const history = useHistory();
  const [code, setCode] = useState('');

  const handleConfirm = async () => {
    try {
      console.log(props.location);
      const email = props.location.state.email;
      console.log(email);
      await Auth.confirmSignUp(email, code);
      history.push('/admin/auth/sign-in');
    } catch (error) {
        console.log('error confirming sign up', error);
    }
  }

  return (
    <React.Fragment>
      <div className="text-center mt-4">
        <h1 className="h2">Confirm User</h1>
        <p className="lead">Enter code to confirm your user name.</p>
      </div>

      <Card>
        <CardBody>
          <div className="m-sm-4">
            <Form>
              <FormGroup>
                <Label>Code</Label>
                <Input
                  bsSize="lg"
                  type="text"
                  name="code"
                  placeholder="Enter code"
                  value={code}
                  onChange={val => setCode(val.target.value)}
                />
              </FormGroup>
              <div className="text-center mt-3">
                <Button color="primary" size="lg" onClick={handleConfirm}>
                  Confirm
                </Button>
              </div>
            </Form>
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  );
}


export default ConfirmUser;
