import React, { useState, useEffect } from "react";
import classnames from "classnames";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import {
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Input,
  Container,
  Spinner,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Table,
  Row,
  Col,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt, faTrashAlt, faPlus } from "@fortawesome/free-solid-svg-icons";

import {
  setLanguages,
} from "../../redux/actions/apiActions";
import { ApiService } from "../../services/apiService";
import { sessionLogout } from "../../redux/actions/sessionActions";
import { showToastr } from "../../services/themeService";


const customStyles = {
  container: (provided, state) => ({
    ...provided,
    display: 'inline-block',
    width: '75%',
  }),
};

const AddItem = ({ text, onClick }) => {

  return (
    <a style={{ color: '#3490DD', marginTop: 30, display: 'block', textAlign: 'center', }} onClick={onClick}>
      <FontAwesomeIcon icon={faPlus} style={{ marginRight: 10, fontSize: 16, fontWeight: 600, }} />
      {text}
    </a>
  )
}


const Parameters = () => {

  const dispatch = useDispatch();
  const history = useHistory();

  const languages = useSelector((state) => state.api.languages);

  const [activeTab, setActiveTab] = useState(1);
  const [factors, setFactors] = useState(null);
  const [modifiedFactors, setModifiedFactors] = useState({});
  const [star, setStar] = useState('');
  const [cuisines, setCuisines] = useState(null);
  const [currentCuisine, setCurrentCuisine] = useState(null);
  const [editOpen, setEditOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [currentLanguage, setCurrentLanguage] = useState(null);
  const [languageOpen, setLanguageOpen] = useState(false);
  const [languageDeleteOpen, setLanguageDeleteOpen] = useState(false);

  

  useEffect(() => {
    
    ApiService.getFactors()
      .then(data => {
        console.log('factors: ', data);
        setFactors(data);
      })
      .catch((err) => {
        console.error('factors error: ', err);
        if (err.response.status === 401) {
          showToastr('warning', 'Authorization Error', err.response.data.message);
          dispatch(sessionLogout());

          history.push('/');
          return;
        }
      });
  }, []);

  useEffect(() => {
    if (factors)
      setModifiedFactors(factors);
  }, [factors])

  const handleAddCuisine = () => {
    setCurrentCuisine(null);

    setEditOpen(true);
  }

  const handleEditCuisine = (cuisine) => {
    setCurrentCuisine(cuisine);

    setEditOpen(true);
  }

  const handleDeleteCuisine = (cuisine) => {
    setCurrentCuisine(cuisine);

    setDeleteOpen(true);
  }

  const handleAddLanguage = () => {
    setCurrentLanguage({
      name: '',
      code: ''
    });

    setLanguageOpen(true);
  }

  const handleEdit = () => {

    let tmpCuisines = cuisines.slice();
    const tmpCuisine = tmpCuisines.find(tmpCuisine => tmpCuisine.id === currentCuisine.id);
    const index = tmpCuisines.indexOf(tmpCuisine);

    tmpCuisines[index] = currentCuisine;

    setCuisines(tmpCuisines);
    setEditOpen(false);
  }

  const handleDelete = () => {
    let tmpCuisines = cuisines.slice();
    const tmpCuisine = tmpCuisines.find(tmpCuisine => tmpCuisine.id === currentCuisine.id);
    if (tmpCuisine) {
      const index = tmpCuisines.indexOf(tmpCuisine);
      if (index > -1) {
        tmpCuisines.splice(index, 1);
      }
    }

    setCuisines(tmpCuisines);

    setDeleteOpen(false);
  }

  const handleEditFactor = () => {
    setModifiedFactors(factors);

    setEditMode(true);
  }

  const handleSaveFactor = () => {
    if (modifiedFactors['RDD Factor'] == factors['RDD Factor'] && modifiedFactors['Star Factor'] == factors['Star Factor']) {
      setEditMode(false);

      return;
    }
    setFactors(null);

    ApiService.updateFactors(modifiedFactors)
      .then(data => {
        // if (data && data.message === "Unauthenticated.") {
        //   history.push('/admin');

        //   return;
        // }
        console.log(data);
        setFactors(data);
      })
      .catch((err) => {
        console.log('catch');
        console.error(err);
        if (err.response.status === 401) {
          showToastr('warning', 'Authorization Error', err.response.data.message);
          dispatch(sessionLogout());

          history.push('/');
          return;
        }
      });

    setEditMode(false);
  }

  const handleCancelFactor = () => {
    setModifiedFactors(factors);

    setEditMode(false);
  }

  const handleEditLanguage = (language) => {
    setCurrentLanguage(language);

    setLanguageOpen(true);
  }

  const handleSaveLanguage = () => {
    ApiService.createLanguage(currentLanguage.name, currentLanguage.code)
      .then(data => {
        // if (data && data.message === "Unauthenticated.") {
        //   history.push('/admin');

        //   return;
        // }
        console.log('add language: ', data);
        let tmpLanguages = JSON.parse(JSON.stringify(languages));
        tmpLanguages[data.id] = {
          name: data.name,
          code: data.code,
        };

        dispatch(setLanguages(tmpLanguages));
      })
      .catch((err) => {
        console.log('catch');
        console.error(err);
        if (err.response.status === 401) {
          showToastr('warning', 'Authorization Error', err.response.data.message);
          dispatch(sessionLogout());

          history.push('/');
          return;
        }
      });

    setLanguageOpen(false);
  }

  const handleDeleteLanguage = (language) => {
    setCurrentLanguage({
      id: language[0],
      name: language[1].name,
      code: language[1].code,
    });

    setLanguageDeleteOpen(true);
  }

  const handleConfirmLanguage = () => {
    ApiService.deleteLanguage(currentLanguage.id)
      .then(data => {
        // if (data && data.message === "Unauthenticated.") {
        //   history.push('/admin');

        //   return;
        // }
        let tmpLanguages = JSON.parse(JSON.stringify(languages));
        delete tmpLanguages[currentLanguage.id];

        dispatch(setLanguages(tmpLanguages));
      })
      .catch((err) => {
        console.log('delete language error: ', err);
        if (err.response.status === 401) {
          showToastr('warning', 'Authorization Error', err.response.data.message);
          dispatch(sessionLogout());

          history.push('/');
          return;
        }
      });

    setLanguageDeleteOpen(false);
  }

  const statusOptions = [
    {
      value: 0,
      label: 'Draft',
    },
    {
      value: 1,
      label: 'Publish',
    },
  ];


  if (!factors || !languages) {
    return (
      <Container fluid className="vh-50 d-flex justify-content-center align-items-center">
        <Spinner color="primary" size="lg" className="mr-2" />
      </Container>
    );
  }


  return (
    <React.Fragment>
      <div style={{ padding: '0 30px' }}>
        <Nav tabs>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === 1 })}
              onClick={() => {
                setActiveTab(1);
              }}
            >
              RDD & Star Factor
            </NavLink>
          </NavItem>
          {/* <NavItem>
            <NavLink
              className={classnames({ active: activeTab === 2 })}
              onClick={() => {
                setActiveTab(2);
              }}
            >
              Cuisines
            </NavLink>
          </NavItem> */}
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === 3 })}
              onClick={() => {
                setActiveTab(3);
              }}
            >
              Languages
            </NavLink>
          </NavItem>
        </Nav>

        <TabContent activeTab={activeTab} style={{ padding: '50px 0' }}>
          <TabPane tabId={1}>
            <p style={{ width: 300, display: 'flex', justifyContent: 'flex-end', }}>
              <span className="meal-type-item-button mr-2" style={{ backgroundColor: '#FFF1E6' }} onClick={handleEditFactor}>
                <FontAwesomeIcon icon={faPencilAlt} style={{ color: '#FC9C52', }} />
              </span>
            </p>
            <span>
              RDD Factor
            </span>
            <Input
              type="text"
              aria-label="Value"
              className="mt-2 mb-2"
              style={{ width: 300, }}
              readOnly={editMode ? false : true}
              value={modifiedFactors['RDD Factor'] || ''}
              onChange={(val) => setModifiedFactors({ ...modifiedFactors, ['RDD Factor']: val.target.value })}
            />
            <span>
              Star Factor
            </span>
            <Input
              type="text"
              aria-label="Value"
              className="mt-2 mb-2"
              style={{ width: 300, }}
              readOnly={editMode ? false : true}
              value={modifiedFactors['Star Factor'] || ''}
              onChange={(val) => setModifiedFactors({ ...modifiedFactors, ['Star Factor']: val.target.value })}
            />
            {
              editMode ? (
                <div className="d-flex align-items-center justify-content-center mt-3" style={{ width: 300, }}>
                  <Button className="mr-2" color="secondary" onClick={handleCancelFactor}>Cancel</Button>
                  <Button color="primary" onClick={handleSaveFactor}>Save</Button>
                </div>
              ) : null
            }
          </TabPane>
          {/* <TabPane tabId={2}>
            <Row>
              {
                cuisines ? cuisines.map((cuisine, index) => (
                  <Col sm="6" md="4" lg="3" key={index}>
                    <div className="cuisine-item" style={{ fontSize: 18, fontWeight: 'bold', }}>
                      {cuisine.name}
                      <div className="d-flex align-items-center">
                        <FontAwesomeIcon icon={faPencilAlt} style={{ color: '#FC9C52', marginRight: 10, }} onClick={() => handleEditCuisine(cuisine)} />
                        <FontAwesomeIcon icon={faTrashAlt} style={{ color: '#FF2048', }} onClick={() => handleDeleteCuisine(cuisine)} />
                      </div>
                    </div>
                  </Col>
                )) : null
              }
            </Row>
            <AddItem
              text="Create a Cuisine"
              onClick={handleAddCuisine}
            />
          </TabPane> */}
          <TabPane tabId={3}>
            <Table responsive striped hover>
              <thead>
                <tr style={{ backgroundColor: '#6B6C72', color: 'white', }}>
                  <th scope="col" style={{ borderRadius: '6px 0 0 0' }}>ID</th>
                  <th scope="col">Language</th>
                  <th scope="col">Code</th>
                  {/* <th scope="col">Status</th> */}
                  <th scope="col" style={{ borderRadius: '0 6px 0 0', width: 65, }}>Action</th>
                </tr>
              </thead>
              <tbody>
                {
                  Object.entries(languages).map((language, index) => (
                    <tr key={index}>
                      <td>
                        {language[0]}
                      </td>
                      <td>
                        {language[1].name}
                      </td>
                      <td>
                        {language[1].code}
                      </td>
                      {/* <td>
                        {language[1].status}
                      </td> */}
                      <td className="text-center">
                        {/* <FontAwesomeIcon className="table-action-button" icon={faPencilAlt} style={{ color: '#FC9C52', fontSize: 16, marginRight: 10, }} onClick={() => handleEditLanguage(language)} /> */}
                        <FontAwesomeIcon className="table-action-button" icon={faTrashAlt} style={{ color: '#FF2048', fontSize: 16, }} onClick={() => handleDeleteLanguage(language)} />
                      </td>
                    </tr>
                  ))
                }
              </tbody>
            </Table>
            <AddItem
              text="Create a Language"
              onClick={handleAddLanguage}
            />
          </TabPane>
        </TabContent>
      </div>

      <Modal
        isOpen={editOpen}
        toggle={() => setEditOpen(!editOpen)}
        centered
      >
        <ModalHeader toggle={() => setEditOpen(!editOpen)}>
          Edit Cuisine
        </ModalHeader>
        <ModalBody className="text-center m-3">
          <span style={{ fontSize: 18, fontWeight: 'bold', marginBottom: 10, }}>
            Input Cuisine Name.
          </span>
          <Input
            type="text"
            aria-label="Value"
            className="p-0 text-center"
            style={{ width: '75%', margin: 'auto', padding: '0 10px' }}
            value={currentCuisine ? currentCuisine.name : ''}
            onChange={(val) => setCurrentCuisine({ ...currentCuisine, name: val.target.value })}
          />
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => setEditOpen(!editOpen)}>
            Cancel
          </Button>{" "}
          <Button color="primary" onClick={() => handleEdit()}>
            OK
          </Button>
        </ModalFooter>
      </Modal>

      <Modal
        isOpen={deleteOpen}
        toggle={() => setDeleteOpen(!deleteOpen)}
        centered
      >
        <ModalHeader toggle={() => setDeleteOpen(!deleteOpen)}>
          Delete Cuisine
        </ModalHeader>
        <ModalBody className="text-center m-3">
          <p className="mb-0">
            Are you sure want to delete this cuisine?
          </p>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => setDeleteOpen(!deleteOpen)}>
            Cancel
          </Button>{" "}
          <Button color="danger" onClick={() => handleDelete()}>
            Delete
          </Button>
        </ModalFooter>
      </Modal>

      <Modal
        isOpen={languageOpen}
        toggle={() => setLanguageOpen(!languageOpen)}
        centered
      >
        <ModalHeader toggle={() => setLanguageOpen(!languageOpen)}>
          Edit Language
        </ModalHeader>
        <ModalBody className="text-center m-3">
          <Col className="w-50 m-auto">
            <p style={{ fontSize: 18, fontWeight: 'bold', marginBottom: 10, }}>
              Input Language.
            </p>
            <Row className="mb-2 align-items-center justify-content-between">
              <span className="mr-2">
                Name
              </span>
              <Input
                type="text"
                aria-label="Value"
                className="p-0 text-center m-0"
                style={{ width: '75%', margin: 'auto', padding: '0 10px' }}
                value={currentLanguage ? currentLanguage.name : ''}
                onChange={(val) => setCurrentLanguage({ ...currentLanguage, name: val.target.value })}
              />
            </Row>
            <Row className="mb-2 align-items-center justify-content-between">
              <span className="mr-2">
                Code
              </span>
              <Input
                type="text"
                aria-label="Value"
                className="p-0 text-center m-0"
                style={{ width: '75%', margin: 'auto', padding: '0 10px' }}
                value={currentLanguage ? currentLanguage.code : ''}
                onChange={(val) => setCurrentLanguage({ ...currentLanguage, code: val.target.value })}
              />
            </Row>
            {/* <Row className="mb-2 align-items-center justify-content-between">
              <span className="mr-2">
                Status
            </span>
              <Select
                className="react-select-container"
                classNamePrefix="react-select"
                styles={customStyles}
                options={statusOptions}
                isSearchable={false}
                defaultValue={statusOptions.find(statusOption => statusOption.value === currentLanguage?.status)}
                onChange={(item) => setCurrentLanguage({ ...currentLanguage, status: item.value })}
              />
            </Row> */}
          </Col>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => setLanguageOpen(!languageOpen)}>
            Cancel
          </Button>{" "}
          <Button color="primary" onClick={() => handleSaveLanguage()}>
            OK
          </Button>
        </ModalFooter>
      </Modal>

      <Modal
        isOpen={languageDeleteOpen}
        toggle={() => setLanguageDeleteOpen(!languageDeleteOpen)}
        centered
      >
        <ModalHeader toggle={() => setLanguageDeleteOpen(!languageDeleteOpen)}>
          Delete Language
        </ModalHeader>
        <ModalBody className="text-center m-3">
          <p className="mb-0">
            Are you sure want to delete this language?
          </p>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => setLanguageDeleteOpen(!languageDeleteOpen)}>
            Cancel
          </Button>{" "}
          <Button color="danger" onClick={() => handleConfirmLanguage()}>
            Delete
          </Button>
        </ModalFooter>
      </Modal>

    </React.Fragment>
  );
}

export default Parameters;
