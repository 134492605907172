import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import Select from "react-select";
import {
  Button,
  Table,
  Input,
  Container,
  Spinner,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  Row,
  Col,
  CardHeader,
  CardTitle,
  CardBody,
  Card,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSearch,
  faPencilAlt,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";

import { ApiService } from "../../services/apiService";
import Pagination from '../../components/Pagination';
import { showToastr } from '../../services/themeService';

import { sessionLogout } from '../../redux/actions/sessionActions';

import FilterIcon from '../../assets/img/filter.png';


const customStyles = {
  container: (provided) => ({
    ...provided,
    display: 'inline-block',
    width: '60%',
  }),
};

function formatDate(date) {
  var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2)
    month = '0' + month;
  if (day.length < 2)
    day = '0' + day;

  return [year, month, day].join('-');
}


const Users = (props) => {

  const history = useHistory();
  const dispatch = useDispatch();

  const page = parseInt(props.match.params.page);

  const countries = useSelector(state => state.api.countries);

  const [userInfo, setUserInfo] = useState(null);

  const [currentUser, setCurrentUser] = useState(null);
  const [filter, setFilter] = useState('');
  const [userGroupOptions, setUserGroupOptions] = useState([]);
  const [userGroup, setUserGroup] = useState(null);
  const [open, setOpen] = useState(false);
  const [sortCol, setSortCol] = useState('email');
  const [sortDirection, setSortDirection] = useState('asc');

  const [editMode, setEditMode] = useState(false);

  const count = 10;

  const flipDirection = (col) => {
    if (sortCol == col) {
      if (sortDirection == "asc")
        setSortDirection("desc");
      else
        setSortDirection("asc");
    }
    else {
      setSortDirection("asc");
    }
  }

  useEffect(() => {
    console.log("?");
    ApiService.getUserGroups()
      .then(data => {
        console.log('userGroups', data);
        let userGroupOptions = [];

        for (const id in data) {
          userGroupOptions.push({
            value: parseInt(id),
            label: data[id].description,
          });
        }

        console.log('userGroupOptions', userGroupOptions);

        setUserGroupOptions(userGroupOptions);
      })
      .catch(error => {
        console.log(error);
        if (error.response.status === 401) {
          showToastr('warning', 'Authorization Error', error.response.data.message);
          dispatch(sessionLogout());

          history.push('/');
          return;
        }
      });

    handlePageChange(page);
  }, []);

  useEffect(() => {
    handlePageChange(page);
  }, [sortCol, sortDirection, page])

  const handlePageChange = () => {
    setUserInfo(null);

    ApiService.getUsers(page, count, sortCol, sortDirection, filter)
      .then(data => {
        console.log('users', data);
        setUserInfo(data);
      })
      .catch((error) => {
        console.log('users error', error);
        if (error.response.status === 401) {
          showToastr('warning', 'Authorization Error', error.response.data.message);
          dispatch(sessionLogout());

          history.push('/');
          return;
        }
      });
  }

  const handleEdit = () => {
    setEditMode(true);
  }

  const handleConfirm = () => {
    ApiService.deleteUser(currentUser.id)
      .then((data) => {
        console.log(data);
        let tmpUsers = userInfo.users.slice();
        const curUser = userInfo.users.filter(user => user.id == currentUser.id);
        if (curUser.length > 0) {
          const index = tmpUsers.indexOf(curUser);

          if (index > -1) {
            tmpUsers.splice(index, 1);
          }
        }

        setUserInfo({ ...userInfo, items: tmpUsers });

        setCurrentUser(null);
      })
      .catch((error) => {
        console.error(error);
        if (error.response.status === 401) {
          showToastr('warning', 'Authorization Error', error.response.data.message);
          dispatch(sessionLogout());

          history.push('/');
          return;
        }
      });

    setOpen(false);
  }

  const handleFilter = (e) => {
    handlePageChange(1);

    e.preventDefault();
  }

  const handleClickUser = (user) => {
    console.log('current user', user);
    setCurrentUser(user);

    if (user.userGroupId)
      setUserGroup(userGroupOptions.find(userGroupOption => userGroupOption.value === user.userGroupId));
    else
      setUserGroup(userGroupOptions[0]);
  }

  const handleSave = () => {
    let updatedUser = {};

    updatedUser.email = currentUser.email;
    updatedUser.userGroupId = userGroup.value;

    console.log(updatedUser);

    ApiService.updateUser(currentUser.id, updatedUser)
      .then(data => {
        let tmpUsers = userInfo.users.slice();
        let curUser = userInfo.users.find(user => user.id == currentUser.id);
        console.log(curUser);
        const index = tmpUsers.indexOf(curUser);

        tmpUsers[index] = data;

        setUserInfo({ ...userInfo, users: tmpUsers });

        setCurrentUser(data);

        setEditMode(false);
      })
      .catch(error => {
        console.log(error);
        if (error.response.status === 401) {
          showToastr('warning', 'Authorization Error', error.response.data.message);
          dispatch(sessionLogout());

          history.push('/');
          return;
        }
      });

    setEditMode(false);
  }

  const handleCancel = () => {
    setEditMode(false);
  }


  if (!userInfo || !countries) {
    return (
      <Container fluid className="vh-50 d-flex justify-content-center align-items-center">
        <Spinner color="primary" size="lg" className="mr-2" />
      </Container>
    );
  }


  return (
    <div>
      <Row>
        <Col xl="8">
          <div className="mt-4 d-flex align-items-center justify-content-between">
            <div>
              <h3 style={{ color: '#3490DD', }}>List of Users</h3>
              <p>Total {userInfo.count}</p>
            </div>
            <div className="d-flex" style={{ height: 50, width: '60%', }}>
              <Form className="d-flex" style={{ width: '100%', }} onSubmit={e => handleFilter(e)}>
                <div style={{ display: 'flex', backgroundColor: 'white', alignItems: 'center', marginRight: 30, width: '100%', }}>
                  <Input
                    type="text"
                    placeholder="Search"
                    aria-label="Search"
                    className="form-control-no-border mr-sm-2"
                    value={filter}
                    onChange={val => setFilter(val.target.value)}
                  />
                  <FontAwesomeIcon icon={faSearch} style={{ color: '#6B6C72' }} className="mr-3" />
                </div>
                <Button color="warning" style={{ width: 146, minWidth: 100, height: '100%', }}>
                  <img src={FilterIcon} className="mr-2" /> Filter
                </Button>
              </Form>
            </div>
          </div>

          <Table responsive striped hover>
            <thead>
              <tr style={{ backgroundColor: '#6B6C72', color: 'white', cursor: 'pointer'}}>
                <th scope="col" style={{ borderRadius: '6px 0 0 0' }}>ID</th>
                <th className="text-center">Avatar</th>
                <th onClick={() => { flipDirection('name'); setSortCol('name') }}>Name</th>
                <th onClick={() => { flipDirection('email'); setSortCol('email') }}>Email</th>
                <th onClick={() => { flipDirection('city'); setSortCol('city') }}>City</th>
                <th onClick={() => { flipDirection('countryId'); setSortCol('countryId') }}>Country</th>
                <th onClick={() => { flipDirection('createdAt'); setSortCol('createdAt') }}>Reg. Date</th>
                <th scope="col" style={{ borderRadius: '0 6px 0 0' }}>Status</th>
              </tr>
            </thead>
            <tbody>
              {
                userInfo.users?.map((user, index) => (
                  <tr onClick={() => handleClickUser(user)} key={index}>
                    <td>
                      {user.id}
                    </td>
                    <td className="text-center">
                      <img
                        src={user.photo}
                        width="32"
                        height="32"
                        className="rounded-circle my-n1"
                        alt="Avatar"
                      />
                    </td>
                    <td>
                      {user.name}
                    </td>
                    <td>
                      {user.email}
                    </td>
                    <td>
                      {user.city}
                    </td>
                    <td>
                      {user ? countries[user.countryId]?.name : ''}
                    </td>
                    <td>
                      {user ? user.createdAt : ''}
                    </td>
                    <td>

                    </td>
                  </tr>
                ))
              }
            </tbody>
          </Table>

          <Pagination
            value={page}
            maxPage={userInfo.pages}
            onChange={(val) => history.push(`/admin/users/page=${val}`)}
          />
        </Col>
        <Col xl="4">
          <Card>
            <CardHeader>
              {
                currentUser ? (
                  <div className="card-actions float-right">
                    <FontAwesomeIcon className="pointer" icon={faPencilAlt} style={{ fontSize: 18, color: '#FC9C52', marginRight: 10, }} onClick={handleEdit} />
                    <FontAwesomeIcon className="pointer" icon={faTrashAlt} style={{ fontSize: 18, color: '#FF2048', }} onClick={() => setOpen(true)} />
                  </div>
                ) : null
              }
              <CardTitle tag="h5" className="mb-0">
                {currentUser ? currentUser.name : ''}
              </CardTitle>
            </CardHeader>
            <CardBody>
              <Row noGutters>
                <Col sm="3" xl="12" className="col-xxl-3 text-center">
                  <img
                    src={currentUser ? currentUser.photo : ''}
                    width="64"
                    height="64"
                    className="rounded-circle mt-2"
                    alt="Avatar"
                  />
                </Col>
                <Col sm="9" xl="12" className="col-xxl-9">
                  <strong>About me</strong>
                  <p>

                  </p>
                </Col>
              </Row>

              <div>
                <div style={{ borderBottom: '0.1px solid #dee2e6', borderTop: '0.1px solid #dee2e6', padding: 10, }}>
                  <span style={{ width: '30%', fontWeight: 600, display: 'inline-block', }}>
                    Name
                  </span>
                  <span>
                    {currentUser ? currentUser.name : ''}
                  </span>
                </div>
                <div style={{ borderBottom: '0.1px solid #dee2e6', padding: 10, }}>
                  <span style={{ width: '30%', fontWeight: 600, display: 'inline-block', }}>
                    Email
                  </span>
                  <span>
                    {currentUser ? currentUser.email : ''}
                  </span>
                </div>
                <div style={{ borderBottom: '0.1px solid #dee2e6', padding: 10, }}>
                  <span style={{ width: '30%', fontWeight: 600, display: 'inline-block', }}>
                    Gender
                  </span>
                  <span>
                    {currentUser ? currentUser.gender : ''}
                  </span>
                </div>
                <div style={{ borderBottom: '0.1px solid #dee2e6', padding: 10, }}>
                  <span style={{ width: '30%', fontWeight: 600, display: 'inline-block', }}>
                    Birthday
                  </span>
                  <span>
                    {currentUser ? formatDate(currentUser.birthday) : ''}
                  </span>
                </div>
                <div style={{ borderBottom: '0.1px solid #dee2e6', padding: 10, }}>
                  <span style={{ width: '30%', fontWeight: 600, display: 'inline-block', }}>
                    City
                  </span>
                  <span>
                    {currentUser ? currentUser.city : ''}
                  </span>
                </div>
                <div style={{ borderBottom: '0.1px solid #dee2e6', padding: 10, }}>
                  <span style={{ width: '30%', fontWeight: 600, display: 'inline-block', }}>
                    Country
                  </span>
                  <span>
                    {currentUser ? countries[currentUser.countryId]?.name : ''}
                  </span>
                </div>
                <div style={{ borderBottom: '0.1px solid #dee2e6', padding: 10, }}>
                  <span style={{ width: '30%', fontWeight: 600, display: 'inline-block', }}>
                    User Group
                  </span>
                  {
                    editMode ? (
                      <Select
                        className="react-select-container"
                        classNamePrefix="react-select"
                        styles={customStyles}
                        options={userGroupOptions}
                        isSearchable={false}
                        onChange={(item) => setUserGroup(item)}
                        value={userGroup}
                      />
                    ) : (
                      <span>
                        {userGroupOptions.find(userGroupOption => userGroupOption.value === currentUser?.userGroupId)?.label}
                      </span>
                    )
                  }
                </div>
                <div style={{ borderBottom: '0.1px solid #dee2e6', padding: 10, }}>
                  <span style={{ width: '30%', fontWeight: 600, display: 'inline-block', }}>
                    Reg. Date
                  </span>
                  <span>
                    {currentUser ? currentUser.createdAt : ''}
                  </span>
                </div>
              </div>
              {
                editMode ? (
                  <div className="d-flex align-items-center justify-content-center mt-3">
                    <Button className="mr-2" color="secondary" onClick={handleCancel}>Cancel</Button>
                    <Button color="primary" onClick={handleSave}>Save</Button>
                  </div>
                ) : null
              }
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Modal
        isOpen={open}
        toggle={() => setOpen(!open)}
        centered
      >
        <ModalHeader toggle={() => setOpen(!open)}>
          Delete user
        </ModalHeader>
        <ModalBody className="text-center m-3">
          <p className="mb-0">
            Are you sure want to delete this user?
          </p>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => setOpen(false)}>
            Cancel
          </Button>{" "}
          <Button color="danger" onClick={handleConfirm}>
            Delete
          </Button>
        </ModalFooter>
      </Modal>
    </div >
  );
}

export default Users;
