// Sidebar
export const SIDEBAR_VISIBILITY_TOGGLE = "SIDEBAR_VISIBILITY_TOGGLE";

// Theme
export const THEME_TOGGLE = "THEME_TOGGLE";

//Session
export const SESSION_LOGIN = "SESSION_LOGIN";
export const SESSION_LOGOUT = "SESSION_LOGOUT";
export const SESSION_INFO = "SESSION_INFO";

//Api
export const INGREDIENTS_UPDATE = "INGREDIENTS_UPDATE";
export const INGREDIENTS_NONE = "INGREDIENTS_NONE";

export const RDDS_UPDATE = "RDDS_UPDATE";
export const RDDS_NONE = "RDDS_NONE";

export const RECIPES_UPDATE = "RECIPES_UPDATE";
export const RECIPES_NONE = "RECIPES_NONE";
export const RECIPES_FILTER = "RECIPES_FILTER";
export const RECIPES_OPTION = "RECIPES_OPTION";

export const FIELDS_UPDATE = "FIELDS_UPDATE";
export const FILTER_INGREDIENT_UPDATE = "FILTER_INGREDIENT_UPDATE";
export const FILTER_RECIPE_UPDATE = "FILTER_RECIPE_UPDATE";
export const CALCTYPES_UPDATE = "CALCTYPES_UPDATE";
export const LANGUAGES_UPDATE = "LANGUAGES_UPDATE";
export const KEYWORD_UPDATE = "KEYWORD_UPDATE";
export const TABS_UPDATE = "TABS_UPDATE";
export const GROUPS_UPDATE = "GROUPS_UPDATE";
export const GROCERIES_UPDATE = "GROCERIES_UPDATE";
export const CUISINES_UPDATE = "CUISINES_UPDATE";
export const VEGETARIANTYPES_UPDATE = "VEGETARIANTYPES_UPDATE";
export const DIFFICULTYLEVELS_UPDATE = "DIFFICULTYLEVELS_UPDATE";
export const COUNTRIES_UPDATE = "COUNTRIES_UPDATE";
export const PROFILE_UPDATE = "PROFILE_UPDATE";
export const MEALLEVELS_UPDATE = "MEALLEVELS_UPDATE";