import React, { useState, useEffect } from "react";
import { useSelector, useDispatch, } from 'react-redux';
import Select from 'react-select';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {
  Button,
  Input,
  Row,
  Col,
  Container,
  Spinner,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPencilAlt,
  faPlus,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import Rating from 'react-rating';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { FaStar } from 'react-icons/fa';
import { FiStar } from 'react-icons/fi';

import BackButton from '../../components/BackButton';
import ImageUploader from '../../components/ImageUploader';
import PieChart from "../../components/PieChart";
import { ApiService } from "../../services/apiService";
import IngredientElementItem from './IngredientElementItem';
import InstructionStepItem from './InstructionStepItem';
import Pagination from '../../components/Pagination';
import { ReactS3Client } from '../../config/aws-export';
import { showToastr } from '../../services/themeService';

import { sessionLogout } from '../../redux/actions/sessionActions';

import PrepImage from '../../assets/img/prep.png';
import CookImage from '../../assets/img/cook.png';
import PersonalizeImage from '../../assets/img/personalize.png';
import ImageAddImage from '../../assets/img/add-image.png';
import FilterIcon from '../../assets/img/filter.png';
import ActImage1 from '../../assets/img/act-1.png';
import ActImage2 from '../../assets/img/act-2.png';
import ActImage3 from '../../assets/img/act-3.png';
import ActImage4 from '../../assets/img/act-4.png';
import ActImage5 from '../../assets/img/act-5.png';
import ActImage6 from '../../assets/img/act-6.png';

import StroberiImage from '../../assets/img/tmp/stroberi.png';
import BroccoliImage from '../../assets/img/tmp/broccoli.png';
import MangoImage from '../../assets/img/tmp/mango.png';
import FoodImage1 from '../../assets/img/tmp/food-1.png';

import DefaultImage from '../../assets/img/tmp/coconut-milk.png';

import {
  setRDDInfo,
} from "../../redux/actions/apiActions";
import { text } from "@fortawesome/fontawesome-svg-core";

import Resizer from "react-image-file-resizer";

const IMG_SIZE = {
  normal: [512, 384],
  small: [256, 192],
};

const colorGroup = [
  '#4CC0B5',
  '#FBAD63',
  '#000000',
];

const imageGroup = [
  StroberiImage,
  BroccoliImage,
  MangoImage,
];

const customStyles = {
  container: (provided, state) => ({
    ...provided,
    display: 'inline-block',
    width: 80,
    marginLeft: 'auto',
    marginRight: 5,
  }),
};

const customStyles1 = {
  container: (provided, state) => ({
    ...provided,
    display: 'inline-block',
    width: 210,
    marginLeft: 'auto',
    marginBottom: 5,
  }),
};

const AddItem = ({ text, onClick, hidden }) => {

  return (
    <a style={{ color: '#3490DD', marginTop: 30, display: 'block', textAlign: 'center', }} onClick={onClick} hidden={hidden}>
      <FontAwesomeIcon icon={faPlus} style={{ marginRight: 10, fontSize: 16, fontWeight: 600, }} />
      {text}
    </a>
  )
}

const GetMissingFields = (request, fieldNames, exists) => {
  let missingFields = [];
  for (let i = 0; i < fieldNames.length; i++) {
    if (!exists) {
      if (!request.hasOwnProperty(fieldNames[i]) || request[fieldNames[i]] === undefined || request[fieldNames[i]].length === 0) {
        missingFields.push(fieldNames[i]);
      }
    }
    else {
      if (request.hasOwnProperty(fieldNames[i]) && (request[fieldNames[i]] === undefined || request[fieldNames[i]].length === 0)) {
        missingFields.push(fieldNames[i]);
      }
    }
  }
  return missingFields;
}

const ValidateRecipeFields = (request, exists) => {
  return GetMissingFields(request, [
    "title", 
    "preparationTime", 
    "cookingTime", 
    "cuisineId", 
    "difficultyLevelId", 
    "vegetarianTypeId",
    // "ingredients",
    // "instructions"
  ], exists)
}

const fileToBlob = (file) => new Promise((resolve) => {
  const reader = new FileReader();
  reader.onload = (event) => {
    resolve(new Blob([event.target.result], {type: file.type}))
  };
  reader.readAsArrayBuffer(file);
})

const resizeFile = (filename, width, height, format, quality, rotation, type) =>
  new Promise((resolve) => {
    fileToBlob(filename)
      .then(file => {
        Resizer.imageFileResizer(
          file,
          width,
          height,
          format,
          quality,
          rotation,
          (uri) => {
            resolve(uri);
          },
          type
        );
    });
  });

const RecipeDetails = (props) => {

  const { id } = props.match.params;
  const { query } = useLocation();

  let recipePage = 1;
  if (props.history.location.state?.page) {
    recipePage = props.history.location.state?.page;
  }
  else {
    recipePage = query ? query.recipePage : 1;
  }

  const recipeInfo = useSelector((state) => state.api.recipeInfo);
  const languages = useSelector((state) => state.api.languages);
  const cuisines = useSelector((state) => state.api.cuisines);
  const vegetarianTypes = useSelector((state) => state.api.vegetarianTypes);
  const difficultyLevels = useSelector((state) => state.api.difficultyLevels);
  const mealLevels = useSelector((state) => state.api.mealLevels);
  const rddInfo = useSelector((state) => state.api.rddInfo);
  const { userId, profile } = useSelector((state) => state.session);

  const dispatch = useDispatch();
  const history = useHistory();

  const defaultLangId = 1;

  const [page, setPage] = useState(1);
  const [ingredientInfo, setIngredientInfo] = useState(null);
  const [headerOpen, setHeaderOpen] = useState(false);
  const [ingredientCreateOpen, setIngredientCreateOpen] = useState(false);
  const [ingredientEditOpen, setIngredientEditOpen] = useState(false);
  const [stepCreateOpen, setStepCreateOpen] = useState(false);
  const [stepEditOpen, setStepEditOpen] = useState(false);
  const [ingredientDeleteOpen, setIngredientDeleteOpen] = useState(false);
  const [stepDeleteOpen, setStepDeleteOpen] = useState(false);
  const [discardOpen, setDiscardOpen] = useState(false);
  const [recipe, setRecipe] = useState(null);
  const [lastPosition, setLastPosition] = useState(1);
  const [language, setLanguage] = useState({
    title: 0,
    step: 0,
    descriptionBase: 0,
  });
  const [filter, setFilter] = useState('');
  const [changedSector, setChangedSector] = useState({});
  const [updatedRecipe, setUpdatedRecipe] = useState(null);
  const [editRecipe, setEditRecipe] = useState(null);
  const [currentIngredientId, setCurrentIngredientId] = useState(null);
  const [currentStepId, setCurrentStepId] = useState(null);
  const [addedIngredient, setAddedIngredient] = useState({});
  const [addedStep, setAddedStep] = useState({});

  useEffect(() => {
    
    if (id) {
      let loadRecipe = recipeInfo == undefined;
      if (!loadRecipe) {
        let recipe = recipeInfo.meals.find(recipe => recipe.id == id);
        if (recipe === undefined)
          loadRecipe = true;
        else {
          loadRecipe = !recipe.ingredients?.length || !recipe.instructions?.length;
        }
      }
      if (loadRecipe) {
        console.log("Need to reload recipe info");
        ApiService.getRecipe(id)
          .then(data => {
            // if (data && data.message === "Unauthenticated.") {
            //   history.push('/admin');

            //   return;
            // }
            console.log('recipe: ', data);

            setRecipe(data);
            setLastPosition(Math.max(...data.ingredients.map(i => i.position)) + 1);
            setUpdatedRecipe(data);
            setEditRecipe(data);
          })
          .catch((err) => {
            console.log('recipe error:', err);
            if (err?.response?.status === 401) {
              showToastr('warning', 'Authorization Error', err.response.data.message);
              dispatch(sessionLogout());

              history.push('/');
              return;
            }
          });
      } else {
        const recipe = recipeInfo.meals.find(recipe => recipe.id == id);

        setRecipe(recipe);
        setLastPosition(Math.max(...recipe.ingredients.map(i => i.position)) + 1);
        setUpdatedRecipe(recipe);
        setEditRecipe(recipe);
      }
    } else {
      if (!cuisines || !vegetarianTypes || !difficultyLevels)
        return;

      setRecipe({
        cuisineId: Object.keys(cuisines)[0],
        vegetarianTypeId: Object.keys(vegetarianTypes)[0],
        difficultyLevelId: Object.keys(difficultyLevels)[0],
        qualities: {
          ironAbsortion: 1,
          proteinComplementarity: 1,
          sulforaphane: 1,
          sustainability: 1,
        }
      });
      setUpdatedRecipe({
        cuisineId: Object.keys(cuisines)[0],
        vegetarianTypeId: Object.keys(vegetarianTypes)[0],
        difficultyLevelId: Object.keys(difficultyLevels)[0],
        qualities: {
          ironAbsortion: 1,
          proteinComplementarity: 1,
          sulforaphane: 1,
          sustainability: 1,
        }
      });
      setEditRecipe({
        cuisineId: Object.keys(cuisines)[0],
        vegetarianTypeId: Object.keys(vegetarianTypes)[0],
        difficultyLevelId: Object.keys(difficultyLevels)[0],
        qualities: {
          ironAbsortion: 1,
          proteinComplementarity: 1,
          sulforaphane: 1,
          sustainability: 1,
        }
      });
    }

    if (!ingredientInfo) {
      handlePageChange(1);
    }

  }, [recipeInfo, cuisines, vegetarianTypes, difficultyLevels]);

  const count = 10;

  const handlePageChange = (val) => {
    console.log(val);
    setPage(val);

    setIngredientInfo(null);

    ApiService.getIngredients(val, count, 'code', 'asc', filter)
      .then(data => {
        // if (data && data.message === "Unauthenticated.") {
        //   history.push('/admin');

        //   return;
        // }
        console.log('ingredients: ', data);
        setIngredientInfo(data);
      })
      .catch((err) => {
        console.log('ingredients error: ', err);
        if (err.response.status === 401) {
          showToastr('warning', 'Authorization Error', err.response.data.message);
          dispatch(sessionLogout());

          history.push('/');
          return;
        }
      });
  }

  const handleDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const reorder = (list, startIndex, endIndex) => {
      const result = Array.from(list);
      const [removed] = result.splice(startIndex, 1);
      result.splice(endIndex, 0, removed);
    
      return result;
    };

    const ingredients = reorder(
      updatedRecipe.ingredients?.sort((a, b) => a.position - b.position),
      result.source.index,
      result.destination.index
    ).map((ingredient, index) => {return {...ingredient, position: index}});

    setUpdatedRecipe({...updatedRecipe, ingredients: ingredients});
    setChangedSector({ ...changedSector, ingredients: true });
  }

  const handleFilter = (e) => {
    handlePageChange(1);

    e.preventDefault();
  }

  const handleEditHeader = () => {
    setLanguage({ ...language, title: languageOptions[0].value });

    setHeaderOpen(true);
  }

  const handleChangeHeader = (type, val) => {
    let tmpEditRecipe = JSON.parse(JSON.stringify(editRecipe));

    if (type === 'title') {
      if (!tmpEditRecipe.title)
        tmpEditRecipe.title = {};

      tmpEditRecipe.title[language.title] = val;
    } else if (type === 'ironAbsortion' || type === 'proteinComplementarity' || type === 'sulforaphane' || type === 'sustainability') {
      if (!tmpEditRecipe.qualities)
        tmpEditRecipe.qualities = {};

      tmpEditRecipe.qualities[type] = val ? parseInt(val) : '';
    } else {
      tmpEditRecipe[type] = val;
    }

    setEditRecipe(tmpEditRecipe);
  }

  const handleSaveHeader = () => {
    setUpdatedRecipe(editRecipe);

    setChangedSector({ ...changedSector, header: true });

    setHeaderOpen(false);
  }

  const handleCancelHeader = () => {
    setEditRecipe(updatedRecipe);

    setHeaderOpen(false);
  }

  const handleAddIngredient = () => {
    setCurrentIngredientId(null);
    setLanguage({ ...language, descriptionBase: languageOptions[0].value });

    setIngredientCreateOpen(true);
  }

  const handleEditIngredient = (id) => {
    setCurrentIngredientId(id);
    setLanguage({ ...language, descriptionBase: languageOptions[0].value });

    console.log("Ingredient modal", id);
    if (id !== undefined) {
      let selectedIngredient = updatedRecipe.ingredients ? updatedRecipe.ingredients.find(
        (value) => value.code == id
      ) : undefined;
      console.log("selectedIngredient:", selectedIngredient);
      if (selectedIngredient !== undefined) {
        setAddedIngredient(selectedIngredient);
      }
    }

    setIngredientEditOpen(true);
  }

  const handleRemoveIngredient = (id) => {
    setCurrentIngredientId(id);

    setIngredientDeleteOpen(true);
  }

  const handleSaveIngredient = () => {
    if (currentIngredientId) {
      setUpdatedRecipe(editRecipe);

      setIngredientEditOpen(false);
    } else {
      let tmpUpdatedRecipe = JSON.parse(JSON.stringify(updatedRecipe));
      if (!tmpUpdatedRecipe.ingredients)
        tmpUpdatedRecipe.ingredients = [];

      setLastPosition(lastPosition+1);
      tmpUpdatedRecipe.ingredients.push({...addedIngredient, position: lastPosition});

      setUpdatedRecipe(tmpUpdatedRecipe);
      setEditRecipe(tmpUpdatedRecipe);

      setAddedIngredient({});
      setIngredientCreateOpen(false);
    }

    setChangedSector({ ...changedSector, ingredients: true });
  }

  const handleConfirmRemoveIngredient = () => {
    let tmpUpdatedRecipe = JSON.parse(JSON.stringify(updatedRecipe));
    const index = tmpUpdatedRecipe.ingredients.indexOf(tmpUpdatedRecipe.ingredients.find(ingredient => ingredient.code === currentIngredientId));
    tmpUpdatedRecipe.ingredients.splice(index, 1);

    setUpdatedRecipe(tmpUpdatedRecipe);
    setEditRecipe(tmpUpdatedRecipe);

    setChangedSector({ ...changedSector, ingredients: true });

    setIngredientDeleteOpen(false);
  }

  const handleChangeIngredient = () => {
    let tmpEditRecipe = JSON.parse(JSON.stringify(editRecipe));
    // if (type === "descriptionBase") {
    //   tmpEditRecipe.ingredients.find(ingredient => ingredient.code === currentIngredientId)[type][language.descriptionBase] = val;
    // } else {
    //   tmpEditRecipe.ingredients.find(ingredient => ingredient.code === currentIngredientId)[type] = val;
    // }

    let ingredientIndex = tmpEditRecipe.ingredients.findIndex(ingredient => ingredient.code === currentIngredientId);
    tmpEditRecipe.ingredients[ingredientIndex] = {...addedIngredient, position: tmpEditRecipe.ingredients[ingredientIndex].position};

    console.log("Found ingredient at index", ingredientIndex);
    console.log(tmpEditRecipe.ingredients[ingredientIndex]);

    setAddedIngredient({});
    setEditRecipe(tmpEditRecipe);
    setUpdatedRecipe(tmpEditRecipe);
    setChangedSector({ ...changedSector, ingredients: true });
    setIngredientEditOpen(false);
  }

  const handleClickIngredient = (id) => {
    // let tmpAddedIngredient = JSON.parse(JSON.stringify(addedIngredient));
    // tmpAddedIngredient.code = id;
    // tmpAddedIngredient.descriptionBase = {};
    // tmpAddedIngredient.description = ingredientInfo.ingredients.find(ingredient => ingredient.code === id)?.description;
    // tmpAddedIngredient.categoryDescription = ingredientInfo.ingredients.find(ingredient => ingredient.code === id)?.categoryDescription;
    // tmpAddedIngredient.image = ingredientInfo.ingredients.find(ingredient => ingredient.code === id)?.image;
    let tmpAddedIngredient = ingredientInfo.ingredients.find(ingredient => ingredient.code === id);
    tmpAddedIngredient.descriptionBase = {};

    setAddedIngredient(tmpAddedIngredient);
  }

  const handleAddStep = () => {
    setLanguage({ ...language, step: languageOptions[0].value });

    setCurrentStepId(null);

    setStepCreateOpen(true);
  }

  const handleEditStep = (step) => {
    setLanguage({ ...language, step: languageOptions[0].value });

    setCurrentStepId(step);

    setStepEditOpen(true);
  }

  const handleChangeStep = (val) => {
    let tmpEditRecipe = JSON.parse(JSON.stringify(editRecipe));
    tmpEditRecipe.instructions.find(step => step.step === currentStepId).text[language.step] = val;

    setEditRecipe(tmpEditRecipe);
  }

  const handleRemoveStep = (id) => {
    setCurrentStepId(id);

    setStepDeleteOpen(true);
  }

  const handleSaveStep = () => {
    if (currentStepId) {
      setUpdatedRecipe(editRecipe);

      setStepEditOpen(false);
    } else {
      let tmpUpdatedRecipe = JSON.parse(JSON.stringify(updatedRecipe));
      if (!tmpUpdatedRecipe.instructions)
        tmpUpdatedRecipe.instructions = [];

      tmpUpdatedRecipe.instructions.push(addedStep);

      setUpdatedRecipe(tmpUpdatedRecipe);
      setEditRecipe(tmpUpdatedRecipe);

      setAddedStep({});
      setStepCreateOpen(false);
    }

    setChangedSector({ ...changedSector, steps: true });
  }

  const handleConfirmRemoveStep = () => {
    let tmpUpdatedRecipe = JSON.parse(JSON.stringify(updatedRecipe));
    const index = tmpUpdatedRecipe.instructions.indexOf(tmpUpdatedRecipe.instructions.find(step => step.step === currentStepId));
    tmpUpdatedRecipe.instructions.splice(index, 1);
    for (let i = 0; i < tmpUpdatedRecipe.instructions.length; i++) {
      if (tmpUpdatedRecipe.instructions[i].step > currentStepId) {
        tmpUpdatedRecipe.instructions[i].step--;
      }
    }

    setUpdatedRecipe(tmpUpdatedRecipe);
    setEditRecipe(tmpUpdatedRecipe);

    setStepDeleteOpen(false);

    setChangedSector({ ...changedSector, steps: true });
  }

  const handlePublish = async(status) => {
    const request = {
      statusId: status,
      qualities: {
        type: 1,
        ...updatedRecipe.qualities,
      },
      countryId: updatedRecipe.countryId,
      cuisineId: updatedRecipe.cuisineId,
      difficultyLevelId: updatedRecipe.difficultyLevelId,
      vegetarianTypeId: updatedRecipe.vegetarianTypeId,
    };
    ApiService.updateRecipe(updatedRecipe.id, request)
      .then(data => {
        if (status == 2) {
          console.log("published:", data);
          showToastr("info", "Success", `Successfully published recipe "${data.title[1]}"`);
        }
        else {
          console.log("unpublished:", data);
          showToastr("info", "Success", `Successfully unpublished recipe "${data.title[1]}"`);
        }
        setRecipe(data);
        setUpdatedRecipe(data);
      })
      .catch((err) => {
        console.log('publish recipe error: ', err);
        if (err?.response?.status === 401) {
          showToastr('warning', 'Authorization Error', err.response.data.message);
          dispatch(sessionLogout());

          history.push('/');
          return;
        }
        else {
          showToastr("error", "Error", "An unknown error occurred");
        }
      });
  }

  const handleSave = async () => {
    let request = {};
    if (changedSector.header || id === undefined) {
      request.title = updatedRecipe.title;
      request.preparationTime = updatedRecipe.preparationTime;
      request.cookingTime = updatedRecipe.cookingTime;
      request.serves = updatedRecipe.serves;
      request.author = updatedRecipe.author;
      if (updatedRecipe.submittedBy === "") {
        request.submittedBy = " ";
      }
      else if (updatedRecipe.submittedBy === null) {
        request.submittedBy = profile.name;
      }
      else {
        request.submittedBy = updatedRecipe.submittedBy;
      }
    }
    if (changedSector.ingredients || id === undefined) {
      let ingredients = [];
      if (updatedRecipe.ingredients)
        updatedRecipe.ingredients.map(item => {
          ingredients.push({
            code: item.code,
            id: item.id,
            quantity: item.quantity,
            descriptionBase: item.descriptionBase,
            position: item.position
          });
        })
      ingredients.sort((a, b) => a.position - b.position);
      ingredients = ingredients.map((ing, index) => {return {...ing, position: index}});
      request.ingredients = ingredients.length !== 0 ? ingredients : (changedSector.ingredients ? [] : undefined);
    }
    if (changedSector.steps || id === undefined) {
      request.instructions = updatedRecipe.instructions?.length ? updatedRecipe.instructions : (changedSector.steps ? [] : undefined);
    }
    if (changedSector.image) {
      const baseFileName = Date.now();
      const fileExtension = updatedRecipe.image.name.split('.').pop();
      const normalSizeFile = await resizeFile(updatedRecipe.image, IMG_SIZE.normal[0], IMG_SIZE.normal[1], "JPEG", 85, 0, "blob");
      const smallSizeFile = await resizeFile(updatedRecipe.image, IMG_SIZE.small[0], IMG_SIZE.small[1], "JPEG", 85, 0, "blob");
      
      const location = await ReactS3Client.uploadFile(normalSizeFile, baseFileName + "." + fileExtension);
      if (location == undefined) {
        showToastr('error', 'Error uploading image', `Error while trying to upload the image`);
        return;
      }
      request.image = location;
      await ReactS3Client.uploadFile(smallSizeFile, baseFileName + "-small." + fileExtension);
      console.log('updatedRecipe', updatedRecipe);
    }

    console.log(request);
    console.log("Saving recipe with ID", updatedRecipe.id);

    if (id) {
      request.qualities = {
        type: 1,
        ...updatedRecipe.qualities,
      };
      request.countryId = updatedRecipe.countryId;
      request.cuisineId = updatedRecipe.cuisineId;
      request.difficultyLevelId = updatedRecipe.difficultyLevelId;
      request.vegetarianTypeId = updatedRecipe.vegetarianTypeId;

      const missing = ValidateRecipeFields(request, true);
      if (missing.length != 0) {
        showToastr('error', 'Missing fields', `Please input fields: ${missing}`);

        return;
      }

      ApiService.updateRecipe(updatedRecipe.id, request)
        .then(data => {
          // if (data && data.message === "Unauthenticated.") {
          //   history.push('/admin');

          //   return;
          // }
          console.log('updated Recipe: ', data);
          showToastr("info", "Success", `Successfully updated recipe "${data.title[1]}"`);
          setRecipe(data);
        })
        .catch((err) => {
          console.log('updated Recipe error: ', err);
          if (err?.response?.status === 401) {
            showToastr('warning', 'Authorization Error', err.response.data.message);
            dispatch(sessionLogout());

            history.push('/');
            return;
          }
          else {
            showToastr("error", "Error", "An unknown error occurred");
          }
        });
    } else {
      request.qualities = {
        type: 1,
        ...updatedRecipe.qualities,
      };
      request.countryId = 24;
      request.cuisineId = updatedRecipe.cuisineId;
      request.difficultyLevelId = updatedRecipe.difficultyLevelId;
      request.vegetarianTypeId = updatedRecipe.vegetarianTypeId;

      const missing = ValidateRecipeFields(request, false);
      console.log(missing);
      if (missing.length != 0) {
        showToastr('error', 'Missing fields', `Please input fields: ${missing.join(", ")}`);

        return;
      }

      ApiService.createRecipe(request)
        .then(data => {
          // if (data && data.message === "Unauthenticated.") {
          //   history.push('/admin');

          //   return;
          // }
          if (data.hasOwnProperty("errorMessage")) {
            showToastr("error", "Unknown Error", "An unkown error has occurred while creating the recipe");
          }
          else {
            console.log('created Recipe: ', data);
            showToastr("info", "Success", `Successfully created recipe "${request.title[1]}"`);
            setRecipe(data);
          }
        })
        .catch((err) => {
          console.log('created Recipe error: ', err);
          if (err.response.status === 401) {
            showToastr('warning', 'Authorization Error', err.response.data.message);
            dispatch(sessionLogout());

            history.push('/');
            return;
          }
        });
    }

    setChangedSector({});
  }

  const handleSaveNew = async() => {
    let request = {};
    request.title = updatedRecipe.title;
    for (let key in request.title) {
      if (request.title.hasOwnProperty(key)) {
        request.title[key] += " (copy)";
      }
    }

    request.preparationTime = updatedRecipe.preparationTime;
    request.cookingTime = updatedRecipe.cookingTime;
    request.serves = updatedRecipe.serves;
    request.author = updatedRecipe.author;

    let ingredients = [];
    updatedRecipe.ingredients.map(item => {
      ingredients.push({
        code: item.code,
        quantity: item.quantity,
        descriptionBase: item.descriptionBase,
      });
    })
    request.ingredients = ingredients.length !== 0 ? ingredients : undefined;

    request.instructions = updatedRecipe.instructions.length !== 0 ? updatedRecipe.instructions : undefined;

    if (updatedRecipe.image) {
      if (typeof updatedRecipe.image === "string") {
        request.image = updatedRecipe.image;
      }
      else {
        const baseFileName = Date.now();
        const fileExtension = updatedRecipe.image.name.split('.').pop();
        
        const normalSizeFile = await resizeFile(updatedRecipe.image, IMG_SIZE.normal[0], IMG_SIZE.normal[1], "JPEG", 85, 0, "blob");
        const smallSizeFile = await resizeFile(updatedRecipe.image, IMG_SIZE.small[0], IMG_SIZE.small[1], "JPEG", 85, 0, "blob");
        
        const location = await ReactS3Client.uploadFile(normalSizeFile, baseFileName + "." + fileExtension);
        if (location == undefined) {
          showToastr('error', 'Error uploading image', `Error while trying to upload the image`);
          return;
        }
        request.image = location;
        await ReactS3Client.uploadFile(smallSizeFile, baseFileName + "-small." + fileExtension);
        console.log('updatedRecipe', updatedRecipe);
      }
    }

    console.log(request);

    request.qualities = {
      type: 1,
      ...updatedRecipe.qualities,
    };
    request.countryId = 24;
    request.cuisineId = updatedRecipe.cuisineId;
    request.difficultyLevelId = updatedRecipe.difficultyLevelId;
    request.vegetarianTypeId = updatedRecipe.vegetarianTypeId;

    const missing = ValidateRecipeFields(request, false);
    console.log(missing);
    if (missing.length != 0) {
      showToastr('error', 'Missing fields', `Please input fields: ${missing.join(", ")}`);

      return;
    }

    ApiService.createRecipe(request)
      .then(data => {
        // if (data && data.message === "Unauthenticated.") {
        //   history.push('/admin');

        //   return;
        // }
        if (data.hasOwnProperty("errorMessage")) {
          showToastr("error", "Unknown Error", "An unkown error has occurred while creating the recipe");
        }
        else {
          console.log('created Recipe: ', data);
          history.push(`/admin/recipe/detail/${data.id}`);
          showToastr("info", "Success", `Successfully created recipe "${request.title[1]}"`);
          setRecipe(data);
          setUpdatedRecipe(data);
          setEditRecipe(data);
        }
      })
      .catch((err) => {
        console.log('created Recipe error: ', err);
        if (err.response.status === 401) {
          showToastr('warning', 'Authorization Error', err.response.data.message);
          dispatch(sessionLogout());

          history.push('/');
          return;
        }
      });
  }

  const handleDiscard = () => {
    setUpdatedRecipe(recipe);
    setEditRecipe(recipe);

    setChangedSector({});
    setDiscardOpen(false);
  }

  const handleChangeImage = (img) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const image = new Image();
      image.src = e.target.result;

      image.onload = function() {
        const normal = 4 / 3;
        const ratio = this.width / this.height;
        const error = Math.abs(ratio - normal) / normal;
        console.log("error:", error);
        if (error > 0.15) {
          const percentage = Math.round(error * 100);
          showToastr("warning", `Image maladjusted by ${percentage}%`);
        }
        console.log("image ratio:", ratio);
      }
    } 
    reader.readAsDataURL(img);

    setUpdatedRecipe({ ...updatedRecipe, image: img });

    setChangedSector({ ...changedSector, image: true });
  }

  const generateInfoText = (recipe) => {
    return `Created by ${recipe.author ?? '?'} | Submitted by ${recipe.submittedBy ?? '?'} ${recipe.statusId == 1 ? '(Waiting for approval)' : ''}`;
  }


  if (!recipe || !languages || !updatedRecipe || !editRecipe || !cuisines || !vegetarianTypes || !difficultyLevels || !mealLevels) {
    return (
      <Container fluid className="vh-50 d-flex justify-content-center align-items-center">
        <Spinner color="primary" size="lg" className="mr-2" />
      </Container>
    );
  }

  let languageOptions = [];
  for (const id in languages) {
    languageOptions.push({
      value: parseInt(id),
      label: languages[id].code,
    });
  }

  let cuisineOptions = [];
  for (const id in cuisines) {
    cuisineOptions.push({
      value: parseInt(id),
      label: cuisines[id].name,
    });
  }

  let vegetarianOptions = [];
  for (const id in vegetarianTypes) {
    vegetarianOptions.push({
      value: parseInt(id),
      label: vegetarianTypes[id].name,
    });
  }

  let difficultyOptions = [];
  for (const id in difficultyLevels) {
    difficultyOptions.push({
      value: parseInt(id),
      label: difficultyLevels[id].name,
    });
  }

  let mealLevelOptions = [];
  for (const id in mealLevels) {
    mealLevelOptions.push({
      value: parseInt(id),
      label: mealLevels[id].name,
    });
  }

  const addIngredientModal = (title, open, setOpen, handleSave) => {
    return (<Modal
      isOpen={open}
      centered
      size="lg"
    >
      <ModalHeader toggle={() => setOpen(false)}>
        {
          title
        }
      </ModalHeader>
      <ModalBody className="text-center m-3">
        <Row>
          <Col>
            <div className="d-flex ml-auto" style={{ height: 50, width: '100%', marginBottom: 15, }}>
              <Form className="d-flex" style={{ width: '100%', height: '100%', }} onSubmit={e => handleFilter(e)}>
                <div style={{ display: 'flex', backgroundColor: 'white', border: '1px solid #e5e5e5', alignItems: 'center', marginRight: 30, width: '100%', }}>
                  <Input
                    type="text"
                    placeholder="Search"
                    aria-label="Search"
                    className="form-control-no-border mr-sm-2"
                    value={filter}
                    onChange={val => setFilter(val.target.value)}
                  />
                  <FontAwesomeIcon icon={faSearch} style={{ color: '#6B6C72' }} className="mr-3" />
                </div>
                <Button color="warning" style={{ width: 146, minWidth: 100, height: '100%', }}>
                  <img src={FilterIcon} className="mr-2" /> Filter
                </Button>
              </Form>
            </div>
            <div style={{ maxHeight: 600, overflowY: 'scroll', paddingRight: '5%', }}>
              {
                ingredientInfo?.ingredients ? (
                  <div style={{ height: 405, }}>
                    {
                      ingredientInfo.ingredients.map((ingredient, index) => (
                        <IngredientElementItem
                          key={index}
                          ingredient={ingredient}
                          onClick={() => handleClickIngredient(ingredient.code)}
                          active={ingredient.code === addedIngredient.code}
                          disabled={updatedRecipe.ingredients?.find(item => item.code === ingredient.code)}
                        />
                      ))
                    }
                  </div>
                )
                  : (
                    <Container fluid className="d-flex justify-content-center align-items-center" style={{ height: 405, }}>
                      <Spinner color="primary" size="lg" className="mr-2" />
                    </Container>
                  )
              }
            </div>
            <Pagination
              value={page}
              maxPage={ingredientInfo ? ingredientInfo.pages : 1}
              onChange={(val) => handlePageChange(val)}
            />
          </Col>
          <Col>
            <div style={{ height: '70%', }}>
              <img src={addedIngredient.image ?? DefaultImage} style={{ borderRadius: 6, maxHeight: 200, }}></img>
              <h3 className="tab-title mt-4 mb-3">
                {addedIngredient.friendlyDescription?.[defaultLangId] ?? ''}
              </h3>
            </div>
            <div style={{ maxWidth: 310, margin: 'auto', }}>
              <div className="d-flex justify-content-between">
                <span>Quantity</span>
                <Input
                  type="text"
                  placeholder="Quantity"
                  aria-label="Quantity"
                  style={{ width: 70, }}
                  value={addedIngredient.quantity ?? ''}
                  onChange={val => setAddedIngredient({ ...addedIngredient, quantity: val.target.value })}
                />
              </div>
              <div className="d-flex justify-content-between mt-2">
                <span className="text-left">Additional Information</span>
                <div>
                  <Select
                    className="react-select-container"
                    classNamePrefix="react-select"
                    styles={customStyles1}
                    options={languageOptions}
                    isSearchable={false}
                    defaultValue={languageOptions[0]}
                    onChange={(item) => setLanguage({ ...language, descriptionBase: item.value })}
                  />
                  <Input
                    type="textarea"
                    name="DescriptionText"
                    placeholder="DescriptionText"
                    style={{ width: 210, height: 130, }}
                    value={addedIngredient.descriptionBase?.[language.descriptionBase] ?? ''}
                    onChange={val => setAddedIngredient({ ...addedIngredient, descriptionBase: { ...addedIngredient.descriptionBase, [language.descriptionBase]: val.target.value } })}
                  />
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={() => setOpen(false)}>
          Cancel
        </Button>{" "}
        <Button color="primary" onClick={handleSave} disabled={!addedIngredient.code || !addedIngredient.quantity}>
          OK
        </Button>
      </ModalFooter>
    </Modal>);
  }

  console.log('updatedRecipe: ', updatedRecipe);
  console.log('addedIngredient: ', addedIngredient);
  console.log("rddInfo:", rddInfo);

  return (
    <React.Fragment>
      <BackButton to={`/admin/recipes/page=${recipePage}`}/>
      <div style={{ padding: '30px 30px', backgroundColor: 'white' }}>
        <div className="d-flex align-items-start mb-5">
          <div>
            <ImageUploader img={updatedRecipe.image ?? FoodImage1} onChange={(img) => handleChangeImage(img)} />
            <Link to={{ pathname: `/admin/recipe/detail/${updatedRecipe.id}/votes`, from: 'detail' }} className="d-flex justify-content-between" style={{ display: 'block', marginTop: 10, }}>
              <Rating
                emptySymbol={<FiStar style={{ color: '#FC9C52', width: 22 }} />}
                fullSymbol={<FaStar style={{ color: '#FC9C52', width: 22 }} />}
                stop={5}
                initialRating={updatedRecipe.votes?.voteStar ?? 0}
                readonly
              />
              {`Votes: ${updatedRecipe.votes?.voteCount ?? '0'}`}
            </Link>
          </div>
          <div className="ml-5" style={{ flex: 1, }}>
            <p style={{ fontSize: 24, fontWeight: 600, color: 'black', marginBottom: 10, }}>
              {updatedRecipe.title?.[defaultLangId]}
            </p>
            <p style={{ color: '#3490DD', }}>
              {generateInfoText(updatedRecipe)}
            </p>
            <span className="d-flex align-items-center mb-2">
              <img
                src={PrepImage}
                className="mr-2"
              />
              {`${updatedRecipe.preparationTime ?? ' '} mins to prep`}
              <img
                src={CookImage}
                className="ml-4 mr-2"
              />
              {`${updatedRecipe.cookingTime ?? ' '} mins to cook`}
            </span>
            <div className="d-flex justify-content-between">
              <div>
                <p className="mb-1">
                  {`Serves: ${updatedRecipe.serves ?? '0'}`}
                </p>
                <p className="mb-1">
                  {`Cuisine:  ${cuisines[updatedRecipe.cuisineId]?.name ?? ''}`}
                </p>
                <p className="mb-1">
                  {`Vegetarian Type:  ${vegetarianTypes[updatedRecipe.vegetarianTypeId]?.name ?? ''}`}
                </p>
                <p className="mb-1">
                  {`Difficulty Level:  ${difficultyLevels[updatedRecipe.difficultyLevelId]?.name ?? ''}`}
                </p>
              </div>
              <div className="ml-5">
                {
                  updatedRecipe.qualities ? (
                    <div>
                      <p className="mb-1">
                        {`Protein complementary: ${mealLevels[updatedRecipe.qualities.proteinComplementarity]?.name}`}
                      </p>
                      <p className="mb-1">
                        {`Iron absorption: ${mealLevels[updatedRecipe.qualities.ironAbsortion]?.name}`}
                      </p>
                      <p className="mb-1">
                        {`Sulforaphane: ${mealLevels[updatedRecipe.qualities.sulforaphane]?.name}`}
                      </p>
                      <p className="mb-1">
                        {`Sustainability: ${mealLevels[updatedRecipe.qualities.sustainability]?.name}`}
                      </p>
                    </div>
                  ) : null
                }
              </div>
              <div className="ml-5">
                <Link to={{ pathname: `/admin/recipe/evaluation/${updatedRecipe.id}`, from: 'detail' }}>
                {
                  updatedRecipe.evaluations?.map((evaluation, index) =>
                    (
                        <p className="mb-1" key={index}>
                          { rddInfo?.rdds ? `${rddInfo.rdds.find(r => r.code == evaluation.code).title}: ${evaluation.value}` : null }
                        </p>
                    )
                  )
                }
                </Link>
              </div>
            </div>
          </div>
          <div>
            <FontAwesomeIcon className="table-action-button" icon={faPencilAlt} style={{ color: '#FC9C52', float: 'right', }} onClick={handleEditHeader} />
          </div>
        </div>
        <div className="d-flex align-items-start justify-content-between">
          <Col sm="8" style={{ width: 'calc(100% - 200px)', paddingRight: 50, borderRight: '1px solid #DEE2ED', }}>
            <div className="d-flex justify-content-between align-items-center">
              <p style={{ fontSize: 18, fontWeight: 'bold', color: 'black', margin: 0, }}>
                Ingredients
              </p>
            </div>
            <div style={{ marginBottom: 40, }}>
              {
                /*updatedRecipe.ingredients?.sort((a, b) => a.position - b.position)?.map((ingredient, index) => (
                  <IngredientElementItem
                    key={index}
                    ingredient={ingredient}
                    editable
                    onEdit={() => handleEditIngredient(ingredient.code)}
                    onDelete={() => handleRemoveIngredient(ingredient.code)}
                  />
                  ))
                  */
                <DragDropContext onDragEnd={handleDragEnd}>
                  <Droppable droppableId="droppable">
                    {(provided, snapshot) => 
                      <div {...provided.droppableProps} ref={provided.innerRef}>
                      {
                      updatedRecipe.ingredients?.sort((a, b) => a.position - b.position)?.map((ingredient, index) => (
                      <Draggable key={"ingredient-"+ingredient.code} draggableId={"ingredient-"+ingredient.code} index={index}>
                        {(provided, snapshot) => 
                        <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                          <IngredientElementItem
                            key={ingredient.code}
                            ingredient={ingredient}
                            editable
                            onEdit={() => handleEditIngredient(ingredient.code)}
                            onDelete={() => handleRemoveIngredient(ingredient.code)}
                          />
                        </div>
                        }
                      </Draggable>
                      ))
                      }
                      {provided.placeholder}
                      </div>
                    }
                  </Droppable>
                </DragDropContext>
              }
              <AddItem
                text="Add an Ingredient"
                onClick={handleAddIngredient}
              />
            </div>
            <div>
              {
                updatedRecipe.instructions?.map((step, index) => (
                  <InstructionStepItem
                    key={index}
                    step={step.step}
                    instruction={step.text?.[defaultLangId]}
                    color={colorGroup[index % colorGroup.length]}
                    editable
                    onEdit={() => handleEditStep(step.step)}
                    onDelete={() => handleRemoveStep(step.step)}
                  />
                ))
              }
              <AddItem
                // hidden={addIngredient ? true : false}
                text="Add a Step"
                onClick={handleAddStep}
              />
            </div>
          </Col>
          <Col sm="4">
            <PieChart
              data={updatedRecipe.quantityPerPerson}
            />
          </Col>
        </div>
        {
          <div className="d-flex justify-content-center mt-4">
          {
          Object.keys(changedSector).length !== 0 ? (
              <div>
              <Button className="mr-3" color="secondary" onClick={() => setDiscardOpen(true)}>
                Cancel
              </Button>
              <Button color="primary" onClick={handleSave}>
                Save
              </Button>
              </div>
          ) : (
            updatedRecipe.statusId == 1 ? (
            <Button color="primary" onClick={() => handlePublish(2)}>
                Publish
            </Button>
          ) : 
            (
            <Button color="primary" onClick={() => handlePublish(1)}>
                Unpublish
            </Button>
            )
          )
          }
          <Button className="ml-3" color="primary" onClick={handleSaveNew}>
          Save new
          </Button>
          </div>
        }
      </div>

      <Modal
        isOpen={headerOpen}
        centered
      >
        <ModalHeader toggle={handleCancelHeader}>
          Edit Recipe
        </ModalHeader>
        <ModalBody className="text-center m-3" style={{ padding: '0 10%', }}>
          <div className="d-flex justify-content-between align-items-center mb-3">
            <span style={{ marginRight: 'auto', }}>
              Title
            </span>
            <Select
              className="react-select-container"
              classNamePrefix="react-select"
              styles={customStyles}
              options={languageOptions}
              isSearchable={false}
              defaultValue={languageOptions[0]}
              onChange={(item) => setLanguage({ ...language, title: item.value })}
            />
            <Input
              type="text"
              aria-label="Value"
              style={{ width: 250, }}
              value={editRecipe.title?.[language.title] ?? ''}
              onChange={(val) => handleChangeHeader('title', val.target.value)}
            />
          </div>
          <div className="d-flex justify-content-between align-items-center mb-3">
            <div className="d-flex justify-content-around align-items-center">
              <span>
                Preparation Time
              </span>
              <Input
                type="text"
                aria-label="Value"
                className="ml-2"
                style={{ width: 50, }}
                value={editRecipe.preparationTime ?? ''}
                onChange={(val) => handleChangeHeader('preparationTime', val.target.value)}
              />
            </div>
            <div className="d-flex justify-content-around align-items-center">
              <span>
                Cooking Time
              </span>
              <Input
                type="text"
                aria-label="Value"
                className="ml-2"
                style={{ width: 50, }}
                value={editRecipe.cookingTime ?? ''}
                onChange={(val) => handleChangeHeader('cookingTime', val.target.value)}
              />
            </div>
          </div>
          <div className="d-flex justify-content-between align-items-center mb-3">
            <div className="d-flex justify-content-around align-items-center">
              <span>
                Created By
              </span>
              <Input
                type="text"
                aria-label="Value"
                className="ml-2"
                style={{ width: 150, }}
                value={editRecipe.author ?? '' ?? ''}
                onChange={(val) => handleChangeHeader('author', val.target.value)}
              />
            </div>
            <div className="d-flex justify-content-around align-items-center">
              <span>
                Serves
              </span>
              <Input
                type="text"
                aria-label="Value"
                className="ml-2"
                style={{ width: 50, }}
                value={editRecipe.serves ?? ''}
                onChange={(val) => handleChangeHeader('serves', val.target.value)}
              />
            </div>
          </div>
          <div className="d-flex justify-content-between align-items-center mb-3">
            <div className="d-flex justify-content-around align-items-center">
              <span>
                Submitted By
              </span>
              <Input
                type="text"
                aria-label="Value"
                className="ml-2"
                style={{ width: 150, }}
                value={editRecipe.submittedBy ?? profile.name}
                onChange={(val) => handleChangeHeader('submittedBy', val.target.value)}
              />
            </div>
          </div>
          <div className="d-flex justify-content-between align-items-center mb-3">
            <span style={{ marginRight: 'auto', }}>
              Cuisine
            </span>
            <Select
              className="react-select-container"
              classNamePrefix="react-select"
              styles={customStyles1}
              options={cuisineOptions}
              isSearchable={true}
              defaultValue={cuisineOptions.find(cuisineOption => cuisineOption.value === editRecipe.cuisineId) ?? cuisineOptions[0]}
              onChange={(item) => handleChangeHeader('cuisineId', item.value)}
            />
          </div>
          <div className="d-flex justify-content-between align-items-center mb-3">
            <span style={{ marginRight: 'auto', }}>
              Vegetarian Type
            </span>
            <Select
              className="react-select-container"
              classNamePrefix="react-select"
              styles={customStyles1}
              options={vegetarianOptions}
              isSearchable={true}
              defaultValue={vegetarianOptions.find(vegetarianOption => vegetarianOption.value === editRecipe.vegetarianTypeId) ?? vegetarianOptions[0]}
              onChange={(item) => handleChangeHeader('vegetarianTypeId', item.value)}
            />
          </div>
          <div className="d-flex justify-content-between align-items-center mb-3">
            <span style={{ marginRight: 'auto', }}>
              Difficulty Level
            </span>
            <Select
              className="react-select-container"
              classNamePrefix="react-select"
              styles={customStyles1}
              options={difficultyOptions}
              isSearchable={true}
              defaultValue={difficultyOptions.find(difficultyOption => difficultyOption.value === editRecipe.difficultyLevelId) ?? difficultyOptions[0]}
              onChange={(item) => handleChangeHeader('difficultyLevelId', item.value)}
            />
          </div>
          <div className="d-flex justify-content-between align-items-center mb-3">
            <div className="d-flex justify-content-around align-items-center">
              <span className="mr-1">
                Protein complementarity
              </span>
              <Select
                className="react-select-container"
                classNamePrefix="react-select"
                styles={customStyles}
                options={mealLevelOptions}
                isSearchable={true}
                defaultValue={mealLevelOptions.find(mealLevel => mealLevel.value === editRecipe.qualities?.proteinComplementarity) ?? mealLevelOptions[0]}
                onChange={(item) => handleChangeHeader('proteinComplementarity', item.value)}
              />
            </div>
            <div className="d-flex justify-content-around align-items-center">
              <span className="mr-1">
                Iron absortion
              </span>
              <Select
                className="react-select-container"
                classNamePrefix="react-select"
                styles={customStyles}
                options={mealLevelOptions}
                isSearchable={true}
                defaultValue={mealLevelOptions.find(mealLevel => mealLevel.value === editRecipe.qualities?.ironAbsortion) ?? mealLevelOptions[0]}
                onChange={(item) => handleChangeHeader('ironAbsortion', item.value)}
              />
            </div>
          </div>
          <div className="d-flex justify-content-between align-items-center mb-3">
            <div className="d-flex justify-content-around align-items-center">
              <span className="mr-1">
                Sulforaphane
              </span>
              <Select
                className="react-select-container"
                classNamePrefix="react-select"
                styles={customStyles}
                options={mealLevelOptions}
                isSearchable={true}
                defaultValue={mealLevelOptions.find(mealLevel => mealLevel.value === editRecipe.qualities?.sulforaphane) ?? mealLevelOptions[0]}
                onChange={(item) => handleChangeHeader('sulforaphane', item.value)}
              />
            </div>
            <div className="d-flex justify-content-around align-items-center">
              <span className="mr-1">
                Sustainability
              </span>
              <Select
                className="react-select-container"
                classNamePrefix="react-select"
                styles={customStyles}
                options={mealLevelOptions}
                isSearchable={true}
                defaultValue={mealLevelOptions.find(mealLevel => mealLevel.value === editRecipe.qualities?.sustainability) ?? mealLevelOptions[0]}
                onChange={(item) => handleChangeHeader('sustainability', item.value)}
              />
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={handleCancelHeader}>
            Cancel
          </Button>{" "}
          <Button color="primary" onClick={handleSaveHeader}>
            OK
          </Button>
        </ModalFooter>
      </Modal>
      {addIngredientModal("Add Ingredient", ingredientCreateOpen, setIngredientCreateOpen, handleSaveIngredient)}
      {addIngredientModal("Edit Ingredient", ingredientEditOpen, setIngredientEditOpen, handleChangeIngredient)}
      {
      //! Old edit modal code
      /* <Modal
        isOpen={ingredientEditOpen}
        centered
        size="md"
      >
        <ModalHeader toggle={handleCancelIngredient}>
          Edit Ingredient
        </ModalHeader>
        <ModalBody className="text-center m-3">
          <div style={{ maxWidth: 400, margin: 'auto', }}>
            <img src={editRecipe.ingredients?.find(ingredient => ingredient.code === currentIngredientId)?.image ?? Image} style={{ borderRadius: 6, }}></img>
            <h3 className="tab-title mt-4 mb-3">
              {editRecipe.ingredients?.find(ingredient => ingredient.code === currentIngredientId)?.description?.[defaultLangId]}
            </h3>
            <div className="d-flex justify-content-between">
              <span>Quantity</span>
              <Input
                type="text"
                placeholder="Quantity"
                aria-label="Quantity"
                style={{ width: 70, }}
                value={editRecipe.ingredients?.find(ingredient => ingredient.code === currentIngredientId)?.quantity ?? ''}
                onChange={val => handleChangeIngredient('quantity', val.target.value)}
              />
            </div>
            <div className="d-flex justify-content-between mt-2">
              <span>Additional Information</span>
              <div>
                <Select
                  className="react-select-container"
                  classNamePrefix="react-select"
                  styles={customStyles1}
                  options={languageOptions}
                  isSearchable={false}
                  defaultValue={languageOptions[0]}
                  onChange={(item) => setLanguage({ ...language, descriptionBase: item.value })}
                />
                <Input
                  type="textarea"
                  name="DescriptionText"
                  placeholder="DescriptionText"
                  style={{ width: 210, }}
                  value={editRecipe.ingredients?.find(ingredient => ingredient.code === currentIngredientId)?.descriptionBase[language.descriptionBase] ?? ''}
                  onChange={val => handleChangeIngredient('descriptionBase', val.target.value)}
                />
              </div>
            </div>
          </div>
          <div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={handleCancelIngredient}>
            Cancel
          </Button>{" "}
          <Button color="primary" onClick={handleSaveIngredient}>
            OK
          </Button>
        </ModalFooter>
      </Modal>
      */
      }

      <Modal
        isOpen={stepCreateOpen}
        toggle={() => setStepCreateOpen(!stepCreateOpen)}
        centered
        size="md"
      >
        <ModalHeader toggle={() => setStepCreateOpen(!stepCreateOpen)}>
          Create Step
        </ModalHeader>
        <ModalBody className="text-center m-3">
          <div className="d-flex mb-2">
            <p style={{ fontSize: 18, fontWeight: 'bold', marginBottom: 5, }}>
              Input Step Text
            </p>
            <Select
              className="react-select-container"
              classNamePrefix="react-select"
              styles={customStyles}
              options={languageOptions}
              isSearchable={false}
              defaultValue={languageOptions[0]}
              onChange={(item) => setLanguage({ ...language, step: item.value })}
            />
          </div>
          <Input
            type="textarea"
            name="Step-Text"
            placeholder="Input Step Text"
            rows="3"
            value={addedStep?.text?.[language.step] ?? ''}
            onChange={(val) => setAddedStep({ ...addedStep, step: (updatedRecipe.instructions?.length ?? 0) + 1, text: { ...addedStep.text, [language.step]: val.target.value } })}
          />
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => setStepCreateOpen(!stepCreateOpen)}>
            Cancel
          </Button>{" "}
          <Button color="primary" onClick={handleSaveStep}>
            OK
          </Button>
        </ModalFooter>
      </Modal>

      <Modal
        isOpen={stepEditOpen}
        toggle={() => setStepEditOpen(!stepEditOpen)}
        centered
        size="md"
      >
        <ModalHeader toggle={() => setStepEditOpen(!stepEditOpen)}>
          Edit Step
        </ModalHeader>
        <ModalBody className="text-center m-3">
          <div className="d-flex mb-2">
            <p style={{ fontSize: 18, fontWeight: 'bold', marginBottom: 5, }}>
              Input Step Text
            </p>
            <Select
              className="react-select-container"
              classNamePrefix="react-select"
              styles={customStyles}
              options={languageOptions}
              isSearchable={false}
              defaultValue={languageOptions[0]}
              onChange={(item) => setLanguage({ ...language, step: item.value })}
            />
          </div>
          <Input
            type="textarea"
            name="Step-Text"
            placeholder="Input Step Text"
            rows="3"
            value={editRecipe.instructions?.find(step => step.step === currentStepId)?.text[language.step] ?? ''}
            onChange={(val) => handleChangeStep(val.target.value)}
          />
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => setStepEditOpen(!stepEditOpen)}>
            Cancel
          </Button>{" "}
          <Button color="primary" onClick={handleSaveStep}>
            OK
          </Button>
        </ModalFooter>
      </Modal>

      <Modal
        isOpen={ingredientDeleteOpen}
        toggle={() => setIngredientDeleteOpen(false)}
        centered
      >
        <ModalHeader toggle={() => setIngredientDeleteOpen(false)}>
          Delete Ingredient
        </ModalHeader>
        <ModalBody className="text-center m-3">
          <p className="mb-0">
            Are you sure want to delete this ingredient?
          </p>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => setIngredientDeleteOpen(false)}>
            Cancel
          </Button>{" "}
          <Button color="danger" onClick={() => handleConfirmRemoveIngredient()}>
            Delete
          </Button>
        </ModalFooter>
      </Modal>

      <Modal
        isOpen={stepDeleteOpen}
        toggle={() => setStepDeleteOpen(false)}
        centered
      >
        <ModalHeader toggle={() => setStepDeleteOpen(false)}>
          Delete Instruction Step
        </ModalHeader>
        <ModalBody className="text-center m-3">
          <p className="mb-0">
            Are you sure want to delete this step?
          </p>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => setStepDeleteOpen(false)}>
            Cancel
          </Button>{" "}
          <Button color="danger" onClick={() => handleConfirmRemoveStep()}>
            Delete
          </Button>
        </ModalFooter>
      </Modal>

      <Modal
        isOpen={discardOpen}
        toggle={() => setDiscardOpen(false)}
        centered
      >
        <ModalHeader toggle={() => setDiscardOpen(false)}>
          Discard changes
        </ModalHeader>
        <ModalBody className="text-center m-3">
          <p className="mb-0">
            Are you sure want to discard all changes?
          </p>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => setDiscardOpen(false)}>
            Cancel
          </Button>{" "}
          <Button color="danger" onClick={() => handleDiscard('header')}>
            Discard
          </Button>
        </ModalFooter>
      </Modal>
    </React.Fragment >
  );
}

export default RecipeDetails;
