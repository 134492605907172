import React from "react";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
} from "@fortawesome/free-solid-svg-icons";


const BackButton = (props) => {

  const history = useHistory();
  const { to, params } = props;

  return (
    <span className="meal-type-item-button" style={{ backgroundColor: '#FFF1E6', marginBottom: 10, }} onClick={() => history.push(to, params)}>
      <FontAwesomeIcon icon={faArrowLeft} style={{ color: '#3490DD' }} />
    </span>
  );
}

export default BackButton;