import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPencilAlt,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";


const InstructionStepItem = (props) => {

  const { step, instruction, color, editable, onEdit, onDelete, } = props;


  return (
    <div className="instruction-step-item">
      <div className=" mb-2">
        <p style={{ fontSize: 16, fontWeight: 'bold', color: color, marginBottom: 0, }}>
          {`Step ${step}`}
          {
            editable ? (
              <span className="float-right" style={{ fontSize: 16, }}>
                <FontAwesomeIcon className="table-action-button" icon={faPencilAlt} style={{ color: '#FC9C52', marginRight: 10, }} onClick={() => onEdit(step)} />
                <FontAwesomeIcon className="table-action-button" icon={faTrashAlt} style={{ color: '#FF2048', fontSize: 16, }} onClick={() => onDelete(step)} />
              </span>
            ) : null
          }
        </p>
      </div>
      <p className="m-0">
        {instruction}
      </p>
    </div>
  );
}

export default InstructionStepItem;