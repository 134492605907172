import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import Rating from 'react-rating';
import { FaStar } from 'react-icons/fa';
import { FiStar } from 'react-icons/fi';
import {
  Card,
  CardBody,
  Container,
  Spinner,
} from "reactstrap";
import { useHistory } from "react-router";

import { ApiService } from "../../services/apiService";
import { showToastr } from '../../services/themeService';
import BackButton from '../../components/BackButton';
import { sessionLogout } from '../../redux/actions/sessionActions';

import PrepImage from '../../assets/img/prep.png';
import CookImage from '../../assets/img/cook.png';

import FoodImage1 from '../../assets/img/tmp/food-1.png';


const Votes = (props) => {

  const dispatch = useDispatch();
  const history = useHistory();

  const { id } = props.match.params;
  console.log(props.location);
  const { location } = props;

  const [recipe, setRecipe] = useState(null);
  const recipeInfo = useSelector((state) => state.api.recipeInfo);

  const defaultLangId = 1;

  useEffect(() => {
    if (!recipeInfo || (recipeInfo.items?.find(recipe => recipe.id == id) === undefined)) {
      ApiService.getRecipe(id)
        .then(data => {
          // if (data && data.message === "Unauthenticated.") {
          //   history.push('/admin');

          //   return;
          // }
          console.log(data);
          setRecipe(data);
        })
        .catch((err) => {
          console.log('catch');
          console.error(err);
          if (err.response.status === 401) {
            showToastr('warning', 'Authorization Error', err.response.data.message);
            dispatch(sessionLogout());

            history.push('/');
            return;
          }
        });
    } else {
      setRecipe(recipeInfo.items.find(recipe => recipe.id == id));
    }
  }, [recipeInfo]);


  if (!recipe) {
    return (
      <Container fluid className="vh-50 d-flex justify-content-center align-items-center">
        <Spinner color="primary" size="lg" className="mr-2" />
      </Container>
    );
  }


  return (
    <React.Fragment>
      <BackButton to={location.from === 'detail' ? `/admin/recipe/detail/${id}` : '/admin/recipes/page=1'} />
      <Card>
        <CardBody>
          <div className="d-flex align-items-center mb-5">
            <img
              src={FoodImage1}
              className="rounded"
              alt="Placeholder"
            />
            <div className="ml-5">
              <p style={{ fontSize: 24, fontWeight: 600, color: 'black', marginBottom: 10, }}>
                {recipe.title[defaultLangId]}
              </p>
              <p style={{ color: '#3490DD', }}>
                {recipe.author ? `Created by ${recipe.author}` : ""}
                {(recipe.author && recipe.submittedBy) ? "  |  " : ""}
                {recipe.submittedBy ? `Submitted by ${recipe.submittedBy}` : ""}
              </p>
              <span className="d-flex align-items-center mb-2">
                <img
                  src={PrepImage}
                  className="mr-2"
                />
                {`${recipe.preparationTime} mins to prep`}
                <img
                  src={CookImage}
                  className="ml-4 mr-2"
                />
                {`${recipe.cookingTime} mins to cook`}
              </span>
            </div>
          </div>
        </CardBody>
      </Card>
      <div>
        {
          recipe.votes.comments.map((vote, index) => (
            <Card key={index}>
              <CardBody>
                <p style={{ fontSize: 20, fontWeight: 600, marginBottom: 5, }}>
                  {vote.userName}
                </p>
                <Rating
                  emptySymbol={<FiStar style={{ color: '#FC9C52', width: 22 }} />}
                  fullSymbol={<FaStar style={{ color: '#FC9C52', width: 22 }} />}
                  initialRating={vote.star}
                  readonly
                />
                <p style={{ marginTop: 10, }}>
                  {vote.comment}
                </p>
              </CardBody>
            </Card>
          ))
        }
      </div>
    </React.Fragment>
  )
}


export default Votes;
