import React, { useState, useEffect } from "react";
import {
  Button,
  Input,
  Container,
  Spinner,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
} from "reactstrap";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPencilAlt,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";

import { ApiService } from "../../services/apiService";
import { showToastr } from '../../services/themeService';

import { sessionLogout } from '../../redux/actions/sessionActions';


const customStyles = {
  container: (provided, state) => ({
    ...provided,
    display: 'inline-block',
    width: '60%',
  }),
};

const Evaluations = () => {

  const dispatch = useDispatch();
  const history = useHistory();

  const fields = useSelector(state => state.api.fields);

  const [evaluations, setEvaluations] = useState(null);
  const [types, setTypes] = useState(null);
  const [factors, setFactors] = useState(null);
  const [subFactors, setSubFactors] = useState(null);
  const [currentEvaluation, setCurrentEvaluation] = useState(null);

  const [editOpen, setEditOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);

  

  useEffect(() => {
    
    ApiService.getEvaluationTypes()
      .then(data => {
        console.log('evaluationTypes', data);

        setTypes(data);
      })
      .catch(error => {
        console.log('evaluationTypes error', error);
        if (error.response.status === 401) {
          showToastr('warning', 'Authorization Error', error.response.data.message);
          dispatch(sessionLogout());

          history.push('/');
          return;
        }
      });

    ApiService.getEvaluationFactors()
      .then(data => {
        console.log('evaluationFactors', data);

        setFactors(data);
      })
      .catch(error => {
        console.log('evaluationFactors error', error);
        if (error.response.status === 401) {
          showToastr('warning', 'Authorization Error', error.response.data.message);
          dispatch(sessionLogout());

          history.push('/');
          return;
        }
      });

    ApiService.getEvaluationSubFactors()
      .then(data => {
        console.log('evaluationSubFactors', data);

        setSubFactors(data);
      })
      .catch(error => {
        console.log('evaluationSubFactors', error);
        if (error.response.status === 401) {
          showToastr('warning', 'Authorization Error', error.response.data.message);
          dispatch(sessionLogout());

          history.push('/');
          return;
        }
      });

    ApiService.getEvaluations()
      .then(data => {
        console.log('evaluations', data);

        setEvaluations(data);
      })
      .catch(error => {
        console.log('evaluations', error);
        if (error.response.status === 401) {
          showToastr('warning', 'Authorization Error', error.response.data.message);
          dispatch(sessionLogout());

          history.push('/');
          return;
        }
      });
  }, []);

  const handleSave = () => {
    ApiService.updateEvaluation(currentEvaluation.id, currentEvaluation)
      .then(data => {
        console.log(data);
        const tmpEvaluations = evaluations.slice();
        const evaluation = tmpEvaluations.find(tmpEvaluation => tmpEvaluation.id === currentEvaluation.id);
        const index = tmpEvaluations.indexOf(evaluation);

        tmpEvaluations[index] = data;

        setEvaluations(tmpEvaluations);
      })
      .catch(error => {
        console.log(error);
        if (error.response.status === 401) {
          showToastr('warning', 'Authorization Error', error.response.data.message);
          dispatch(sessionLogout());

          history.push('/');
          return;
        }
      })

    setCurrentEvaluation(null);
    setEditOpen(false);
  }

  const handleCancel = () => {
    setCurrentEvaluation(null);
    setEditOpen(false);
  }

  const handleEdit = (evaluation) => {
    setCurrentEvaluation(evaluation);

    setEditOpen(true);
  }

  const handleDelete = (evaluation) => {
    setCurrentEvaluation(evaluation);

    setDeleteOpen(true);
  }

  const handleConfirm = () => {
    ApiService.deleteEvaluations(currentEvaluation.id)
      .then(data => {
        console.log(data);
        const tmpEvaluations = evaluations.slice();
        const evaluation = tmpEvaluations.find(tmpEvaluation => tmpEvaluation.id === currentEvaluation.id);
        const index = tmpEvaluations.indexOf(evaluation);

        tmpEvaluations.splice(index, 1);

        setEvaluations(tmpEvaluations);
      })
      .catch(error => {
        console.log(error);
        if (error.response.status === 401) {
          showToastr('warning', 'Authorization Error', error.response.data.message);
          dispatch(sessionLogout());

          history.push('/');
          return;
        }
      })

    setCurrentEvaluation(null);
    setDeleteOpen(false);
  }


  if (!evaluations || !types || !factors || !subFactors || !fields) {
    return (
      <Container fluid className="vh-50 d-flex justify-content-center align-items-center">
        <Spinner color="primary" size="lg" className="mr-2" />
      </Container>
    );
  }


  let typeOptions = [];
  for (const id in types) {
    typeOptions.push({
      value: parseInt(id),
      label: types[id].name,
    });
  }

  let factorOptions = [];
  for (const id in factors) {
    typeOptions.push({
      value: parseInt(id),
      label: factors[id].name,
    });
  }

  let subFactorOptions = [];
  for (const id in subFactors) {
    typeOptions.push({
      value: parseInt(id),
      label: subFactors[id].name,
    });
  }


  return (
    <React.Fragment>
      <div>
        <Table responsive hover>
          <thead>
            <tr style={{ backgroundColor: '#6B6C72', color: 'white', }}>
              <th scope="col" style={{ borderRadius: '6px 0 0 0' }}>Type</th>
              <th scope="col">Factor</th>
              <th scope="col">Subfactor</th>
              <th scope="col">Subsubfactor</th>
              <th scope="col">Weight</th>
              {/* <th scope="col">Value</th>
            <th scope="col">Temp Calculated Total</th> */}
              <th scope="col" style={{ borderRadius: '0 6px 0 0' }}>Action</th>
            </tr>
          </thead>
          <tbody>
            {
              evaluations.map((evaluation, index) => (
                <tr key={index}>
                  <td>
                    {types[evaluation.typeId].name}
                  </td>
                  <td>
                    {factors[evaluation.factorId].name}
                  </td>
                  <td>
                    {subFactors[evaluation.sFactorId].name}
                  </td>
                  <td>
                    {evaluation.fieldId ? fields[evaluation.fieldId].display : 'N.A.'}
                  </td>
                  <td>
                    {evaluation.weight}
                  </td>
                  <td onClick={e => e.stopPropagation()}>
                    <FontAwesomeIcon className="table-action-button" icon={faPencilAlt} style={{ color: '#FC9C52', fontSize: 16, marginRight: 10, }} onClick={() => handleEdit(evaluation)} />
                    {/* <FontAwesomeIcon className="table-action-button" icon={faTrashAlt} style={{ color: '#FF2048', fontSize: 16, }} onClick={() => handleDelete(evaluation)} /> */}
                  </td>
                </tr>
              ))
            }
          </tbody>
        </Table>
      </div>

      <Modal
        isOpen={editOpen}
        toggle={() => setEditOpen(!editOpen)}
        centered
      >
        <ModalHeader toggle={() => setEditOpen(!editOpen)}>
          Edit Evaluation
        </ModalHeader>
        <ModalBody className="text-center m-3">
          <div className="m-0 d-flex justify-content-between align-items-center" style={{ padding: '10px 0', }}>
            <span>
              Type
            </span>
            <Select
              className="react-select-container"
              classNamePrefix="react-select"
              styles={customStyles}
              options={typeOptions}
              isSearchable={false}
              defaultValue={currentEvaluation ? typeOptions.find(typeOption => typeOption.value === currentEvaluation.typeId) : typeOptions[0]}
              onChange={(item) => setCurrentEvaluation({ ...currentEvaluation, typeId: item.value })}
            />
          </div>
          <div className="m-0 d-flex justify-content-between align-items-center" style={{ padding: '10px 0', }}>
            <span>
              Factor
            </span>
            <Select
              className="react-select-container"
              classNamePrefix="react-select"
              styles={customStyles}
              options={factorOptions}
              isSearchable={false}
              defaultValue={currentEvaluation ? factorOptions.find(factorOption => factorOption.value === currentEvaluation.factorId) : factorOptions[0]}
              onChange={(item) => setCurrentEvaluation({ ...currentEvaluation, factorId: item.value })}
            />
          </div>
          <div className="m-0 d-flex justify-content-between align-items-center" style={{ padding: '10px 0', }}>
            <span>
              Subfactor
            </span>
            <Select
              className="react-select-container"
              classNamePrefix="react-select"
              styles={customStyles}
              options={subFactorOptions}
              isSearchable={false}
              defaultValue={currentEvaluation ? subFactorOptions.find(subFactorOption => subFactorOption.value === currentEvaluation.sFactorId) : subFactorOptions[0]}
              onChange={(item) => setCurrentEvaluation({ ...currentEvaluation, subFactorId: item.value })}
            />
          </div>
          <div className="m-0 d-flex justify-content-between align-items-center" style={{ padding: '10px 0', }}>
            <span>
              Weight
            </span>
            <Input
              type="text"
              aria-label="Value"
              className="p-0 text-center"
              style={{ width: 75, height: 25, padding: '0 10px' }}
              value={currentEvaluation ? currentEvaluation.weight : ''}
              onChange={(val) => setCurrentEvaluation({ ...currentEvaluation, weight: val.target.value })}
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={handleCancel}>
            Cancel
          </Button>{" "}
          <Button color="primary" onClick={handleSave}>
            OK
          </Button>
        </ModalFooter>
      </Modal>

      <Modal
        isOpen={deleteOpen}
        toggle={() => setDeleteOpen(!deleteOpen)}
        centered
      >
        <ModalHeader toggle={() => setDeleteOpen(!deleteOpen)}>
          Delete evaluation
        </ModalHeader>
        <ModalBody className="text-center m-3">
          <p className="mb-0">
            Are you sure want to delete this evaluation?
          </p>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => setDeleteOpen(!deleteOpen)}>
            Cancel
          </Button>{" "}
          <Button color="danger" onClick={handleConfirm}>
            Delete
          </Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
}

export default Evaluations;
