import React from "react";
import { Provider } from "react-redux";
import ReduxToastr from "react-redux-toastr";
import { PersistGate } from 'redux-persist/integration/react'
import { persistStore } from 'redux-persist';
import { Spinner } from 'reactstrap';
import Amplify from 'aws-amplify';
import { awsConfig } from './config/aws-export';

import store from "./redux/store/index";
import Routes from "./routes/Routes";

import 'react-input-range/lib/css/index.css';

const isLocalhost = Boolean(
  window.location.hostname === "localhost" ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === "[::1]" ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
);

Amplify.configure(awsConfig);

const [ localRedirectSignIn, productRedirectSignIn ] = awsConfig.oauth.redirectSignIn.split(",");
const [ localRedirectSignOut, productRedirectSignOut ] = awsConfig.oauth.redirectSignOut.split(",");
const updateAwsConfig = {
  ...awsConfig,
  oauth: {
    ...awsConfig.oauth,
    redirectSignIn: isLocalhost ? localRedirectSignIn : productRedirectSignIn,
    redirectSignOut: isLocalhost ? localRedirectSignOut : productRedirectSignOut,
  }
}
Amplify.configure(updateAwsConfig);

const persistor = persistStore(store);

const App = () => (
  <Provider store={store}>
    <PersistGate loading={<Spinner color="primary" size="lg" className="mr-2" />} persistor={persistor}>
      <Routes />
      <ReduxToastr
        timeOut={5000}
        newestOnTop={true}
        position="top-right"
        transitionIn="fadeIn"
        transitionOut="fadeOut"
        progressBar
        closeOnToastrClick
      />
    </PersistGate>
  </Provider>
);

export default App;
