import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Auth } from "aws-amplify";
import {
  Button,
  Collapse,
  Navbar,
  Nav,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
} from "reactstrap";

import {
  Settings,
  User,
} from "react-feather";

import { toggleSidebar } from "../redux/actions/sidebarActions";
import { ApiService } from "../services/apiService";
import ImageUploader from '../components/ImageUploader';
import { showToastr } from '../services/themeService';
import { sessionLogout, updateProfile, } from "../redux/actions/sessionActions";
import { config } from "../config";


const NavbarComponent = ({ dispatch }) => {

  const { userId, profile } = useSelector((state) => state.session);

  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [updatedProfile, setUpdatedProfile] = useState({});

  useEffect(() => {
    if (profile && userId) {
      setUpdatedProfile({
        id: userId,
        name: profile.name ?? '',
        email: profile.email,
        image: profile.image ?? '',
        userGroupId: profile.userGroupId ?? '',
      });
    }
  }, [profile, userId]);

  const handleSignout = async () => {
    try {
      await Auth.signOut();

      dispatch(sessionLogout());
    } catch (error) {
      console.log(error);
    }
  }

  const handleProfile = () => {
    toggle();
  }

  const handleConfirm = () => {
    let updatedUser = {};

    updatedUser.email = updatedProfile.email;
    updatedUser.name = updatedProfile.name;
    updatedUser.userGroupId = updatedProfile.userGroupId;

    console.log(updatedUser);

    ApiService.updateUser(userId, updatedProfile)
      .then((data) => {
        dispatch(updateProfile(data));
      })
      .catch((err) => {
        console.error(err);
      });

    toggle();
  }

  const handleReset = async () => {
    try {
      const data = await Auth.forgotPassword(profile.email);
      console.log(data);
      await handleSignout();
      history.push('/admin/auth/reset-password', { email: profile.email });
    } catch (error) {
      console.log(error);
    }
  }

  const toggle = () => {
    setOpen(!open);
  }

  console.log('updatedProfile: ', updatedProfile);

  return (
    <React.Fragment>
      <Navbar color="white" light expand>
        <span
          className="sidebar-toggle d-flex mr-2"
          onClick={() => {
            dispatch(toggleSidebar());
          }}
        >
          <i className="hamburger align-self-center" />
        </span>
        <Collapse navbar>
          <Nav className="ml-auto" navbar>
            <UncontrolledDropdown nav inNavbar>
              <span className="d-inline-block d-sm-none">
                <DropdownToggle nav caret>
                  <Settings size={18} className="align-middle" />
                </DropdownToggle>
              </span>
              <span className="d-none d-sm-inline-block">
                <DropdownToggle nav caret>
                  <img
                    src={profile?.image ?? ''}
                    className="img-fluid rounded-circle mr-1"
                  />
                  <span style={{ color: '#07122E', fontSize: 16, fontWeight: 600, }}>{profile ? profile.name ?? 'No name' : ''}</span>
                </DropdownToggle>
              </span>
              <DropdownMenu right>
                <DropdownItem onClick={handleProfile}>
                  <User size={18} className="align-middle mr-2" />
                  Profile
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem>Settings & Privacy</DropdownItem>
                <DropdownItem onClick={handleSignout}>Sign out</DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
        </Collapse>
      </Navbar>

      <Modal
        isOpen={open}
        toggle={() => toggle()}
        centered
      >
        <ModalHeader toggle={() => toggle()}>
          Profile Settings
        </ModalHeader>
        <ModalBody className="m-3">
          <div className="d-flex justify-content-center">
            <ImageUploader round img={updatedProfile.image ?? ''} onChange={(img) => setUpdatedProfile({ ...updatedProfile, image: img })} />
          </div>
          <h4 className="mt-3">Name</h4>
          <Input
            name="Name"
            onChange={(val) => { setUpdatedProfile({ ...updatedProfile, name: val.target.value }); }}
            value={updatedProfile.name}
          />
          <h4 className="mt-3">Email</h4>
          <Input
            name="Name"
            disabled
            value={updatedProfile.email}
          />
        </ModalBody>
        <ModalFooter style={{ justifyContent: 'space-between' }}>
          <Button color="danger" onClick={() => handleReset()}>
            Reset Pass
          </Button>
          <div>
            <Button color="secondary" onClick={() => toggle()}>
              Cancel
            </Button>{" "}
            <Button color="primary" onClick={() => handleConfirm()}>
              Save
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};

export default connect(store => ({
  app: store.app
}))(NavbarComponent);
