import * as types from "../constants";

const initialState = {
  ingredientInfo: null,
  rddInfo: null,
  recipeInfo: null,
  fields: null,
  calcTypes: null,
  languages: null,
  tabs: null,
  groups: null,
  cuisines: null,
  vegetarianTypes: null,
  difficultyLevels: null,
  groceryTypes: null,
  countries: null,
  mealLevels: null,
  filter: {ingredient: '', recipe: ''}
};

export default function reducer(state = initialState, actions) {
  switch (actions.type) {
    case types.INGREDIENTS_UPDATE:
      return {
        ...state,
        ingredientInfo: actions.data,
      };

    case types.INGREDIENTS_NONE:
      return {
        ...state,
        ingredientInfo: null,
      };

    case types.RDDS_UPDATE:
      return {
        ...state,
        rddInfo: actions.data,
      };

    case types.RDDS_NONE:
      return {
        ...state,
        rddInfo: null,
      };

    case types.RECIPES_UPDATE:
      return {
        ...state,
        recipeInfo: actions.data,
      };

    case types.RECIPES_NONE:
      return {
        ...state,
        recipeInfo: null,
      };

    case types.FIELDS_UPDATE:
      return {
        ...state,
        fields: actions.fields, 
      };

    case types.FILTER_INGREDIENT_UPDATE:
      return {
        ...state,
        filter: {...state.filter, ingredient: actions.keyword},
      };

    case types.FILTER_RECIPE_UPDATE:
      return {
        ...state,
        filter: {...state.filter, recipe: actions.keyword},
      };

    case types.GROCERIES_UPDATE:
        return {
          ...state,
          groceryTypes: actions.groceryTypes,
        };

    case types.CALCTYPES_UPDATE:
      return {
        ...state,
        calcTypes: actions.calcTypes,
      };

    case types.LANGUAGES_UPDATE:
      return {
        ...state,
        languages: actions.languages,
      };

    case types.TABS_UPDATE:
      return {
        ...state,
        tabs: actions.tabs,
      };

    case types.GROUPS_UPDATE:
      return {
        ...state,
        groups: actions.groups,
      };

    case types.CUISINES_UPDATE:
      return {
        ...state,
        cuisines: actions.cuisines,
      };

    case types.VEGETARIANTYPES_UPDATE:
      return {
        ...state,
        vegetarianTypes: actions.vegetarianTypes,
      };

    case types.DIFFICULTYLEVELS_UPDATE:
      return {
        ...state,
        difficultyLevels: actions.difficultyLevels,
      };

    case types.COUNTRIES_UPDATE:
      return {
        ...state,
        countries: actions.countries,
      };

    case types.MEALLEVELS_UPDATE:
      return {
        ...state,
        mealLevels: actions.mealLevels,
      };

    default:
      return state;
  }
}
