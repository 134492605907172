import React, { useState } from 'react';
import { Input } from 'reactstrap';
import {
  HiOutlineUser,
  HiOutlineMail,
  HiOutlineLockClosed,
  HiOutlineEye,
  HiOutlineEyeOff,
} from 'react-icons/hi';


const SignEdit = (props) => {

  const { name, user, email, pass, view, eye, placeholder, style, onViewClick, value, onChange } = props;

  const [off, setOff] = useState(false);

  const handleClick = () => {
    setOff(!off);

    onViewClick();
  }


  return (
    <div className="sign-edit-container" style={{ style }}>
      {user ? <HiOutlineUser size={20} /> : null}
      {email ? <HiOutlineMail size={20} /> : null}
      {pass ? <HiOutlineLockClosed size={20} /> : null}
      <Input
        type={pass && !view ? 'password' : 'text'}
        name={name}
        placeholder={placeholder}
        aria-label=""
        className="form-control-no-border mr-sm-2 sign-edit"
        value={value}
        onChange={(val) => onChange(val.target.value)}
      />
      {eye ? (off ? <HiOutlineEyeOff className="pointer" size={20} onClick={handleClick} /> : <HiOutlineEye className="pointer" size={20} onClick={handleClick} />) : null}
    </div>
  );
}

export default SignEdit;