import { combineReducers } from "redux";

import sidebar from "./sidebarReducers";
import session from "./sessionReducer";
import api from "./apiReducer";

import { reducer as toastr } from "react-redux-toastr";

export default combineReducers({
  sidebar,
  toastr,
  session,
  api,
});
