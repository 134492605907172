import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import {
  Container,
  Spinner,
  Table
} from "reactstrap";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRedoAlt } from "@fortawesome/free-solid-svg-icons";

import { sessionLogout } from '../../redux/actions/sessionActions';
import { ApiService } from "../../services/apiService";
import { showToastr } from '../../services/themeService';


import Image from '../../assets/img/tmp/coconut-milk.png';


const Evaluation = (props) => {
  const { id } = props.match.params;

  const history = useHistory();
  const dispatch = useDispatch();

  const fields = useSelector(state => state.api.fields);
  const rddInfo = useSelector(state => state.api.rddInfo);

  const [evaluations, setEvaluations] = useState(null);
  const [types, setTypes] = useState(null);
  const [factors, setFactors] = useState(null);
  const [rddFactor, setRddFactor] = useState(0);
  const [subFactors, setSubFactors] = useState(null);
  const [recipeEval, setRecipeEval] = useState(null);
  const [selectedRdd, setSelectedRdd] = useState(100);

  

  useEffect(() => {

    ApiService.getFactors()
      .then(data => {
        console.log("Rdd factor:", data["RDD Factor"]);
        setRddFactor(data["RDD Factor"]);
      })
      .catch(error => {
        console.log('Rdd factor error', error);
        if (error?.response?.status === 401) {
          showToastr('warning', 'Authorization Error', error.response.data.message);
          dispatch(sessionLogout());

          history.push('/');
          return;
        }
    });
    
    ApiService.getEvaluationTypes()
      .then(data => {
        console.log('evaluationTypes', data);

        setTypes(data);
      })
      .catch(error => {
        console.log('evaluationTypes error', error);
        if (error?.response?.status === 401) {
          showToastr('warning', 'Authorization Error', error.response.data.message);
          dispatch(sessionLogout());

          history.push('/');
          return;
        }
      });

    ApiService.getEvaluationFactors()
      .then(data => {
        console.log('evaluationFactors', data);

        setFactors(data);
      })
      .catch(error => {
        console.log('evaluationFactors error', error);
        if (error?.response?.status === 401) {
          showToastr('warning', 'Authorization Error', error.response.data.message);
          dispatch(sessionLogout());

          history.push('/');
          return;
        }
      });

    ApiService.getEvaluationSubFactors()
      .then(data => {
        console.log('evaluationSubFactors', data);

        setSubFactors(data);
      })
      .catch(error => {
        console.log('evaluationSubFactors', error);
        if (error?.response?.status === 401) {
          showToastr('warning', 'Authorization Error', error.response.data.message);
          dispatch(sessionLogout());

          history.push('/');
          return;
        }
      });

    ApiService.getEvaluations()
      .then(data => {
        console.log('evaluations', data);

        setEvaluations(data);
      })
      .catch(error => {
        console.log('evaluations', error);
        if (error?.response?.status === 401) {
          showToastr('warning', 'Authorization Error', error.response.data.message);
          dispatch(sessionLogout());

          history.push('/');
          return;
        }
      });

    ApiService.getRecipeEvaluationDetails(id)
      .then(data => {
        if (data.errorType) {
          console.log("recipe eval error:", data.errorMessage);
        }
        else {
          console.log("recipe eval:", data);
          setRecipeEval(data);
        }
      })
      .catch(err => {
        console.log("Error removing recipe:", err);
        if (err?.response?.status === 401) {
          showToastr('warning', 'Authorization Error', err.response.data.message);
          dispatch(sessionLogout());

          history.push('/');
          return;
        } 
      });
  }, []);

  const handleRddChange = (val) => {
    setSelectedRdd(val.value);
  }

  const reevaluate = () => {
    ApiService.evaluateMeal(id, "all")
    .then(data => {
      console.log("Evaluate:", data);
    })
    .catch(err => {
      console.log("Error evaluating recipe:", err);
      if (err?.response?.status === 401) {
        showToastr('warning', 'Authorization Error', err.response.data.message);
        dispatch(sessionLogout());

        history.push('/');
        return;
      }
    });
  }

  if (!evaluations || !types || !factors || !subFactors || !fields || !recipeEval || !rddInfo) {
    return (
      <Container fluid className="vh-50 d-flex justify-content-center align-items-center">
        <Spinner color="primary" size="lg" className="mr-2" />
      </Container>
    );
  }

  let rddOptions = recipeEval.rddValues.map(v => {return {value: v.rddCode, label: rddInfo.rdds.find(rdd => {return rdd.code == v.rddCode;}).title};});

  return (
    <React.Fragment>
      <div>
        <div style={{ padding: 20, backgroundColor: 'white', display: 'inline-block', borderRadius: 6, }}>
          <img src={recipeEval.image ?? Image} style={{ borderRadius: 6, maxHeight: 200, }}></img>
          <h3 className="tab-title mt-4 mb-3">
            {recipeEval.title[1]}
          </h3>
          <div style={{ color: '#6B6C72', fontSize: 14, fontWeight: 400, }}>
            <p style={{ color: '#3490DD', }}>
              {recipeEval.author ? `Created by ${recipeEval.author}` : ""}
              {(recipeEval.author && recipeEval.submittedBy) ? "  |  " : ""}
              {recipeEval.submittedBy ? `Submitted by ${recipeEval.submittedBy}` : ""}
            </p>
            <FontAwesomeIcon className="table-action-button" icon={faRedoAlt} style={{ color: '#FC9C52', float: 'right', }} onClick={reevaluate}/>
            <p className="mb-3">
              Re-evaluate
            </p>
          </div>
        </div>
        <br></br>
        <br></br>
        <Select
          className="react-select-container"
          classNamePrefix="react-select"
          options={rddOptions}
          isSearchable={false}
          defaultValue={rddOptions[0]}
          onChange={(val) => {handleRddChange(val)}}
        />
        <br></br>
        <Table responsive hover>
          <thead>
            <tr style={{ backgroundColor: '#6B6C72', color: 'white', }}>
              <th scope="col" style={{ borderRadius: '6px 0 0 0' }}>Type</th>
              <th scope="col">Factor</th>
              <th scope="col">Subfactor</th>
              <th scope="col">Subsubfactor</th>
              <th scope="col">Weight</th>
              {/* <th scope="col">Value</th>
            <th scope="col">Temp Calculated Total</th> */}
              <th scope="col">Result</th>
              <th scope="col">Per Person</th>
              <th scope="col">RDD Value</th>
              <th scope="col" style={{ borderRadius: '0 6px 0 0' }}>Calculation Method</th>
            </tr>
          </thead>
          <tbody>
            {
              evaluations.map((evaluation, index) => (
                <tr key={index}>
                  <td>
                    {types[evaluation.typeId].name}
                  </td>
                  <td>
                    {factors[evaluation.factorId].name}
                  </td>
                  <td>
                    {subFactors[evaluation.sFactorId].name}
                  </td>
                  <td>
                    {evaluation.fieldId ? fields[evaluation.fieldId].display : 'N.A.'}
                  </td>
                  <td>
                    {
                    evaluation.weight
                    }
                  </td>
                  <td>
                    {
                      recipeEval.rddValues.find(rddv => {return rddv.rddCode == selectedRdd}).evaluations.find(mealEval => {
                        return mealEval.typeId == evaluation.typeId &&
                        mealEval.factorId == evaluation.factorId &&
                        mealEval.sFactorId == evaluation.sFactorId &&
                        (evaluation.fieldId ? mealEval.fieldId == evaluation.fieldId : true)
                      })?.result
                    }
                  </td>
                  <td>
                    {
                      ((v) => {return v == -1 ? "N/A" : v})(recipeEval.rddValues.find(rddv => {return rddv.rddCode == selectedRdd}).evaluations.find(mealEval => {
                        return mealEval.typeId == evaluation.typeId &&
                        mealEval.factorId == evaluation.factorId &&
                        mealEval.sFactorId == evaluation.sFactorId &&
                        (evaluation.fieldId ? mealEval.fieldId == evaluation.fieldId : true)
                      })?.value)
                    }
                  </td>
                  <td>
                    {
                      (() => {
                        const v = rddInfo.rdds.find(rdd => rdd.code == selectedRdd).fields.find(field => evaluation.fieldId ? field.id == evaluation.fieldId : false)?.value;
                        if (v !== undefined) {
                          return v * rddFactor;
                        }
                        return "N/A";
                      })()
                    }
                  </td>
                  <td>
                    {
                      recipeEval.rddValues.find(rddv => {return rddv.rddCode == selectedRdd}).evaluations.find(mealEval => {
                        return mealEval.typeId == evaluation.typeId &&
                        mealEval.factorId == evaluation.factorId &&
                        mealEval.sFactorId == evaluation.sFactorId &&
                        (evaluation.fieldId ? mealEval.fieldId == evaluation.fieldId : true)
                      })?.calcType
                    }
                  </td>
                </tr>
              ))
            }
          </tbody>
        </Table>
      </div>
    </React.Fragment>
  );
}

export default Evaluation;