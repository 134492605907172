import React, { useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPencilAlt,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";


const IngredientElementItem = (props) => {

  const { ingredient, editable, onEdit, onDelete, onClick, active, disabled } = props;

  const defaultLangId = 1;


  return (
    <div className="ingredient-element-item"
      style={disabled ? { backgroundColor: '#e5e5e5', } : active ? { border: '3px solid #3B82EC', backgroundColor: '#1F9BCF' } : null}
      onClick={() => disabled ? null : onClick ? onClick() : null}
    >
      <img
        src={ingredient.image}
        width={50}
        height={50}
        className="rounded mr-2"
        alt=""
      />
      <div className="mr-6 text-left">
        <p style={{ fontSize: 16, fontWeight: 600, color: 'black', marginBottom: 5, }}>
          {ingredient.friendlyDescription?.[defaultLangId]}
        </p>
        {/* <p className="m-0">
          {ingredient.categoryDescription?.[defaultLangId]}
        </p> */}
      </div>
      {
        ingredient.quantity ? <div className="ml-md-auto mr-5">
          <p style={{ color: '#07122E', marginBottom: 5, }}>
            {ingredient.descriptionBase?.[defaultLangId]}
          </p>
          <p className="m-0">
            {`${ingredient.quantity} grams`}
          </p>
        </div> : null
      }
      {
        editable ? (
          <span className="float-right" style={{ fontSize: 16, minWidth: 45, }}>
            <FontAwesomeIcon className="table-action-button" icon={faPencilAlt} style={{ color: '#FC9C52', marginRight: 10, }} onClick={onEdit} />
            <FontAwesomeIcon className="table-action-button" icon={faTrashAlt} style={{ color: '#FF2048', fontSize: 16, }} onClick={onDelete} />
          </span>
        ) : null
      }

    </div>
  )
}

export default IngredientElementItem;