import * as types from "../constants";

const initialState = {
  userId: null,
  token: null,
  profile: null,
  keyword: '',
  filterOpen: false,
  filterOptions: {
    cuisines: [],
    vegetarianTypes: [],
    difficultyLevels: [],
    preparationTime: {
      range: {
        min: 0,
        max: 120,
      },
      value: {
        min: 0,
        max: 120,
      },
    },
    serves: {
      range: {
        min: 0,
        max: 8,
      },
      value: {
        min: 0,
        max: 8,
      },
    },
    userStars: {
      range: {
        min: 0,
        max: 5,
      },
      value: {
        min: 0,
        max: 5,
      },
    },
    evaluationStars: {
      range: {
        min: 0,
        max: 5,
      },
      value: {
        min: 0,
        max: 5,
      },
    },
    energy: {
      range: {
        min: 0,
        max: 800,
      },
      value: {
        min: 0,
        max: 800,
      },
    },
    protein: {
      range: {
        min: 0,
        max: 100,
      },
      value: {
        min: 0,
        max: 100,
      },
    },
    fat: {
      range: {
        min: 0,
        max: 100,
      },
      value: {
        min: 0,
        max: 100,
      },
    },
    sugar: {
      range: {
        min: 0,
        max: 100,
      },
      value: {
        min: 0,
        max: 100,
      },
    },
    carbohydrate: {
      range: {
        min: 0,
        max: 100,
      },
      value: {
        min: 0,
        max: 100,
      },
    },
    fiber: {
      range: {
        min: 0,
        max: 100,
      },
      value: {
        min: 0,
        max: 100,
      },
    },
  }
};

export default function reducer(state = initialState, actions) {
  switch (actions.type) {
    case types.SESSION_LOGIN:
      return {
        ...state,
        userId: actions.userId,
        token: actions.token,
      };

    case types.SESSION_LOGOUT:
      return initialState;

    case types.RECIPES_FILTER:
      return {
        ...state,
        filterOpen: !state.filterOpen,
      };

    case types.RECIPES_OPTION:
      return {
        ...state,
        filterOptions: actions.filterOptions,
      };

    case types.KEYWORD_UPDATE:
      return {
        ...state,
        keyword: actions.keyword,
      }

    case types.PROFILE_UPDATE:
      return {
        ...state,
        profile: actions.profile,
      }

    default:
      return state;
  }
}
