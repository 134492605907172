import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";

import {
  Card,
  CardBody,
} from "reactstrap";

import { ApiService } from "../../services/apiService";


const ShareWithFriends = ({ dispatch }) => {

  const history = useHistory();

  
  return (
    <React.Fragment>
      <div className="text-center mt-4">
        <h2>Welcome</h2>
        <p className="lead">Sign in to your account to continue</p>
      </div>

      <Card>
        <CardBody>
          
        </CardBody>
      </Card>
    </React.Fragment>
  );
}

export default ShareWithFriends;
