import * as types from "../constants";

export function setIngredientInfo(data) {
  return {
    type: types.INGREDIENTS_UPDATE,
    data,
  };
}

export function noneIngredientInfo() {
  return {
    type: types.INGREDIENTS_NONE,
  };
}

export function setRDDInfo(data) {
  return {
    type: types.RDDS_UPDATE,
    data,
  };
}

export function noneRDDInfo() {
  return {
    type: types.RDDS_NONE,
  };
}

export function setRecipeInfo(data) {
  return {
    type: types.RECIPES_UPDATE,
    data,
  };
}

export function noneRecipeInfo() {
  return {
    type: types.RECIPES_NONE,
  };
}

export function setFields(fields) {
  return {
    type: types.FIELDS_UPDATE,
    fields: fields,
  };
}

export function setIngredientFilter(keyword) {
  return {
    type: types.FILTER_INGREDIENT_UPDATE,
    keyword: keyword,
  };
}

export function setRecipeFilter(keyword) {
  return {
    type: types.FILTER_RECIPE_UPDATE,
    keyword: keyword,
  };
}

export function setGroceryTypes(groceryTypes) {
  return {
    type: types.GROCERIES_UPDATE,
    groceryTypes: groceryTypes,
  };
}

export function setCalcTypes(calcTypes) {
  return {
    type: types.CALCTYPES_UPDATE,
    calcTypes: calcTypes,
  };
}

export function setLanguages(languages) {
  return {
    type: types.LANGUAGES_UPDATE,
    languages: languages,
  };
}

export function setTabs(tabs) {
  return {
    type: types.TABS_UPDATE,
    tabs: tabs,
  };
}

export function setGroups(groups) {
  return {
    type: types.GROUPS_UPDATE,
    groups: groups,
  };
}

export function setCuisines(cuisines) {
  return {
    type: types.CUISINES_UPDATE,
    cuisines: cuisines,
  };
}

export function setVegetarianTypes(vegetarianTypes) {
  return {
    type: types.VEGETARIANTYPES_UPDATE,
    vegetarianTypes: vegetarianTypes,
  };
}

export function setDifficultyLevels(difficultyLevels) {
  return {
    type: types.DIFFICULTYLEVELS_UPDATE,
    difficultyLevels: difficultyLevels,
  };
}

export function setCountries(countries) {
  return {
    type: types.COUNTRIES_UPDATE,
    countries: countries,
  };
}

export function setMealLevels(mealLevels) {
  return {
    type: types.MEALLEVELS_UPDATE,
    mealLevels: mealLevels,
  };
}