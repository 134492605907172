import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from "react-router-dom";
import Papa from "papaparse";
import Rating from 'react-rating';
import { ReactS3Client } from '../../config/aws-export';
import classnames from "classnames";
import {
  Row,
  Col,
  Button,
  Container,
  Spinner,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Card,
  CardImg,
  CardBody,
  CustomInput,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  Table,
  TabPane,
  Form,
  Input
} from "reactstrap";
import Select from 'react-select';
import InputRange from 'react-input-range';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrashAlt,
  faBook,
  faPlusCircle,
  faMinusCircle,
  faSearch,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { FaStar } from 'react-icons/fa';
import { FiStar } from 'react-icons/fi';

import {
  setRecipeInfo,
  setRecipeFilter,
  noneRecipeInfo,
} from "../../redux/actions/apiActions";
import {
  filterRecipes,
  filterRecipeOptions,
  updateKeyword,
} from "../../redux/actions/sessionActions";
import { ApiService } from "../../services/apiService";
import Pagination from '../../components/Pagination';
import { showToastr } from '../../services/themeService';
import { sessionLogout } from '../../redux/actions/sessionActions';

import FilterIcon from '../../assets/img/filter.png';

import FoodImage1 from '../../assets/img/tmp/food-1.png';


const smallImage = (url) => {
  const split = url.split(".");
  split[split.length-2] += "-small";
  return split.join(".");
}

const RecipeItem = (props) => {

  const { data, onDelete, onPublish, page } = props;
  const recipe = data;

  const fields = useSelector((state) => state.api.fields);
  const cuisines = useSelector((state) => state.api.cuisines);
  const difficultyLevels = useSelector((state) => state.api.difficultyLevels);

  const [open, setOpen] = useState(false);

  const handleConfirm = () => {
    setOpen(false);

    onDelete(data.id);
  }

  if (!difficultyLevels || !recipe || !fields || !cuisines) {
    return (
      <div></div>
    )
  }

  return (
    <React.Fragment>
      <Card>
        {/* <CardImg top width="100%" src={recipe.image ?? FoodImage1} alt="Card image cap" /> */}
        <div style={{ backgroundImage: `url(${recipe.image ?? FoodImage1})`, width: '100%', paddingTop: '67.5%', backgroundSize: '100% 100%', borderRadius: 6, }}></div>
        <CardBody>
          <p style={{ fontSize: 18, marginBottom: 5, color: 'black', fontWeight: 'bold', textAlign: 'center', }}>
            {recipe.title[1]}
          </p>
          <p style={{ fontSize: 16, marginBottom: 5, color: 'black', textAlign: 'center', }}>
            {cuisines[recipe.cuisineId].name}
          </p>
          <div className="d-flex justify-content-between text-center" style={{ color: 'black', }}>
            <div>
              <p style={{ color: 'black', fontWeight: 700, marginBottom: 0, }}>
                Difficulty level
              </p>
              <p>
                {difficultyLevels[recipe.difficultyLevelId].name}
              </p>
            </div>
            <div>
              <p style={{ color: 'black', fontWeight: 700, marginBottom: 0, }}>
                Serves
              </p>
              <p>
                {recipe.serves}
              </p>
            </div>
          </div>
          <div className="d-flex justify-content-between">
            <div className="text-center">
              <p style={{ color: 'black', fontWeight: 700, marginBottom: 0, }}>
                Calorie
              </p>
              <p>
                {recipe.quantityPerPerson.find(quantity => quantity.fieldId == Object.entries(fields).find(field => field[1].display === 'Energy')?.[0])?.value?.toFixed(1)}
              </p>
            </div>
            <div className="text-center">
              <p style={{ color: 'black', fontWeight: 700, marginBottom: 0, }}>
                Protein
              </p>
              <p>
                {recipe.quantityPerPerson.find(quantity => quantity.fieldId == Object.entries(fields).find(field => field[1].display === 'Protein')?.[0])?.value?.toFixed(1)}
              </p>
            </div>
          </div>
          <div className="d-flex justify-content-between">
            <div className="d-flex align-items-center">
              <Link to={{pathname: `/admin/recipe/detail/${recipe.id}`, query: {recipePage: page}}}>
                Details
              </Link>
              <FontAwesomeIcon className="table-action-button" icon={faTrashAlt} style={{ color: '#FF2048', fontSize: 16, marginLeft: 20, }} onClick={() => setOpen(!open)} />
              <FontAwesomeIcon className="table-action-button" icon={faBook} style={{ color: recipe.statusId == 1 ? '#AAAAAA' : '#45BE00', fontSize: 16, marginLeft: 20, }} onClick={() => onPublish(recipe, recipe.statusId == 1 ? 2 : 1)} />
            </div>
            <span>
              {`(${Math.round(recipe.votes.voteStar * 10) / 10}`}
              <Link to={`/admin/recipe/detail/${recipe.id}/votes`}>
                <Rating
                  emptySymbol={<FiStar style={{ color: '#FC9C52', width: 22 }} />}
                  fullSymbol={<FaStar style={{ color: '#FC9C52', width: 22 }} />}
                  stop={1}
                  initialRating={recipe.votes.voteStar / 5}
                  readonly
                />
              </Link>
              {`${recipe.votes.voteCount}+)`}
            </span>
          </div>
        </CardBody>
      </Card>

      <Modal
        isOpen={open}
        toggle={() => setOpen(!open)}
        centered
      >
        <ModalHeader toggle={() => setOpen(!open)}>
          Delete Recipe
        </ModalHeader>
        <ModalBody className="text-center m-3">
          <p className="mb-0">
            Are you sure want to delete this Recipe?
          </p>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => setOpen(!open)}>
            Cancel
          </Button>{" "}
          <Button color="danger" onClick={() => handleConfirm()}>
            Delete
          </Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  )
}

const RecipeListItem = (props) => {

  const { data, onDelete, onPublish, page } = props;
  const recipe = data;

  const fields = useSelector((state) => state.api.fields);
  const cuisines = useSelector((state) => state.api.cuisines);
  const difficultyLevels = useSelector((state) => state.api.difficultyLevels);

  if (!difficultyLevels || !recipe || !fields || !cuisines) {
    return (
      <div></div>
    )
  }

  return (
    <React.Fragment>
      <ul style={{listStyleType: 'none', padding: 0, margin: 0}}>
        <li style={{display: 'inline-block', paddingRight: '10px'}}><img src={recipe.image ?? FoodImage1} style={{height: '67px', width: '100px', borderRadius: '3px'}}></img></li>
        <li style={{display: 'inline-block', paddingRight: '10px', width: '200px'}}>
          <b style={{textAlign: 'center'}}>{recipe.title[1]}</b>
          <p style={{textAlign: 'center'}}>{cuisines[recipe.cuisineId].name}</p>
        </li>
      </ul>
    </React.Fragment>
  )
}

const Recipes = (props) => {

  const page = parseInt(props.match.params.page);
  const history = useHistory();

  const languages = useSelector((state) => state.api.languages);
  const recipeInfo = useSelector((state) => state.api.recipeInfo);
  const filterOpen = useSelector((state) => state.session.filterOpen);
  const filterOptions = useSelector((state) => state.session.filterOptions);
  const filterKeyword = useSelector(state => state.session.keyword);
  const fields = useSelector((state) => state.api.fields);
  const cuisines = useSelector((state) => state.api.cuisines);
  const vegetarianTypes = useSelector((state) => state.api.vegetarianTypes);
  const difficultyLevels = useSelector((state) => state.api.difficultyLevels);
  const keyword = useSelector((state) => state.api.filter.recipe);

  const dispatch = useDispatch();

  const [expand, setExpand] = useState({
    cuisine: false,
    vegetarianType: false,
    difficultyLevel: false,
  });
  const [activeTab, setActiveTab] = useState(1);
  const [filterCuisines, setFilterCuisines] = useState(filterOptions.cuisines);
  const [filterVegetarianTypes, setFilterVegetarianTypes] = useState(filterOptions.vegetarianTypes);
  const [filterDifficultyLevels, setFilterDifficultyLevels] = useState(filterOptions.difficultyLevels);
  const [preparationRange, setPreparationRange] = useState(filterOptions.preparationTime.value);
  const [servesRange, setServesRange] = useState(filterOptions.serves.value);
  const [votingRange, setVotingRange] = useState(filterOptions.userStars.value);
  const [evaluationRange, setEvaluationRange] = useState(filterOptions.evaluationStars.value);
  const [energyRange, setEnergyRange] = useState(filterOptions.energy.value);
  const [proteinRange, setProteinRange] = useState(filterOptions.protein.value);
  const [fatRange, setFatRange] = useState(filterOptions.fat.value);
  const [sugarRange, setSugarRange] = useState(filterOptions.sugar.value);
  const [carbohydrateRange, setCarbohydrateRange] = useState(filterOptions.carbohydrate.value);
  const [fiberRange, setFiberRange] = useState(filterOptions.fiber.value);
  const [publishedFlag, setPublishedFlag] = useState(false);
  const [displayType, setDisplayType] = useState({label: "Card", value: "card"});

  const count = 12;

  useEffect(() => {
    handlePageChange(page);
  }, [page, filterKeyword, filterOptions]);
  
  const handlePublish = async(recipe, status) => {
    const request = {
      statusId: status
    };

    ApiService.updateRecipe(recipe.id, request)
      .then(data => {
        if (status == 2) {
          console.log("published:", data);
          showToastr("info", "Success", `Successfully published recipe "${data.title[1]}"`);
        }
        else {
          console.log("unpublished:", data);
          showToastr("info", "Success", `Successfully unpublished recipe "${data.title[1]}"`);
        }
        var tmpRecipes = recipeInfo.meals.slice();
        const oldRecipe = tmpRecipes.find(r => r.id == recipe.id);
        const index = tmpRecipes.indexOf(oldRecipe);
        tmpRecipes[index] = data;
        dispatch(setRecipeInfo({...recipeInfo, meals: tmpRecipes}));
        setPublishedFlag(true);
      })
      .catch((err) => {
        console.log('publish recipe error: ', err);
        if (err?.response?.status === 401) {
          showToastr('warning', 'Authorization Error', err.response.data.message);
          dispatch(sessionLogout());

          history.push('/');
          return;
        }
        else {
          showToastr("error", "Error", "An unknown error occurred");
        }
      });
  }

  const isRangeEqual = (object1, object2) => {
    return object1.min === object2.min && object1.max === object2.max;
  }

  const exportRecipes = () => {
    ApiService.getIngredients(1, 1000000)
      .then(ingredients => {
      console.log("Loaded ingredients");
      ApiService.exportRecipes()
        .then(async data => {
          if (data.errorType) {
            console.error(data);
            showToastr("error", data.errorMessage);
          }
          else {
            let columns = [];
            for (let k = 0; k < data.content.length; k++) {
              const recipe = data.content[k];
              
              let newTitles = {id: recipe.id, "type": "title", subId: ""};
              if (k == 0) {
                for (let lang in languages) {
                  newTitles[languages[lang].name] = "";
                }
              }
              for (const [key, value] of Object.entries(recipe.title)) {
                newTitles[languages[key].name] = value;
              }
              columns.push(newTitles);

              const nIngredients = Math.max(...Object.values(recipe.ingredients).map(value => value.length));
              for (let i = 0; i < nIngredients; i++) {
                let newIngredient = {id: recipe.id, type: "ingredient"};
                for (const [key, value] of Object.entries(recipe.ingredients)) {
                  console.log("key:", key);
                  console.log("value:", value[i]);
                  if (value[i]) {
                    newIngredient[languages[key].name] = value[i].text;
                    newIngredient.subId = value[i].id;
                    newIngredient.realId = value[i].realId;
                  }
                }
                let ingredientInfo = ingredients.ingredients.find(ing => ing.id == newIngredient.realId);
                let ingredientInfoEntry = {id: recipe.id, "type": "ingredient_info", subId: newIngredient.subId};
                for (const langId of Object.keys(ingredientInfo.friendlyDescription)) {
                  ingredientInfoEntry[languages[langId].name] = ingredientInfo.friendlyDescription[langId];
                }
                columns.push(ingredientInfoEntry);
                columns.push(newIngredient);
              }

              const nSteps = Math.max(...Object.values(recipe.steps).map(value => value.length));
              for (let s = 0; s < nSteps; s++) {
                let newStep = {id: recipe.id, "type": "step"};
                for (const [key, value] of Object.entries(recipe.steps)) {
                  newStep[languages[key].name] = value[s].text;
                  newStep.subId = value[s].id;
                }
                columns.push(newStep);
              }

            }

            const fileContent = Papa.unparse(columns, {encoding: "ISO-8859-1"});
            const blob = new Blob([fileContent]);
            const fileDownloadUrl = URL.createObjectURL(blob);
            const element = document.createElement("a");
            element.href = fileDownloadUrl;
            element.download = "export.csv";
            document.body.appendChild(element);
            element.click();
            URL.revokeObjectURL(blob);

            showToastr("info", "Success!");
          }
        }).catch(error => {
          console.error(error);
        });
      })
      .catch(error => {
        console.error("Ingredient error:", error);
      });
  }

  const importRecipes = (e) => {
    const fileUpload = e.target.parentElement.parentElement.children[1];
    fileUpload.click();
  }

  const handleImportFileChange = async (e) => {
    const files = e.target.files;
    if (files.length != 1) 
      return;

    Papa.parse(files[0], {
      header: true,
      encoding: "UTF-8",
      skipEmptyLines: true,
      complete: (result) => {
        if (result.errors.length != 0) {
          showToastr("error", `${result.errors[0].message} (row ${result.errors[0].row})`);
        }
        else {
          console.log("data:", result.data);
          let newRecipes = {};
          let success = true;
          for (const i in result.data) {
            if (!(result.data[i].id in newRecipes)) {
              newRecipes[result.data[i].id] = {id: parseInt(result.data[i].id), title: {}, ingredients: {}, steps: {}};
            }
            if (result.data[i].type == "title") {
              for (const [langId, lang] of Object.entries(languages)) {
                newRecipes[result.data[i].id].title[langId] = result.data[i][lang.name];
              }
            }
            else if (result.data[i].type == "ingredient") {
              for (const [langId, lang] of Object.entries(languages)) {
                if (!(langId in newRecipes[result.data[i].id].ingredients)) {
                  newRecipes[result.data[i].id].ingredients[langId] = [];
                }
                newRecipes[result.data[i].id].ingredients[langId].push({
                  text: result.data[i][lang.name],
                  id: parseInt(result.data[i].subId)
                });
              }
            }
            else if (result.data[i].type == "step") {
              for (const [langId, lang] of Object.entries(languages)) {
                if (!(langId in newRecipes[result.data[i].id].steps)) {
                  newRecipes[result.data[i].id].steps[langId] = [];
                }
                newRecipes[result.data[i].id].steps[langId].push({
                  text: result.data[i][lang.name],
                  id: parseInt(result.data[i].subId)
                });
              }
            }
          }
          newRecipes = Object.values(newRecipes);
          console.log(newRecipes);
          for (const element of newRecipes) {
            if (element.id === undefined || element.title === undefined || element.ingredients === undefined || element.steps === undefined) {
              success = false;
              console.log(element);
              showToastr("error", "Invalid data");
              break;
            }
          }
          if (success) {
            ApiService.importRecipes({content: newRecipes})
              .then(data => {
                if (data && data.errorMessage) {
                  console.error(data);
                  showToastr("error", data.errorMessage);
                }
                else 
                  showToastr("info", "Successfully imported new recipe data");
              }).catch(error => {
                console.error(error);
              });
          }
        }
      }
    });
  }

  const handlePageChange = (val, updatedFilterOptions = null) => {
    dispatch(noneRecipeInfo());

    const filterOption = updatedFilterOptions ?? filterOptions;
    let filter = {};
    if (filterOption.cuisines.length > 0)
      filter.cuisineList = filterOption.cuisines;
    if (filterOption.vegetarianTypes.length > 0)
      filter.vegetarianTypeList = filterOption.vegetarianTypes;
    if (filterOption.difficultyLevels.length > 0)
      filter.difficultyLevelList = filterOption.difficultyLevels;
    if (!isRangeEqual(filterOption.preparationTime.value, filterOption.preparationTime.range)) {
      filter.preparationTime = {
        from: filterOption.preparationTime.value.min,
        to: filterOption.preparationTime.value.max,
      };
    }
    if (!isRangeEqual(filterOption.serves.value, filterOption.serves.range)) {
      filter.serves = {
        from: filterOption.serves.value.min,
        to: filterOption.serves.value.max,
      };
    }
    if (!isRangeEqual(filterOption.userStars.value, filterOption.userStars.range)) {
      filter.userStars = {
        from: filterOption.userStars.value.min,
        to: filterOption.userStars.value.max,
      };
    }
    if (!isRangeEqual(filterOption.evaluationStars.value, filterOption.evaluationStars.range)) {
      filter.evaluationStars = {
        from: filterOption.evaluationStars.value.min,
        to: filterOption.evaluationStars.value.max,
      };
    }

    let ingredientRanges = [];
    if (!isRangeEqual(filterOption.energy.value, filterOption.energy.range)) {
      ingredientRanges.push({
        id: fields.find(field => field.display === 'Energy').id,
        from: filterOption.energy.value.min,
        to: filterOption.energy.value.max,
      });
    }
    if (!isRangeEqual(filterOption.protein.value, filterOption.protein.range)) {
      ingredientRanges.push({
        id: fields.find(field => field.display === 'Protein').id,
        from: filterOption.protein.value.min,
        to: filterOption.protein.value.max,
      });
    }
    if (!isRangeEqual(filterOption.fat.value, filterOption.fat.range)) {
      ingredientRanges.push({
        id: fields.find(field => field.display === 'Fat').id,
        from: filterOption.fat.value.min,
        to: filterOption.fat.value.max,
      });
    }
    if (!isRangeEqual(filterOption.sugar.value, filterOption.sugar.range)) {
      ingredientRanges.push({
        id: fields.find(field => field.display === 'Sugar').id,
        from: filterOption.sugar.value.min,
        to: filterOption.sugar.value.max,
      });
    }
    if (!isRangeEqual(filterOption.carbohydrate.value, filterOption.carbohydrate.range)) {
      ingredientRanges.push({
        id: fields.find(field => field.display === 'Carbohydrate').id,
        from: filterOption.carbohydrate.value.min,
        to: filterOption.carbohydrate.value.max,
      });
    }
    if (!isRangeEqual(filterOption.fiber.value, filterOption.fiber.range)) {
      ingredientRanges.push({
        id: fields.find(field => field.display === 'Fiber').id,
        from: filterOption.fiber.value.min,
        to: filterOption.fiber.value.max,
      });
    }
    if (ingredientRanges.length > 0)
      filter.ingredientRanges = ingredientRanges;

    ApiService.getRecipes(val, count, 'id', 'asc', keyword, filter)
      .then(data => {
        console.log('recipes:', data);
        dispatch(setRecipeInfo(data));
      })
      .catch((err) => {
        console.log('recipes error:', err);
        if (err.response.status === 401) {
          showToastr('warning', 'Authorization Error', err.response.data.message);
          dispatch(sessionLogout());

          history.push('/');
          return;
        }
      });
  }

  const handleDelete = id => {
    var tmpRecipes = recipeInfo.meals.slice();
    let tmpRecipe = tmpRecipes.find(recipe => recipe.id == id);
    let index = tmpRecipes.indexOf(tmpRecipe);
    console.log(index);

    tmpRecipes.splice(index, 1);
    console.log(tmpRecipes);

    ApiService.deleteRecipe(tmpRecipe.id)
      .then(data => {
        console.log(`Successfully deleted recipe (ID ${tmpRecipe.id})`);
        console.log(data);
        showToastr("info", "Successfully deleted recipe");
        dispatch(setRecipeInfo({ ...recipeInfo, meals: tmpRecipes }));
      })
      .catch((err) => {
        console.log("Error removing recipe:", err);
        if (err.response.status === 401) {
          showToastr('warning', 'Authorization Error', err.response.data.message);
          dispatch(sessionLogout());

          history.push('/');
          return;
        } 
        else if (err.response.status === 504) {
          showToastr("warning", "Timed Out", "Request timed out");
        }
        else {
          showToastr("error", "Unkown Error", "An unknown error occurred while deleting the recipe");
        }
      });
  }

  const handleFilter = () => {
    let updatedFilterOptions = JSON.parse(JSON.stringify(filterOptions));
    updatedFilterOptions.cuisines = filterCuisines;
    updatedFilterOptions.vegetarianTypes = filterVegetarianTypes;
    updatedFilterOptions.difficultyLevels = filterDifficultyLevels;
    updatedFilterOptions.preparationTime.value = preparationRange;
    updatedFilterOptions.serves.value = servesRange;
    updatedFilterOptions.userStars.value = votingRange;
    // updatedFilterOptions.evaluationStars.value = evaluationRange;
    updatedFilterOptions.energy.value = energyRange;
    updatedFilterOptions.protein.value = proteinRange;
    updatedFilterOptions.fat.value = fatRange;
    updatedFilterOptions.sugar.value = sugarRange;
    updatedFilterOptions.carbohydrate.value = carbohydrateRange;
    updatedFilterOptions.fiber.value = fiberRange;

    dispatch(filterRecipeOptions(updatedFilterOptions));

    dispatch(filterRecipes());

    if (page === 1) {
      handlePageChange(page, updatedFilterOptions);
    } else {
      history.push(`/admin/recipes/page=${page ?? 1}`);
    }
  }

  const handleCuisineCheck = (id) => {
    let tmpCuisines = filterCuisines.slice();
    if (tmpCuisines.find(tmpCuisine => tmpCuisine === id) === undefined) {
      tmpCuisines.push(id);
    } else {
      const index = tmpCuisines.indexOf(id);
      tmpCuisines.splice(index, 1);
    }

    setFilterCuisines(tmpCuisines);
  }

  const handleVegetarianCheck = (id) => {
    let tmpVegetarianTypes = filterVegetarianTypes.slice();
    if (tmpVegetarianTypes.find(tmpVegetarianType => tmpVegetarianType === id) === undefined) {
      tmpVegetarianTypes.push(id);
    } else {
      const index = tmpVegetarianTypes.indexOf(id);
      tmpVegetarianTypes.splice(index, 1);
    }

    setFilterVegetarianTypes(tmpVegetarianTypes);
  }

  const handleDifficultyCheck = (id) => {
    let tmpDifficultyLevels = filterDifficultyLevels.slice();
    if (tmpDifficultyLevels.find(tmpDifficultyLevel => tmpDifficultyLevel === id) === undefined) {
      tmpDifficultyLevels.push(id);
    } else {
      const index = tmpDifficultyLevels.indexOf(id);
      tmpDifficultyLevels.splice(index, 1);
    }

    setFilterDifficultyLevels(tmpDifficultyLevels);
  }

  const handleSearch = () => {
    // dispatch(updateKeyword(keyword));

    if (page === 1) {
      handlePageChange(page);
    } else {
      history.push('/admin/recipes/page=1');
    }
  }


  if (!recipeInfo) {
    return (
      <Container fluid className="vh-50 d-flex justify-content-center align-items-center">
        <Spinner color="primary" size="lg" className="mr-2" />
      </Container>
    );
  }

  console.log(recipeInfo);

  if (publishedFlag)
    setPublishedFlag(false);

  return (
    <React.Fragment>
      <div className="d-flex ml-auto mb-2" style={{ height: 50, width: '100%', }}>
      <div style={{marginRight: '25px', width: 146, minWidth: 100}}>
      <Select
        options={[{label: "List", value: "list"} , {label: "Card", value: "card"}]}
        value={displayType}
        onChange={(value) => setDisplayType(value)}
      ></Select>
      </div>
      <input type="file" accept="text/csv" id="import-file" style={{display: 'none'}} onChange={handleImportFileChange} onClick={(e) => {e.target.value = null}}/>
        <label htmlFor="import-file">
          <Button variant="contained" component="span" color="warning" style={{ width: 146, minWidth: 100, height: '100%', marginRight: 25, }} onClick={importRecipes}>
            Import
          </Button>
        </label>
        <Button color="warning" style={{ width: 146, minWidth: 100, height: '100%', marginRight: 25, }} onClick={() => exportRecipes()}>
          Export
        </Button>
        <Form className="d-flex" style={{ width: '100%', }} onSubmit={e => handleSearch(e)}>
          <div style={{ display: 'flex', backgroundColor: 'white', alignItems: 'center', marginRight: 30, width: '100%', }}>
            <Input
              type="text"
              placeholder="Search"
              aria-label="Search"
              className="form-control-no-border mr-sm-2"
              value={keyword}
              onChange={val => dispatch(setRecipeFilter(val.target.value))}
            />
            <FontAwesomeIcon icon={faSearch} style={{ color: '#6B6C72' }} className="mr-3" />
          </div>
        </Form>
        <Button color="warning" style={{ width: 146, minWidth: 100, height: '100%', marginRight: 25, }} onClick={() => dispatch(filterRecipes())}>
          <img src={FilterIcon} className="mr-2" /> Filter
        </Button>
        <Button color="primary" style={{ width: 161, minWidth: 120, height: '100%', }} onClick={() => history.push('/admin/recipe/detail')}>
          <FontAwesomeIcon icon={faPlus} className="mr-2" /> Insert New
        </Button>
      </div>
      <Row>
        {
          displayType.value == 'card' ?
          recipeInfo.meals?.map((recipe, index) => (
            <Col xl="3" md="4" sm="6" key={index}>
              <RecipeItem
                data={recipe}
                onDelete={id => handleDelete(id)}
                onPublish={handlePublish}
                page={page}
              />
            </Col>
          )) :
          (<Table responsive striped hover>
            <tbody>
            {
              recipeInfo.meals?.map((recipe, index) => (
                <tr key={index} onClick={() => history.push(`/admin/recipe/detail/${recipe.id}`, { page })}>
                  <td style={{ borderRadius: '6px 0 0 0' }}>
                    <img src={recipe.image ? smallImage(recipe.image) : FoodImage1} style={{width: '100px', height: '67px'}}></img>
                  </td>
                  <td>
                    <b>{recipe.title[1]}</b>
                  </td>
                  <td>
                    {cuisines[recipe.cuisineId].name}
                  </td>
                  <td>
                    <b>Difficulty Level</b>
                    <p>{difficultyLevels[recipe.difficultyLevelId].name}</p>
                  </td>
                  <td>
                    <b>Serves</b>
                    <p>{recipe.serves}</p>
                  </td>
                  <td>
                    <b>Calorie</b>
                    <p>{recipe.quantityPerPerson.find(quantity => quantity.fieldId == Object.entries(fields).find(field => field[1].display === 'Energy')?.[0])?.value?.toFixed(1)}</p>
                  </td>
                  <td>
                    <b>Protein</b>
                    <p>{recipe.quantityPerPerson.find(quantity => quantity.fieldId == Object.entries(fields).find(field => field[1].display === 'Protein')?.[0])?.value?.toFixed(1)}</p>
                  </td>
                  <td>
                    {`(${Math.round(recipe.votes.voteStar * 10) / 10}`}
                    <Link to={`/admin/recipe/detail/${recipe.id}/votes`}>
                      <Rating
                        emptySymbol={<FiStar style={{ color: '#FC9C52', width: 22 }} />}
                        fullSymbol={<FaStar style={{ color: '#FC9C52', width: 22 }} />}
                        stop={1}
                        initialRating={recipe.votes.voteStar / 5}
                        readonly
                      />
                    </Link>
                    {`${recipe.votes.voteCount}+)`}
                  </td>
                  <td>
                    <FontAwesomeIcon className="table-action-button" icon={faBook} style={{ color: recipe.statusId == 1 ? '#AAAAAA' : '#45BE00', fontSize: 16, marginLeft: 20, }} onClick={e => {e.stopPropagation(); handlePublish(recipe, recipe.statusId == 1 ? 2 : 1)}} />
                  </td>
                  <td style={{ borderRadius: '6px 0 0 0' }}>
                    <FontAwesomeIcon className="table-action-button" icon={faTrashAlt} style={{ color: '#FF2048', fontSize: 16, marginLeft: 20, }} onClick={e => e.stopPropagation()} />
                  </td>
                </tr>
              ))
            }
            </tbody>
          </Table>)
        }
      </Row>

      <Pagination
        value={page}
        maxPage={recipeInfo.pages}
        onChange={(val) => history.push(`/admin/recipes/page=${val}`)}
      />

      <Modal
        isOpen={filterOpen}
        toggle={() => dispatch(filterRecipes())}
        centered
      >
        <ModalHeader toggle={() => dispatch(filterRecipes())}>
          Filter Options
        </ModalHeader>
        <ModalBody className="m-3" style={{ minHeight: 300, }}>
          <Nav tabs>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === 1 })}
                onClick={() => {
                  setActiveTab(1);
                }}
              >
                Option 1
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === 2 })}
                onClick={() => {
                  setActiveTab(2);
                }}
              >
                Option 2
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === 3 })}
                onClick={() => {
                  setActiveTab(3);
                }}
              >
                Option 3
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={activeTab} style={{ padding: 10 }}>
            <TabPane tabId={1}>
              <div style={{ borderBottom: '0.5px solid gray', padding: '10px 0', }}>
                <div className="pointer" onClick={() => setExpand({ cuisine: !expand.cuisine, vegetarianType: false, difficultyLevel: false, })}>
                  {!expand.cuisine ?
                    <FontAwesomeIcon icon={faPlusCircle} style={{ marginRight: 10, verticalAlign: 'middle', }} />
                    : <FontAwesomeIcon icon={faMinusCircle} style={{ marginRight: 10, verticalAlign: 'middle', }} />}
                  Cuisines
                </div>
                {
                  expand.cuisine && cuisines ? (
                    <Row className="p-2">
                      {
                        Object.entries(cuisines).map((cuisine, index) => (
                          <Col md="6" lg="4" style={{ paddingTop: 5, paddingBottom: 5, }} key={index}>
                            <CustomInput
                              type="checkbox"
                              defaultChecked={filterCuisines.includes(cuisine[0])}
                              id={cuisine[0]}
                              label={cuisine[1].name}
                              onClick={() => handleCuisineCheck(cuisine[0])}
                            />
                          </Col>
                        ))
                      }
                    </Row>
                  ) : null
                }
              </div>
              <div style={{ borderBottom: '0.5px solid gray', padding: '10px 0', }}>
                <div className="pointer" onClick={() => setExpand({ cuisine: false, vegetarianType: !expand.vegetarianType, difficultyLevel: false, })}>
                  {!expand.vegetarianType ?
                    <FontAwesomeIcon icon={faPlusCircle} style={{ marginRight: 10, verticalAlign: 'middle', }} />
                    : <FontAwesomeIcon icon={faMinusCircle} style={{ marginRight: 10, verticalAlign: 'middle', }} />}
                  Vegetarian Types
                </div>
                {
                  expand.vegetarianType && vegetarianTypes ? (
                    <Row className="p-2">
                      {
                        Object.entries(vegetarianTypes).map((vegetarianType, index) => (
                          <Col md="6" style={{ paddingTop: 5, paddingBottom: 5, }} key={index}>
                            <CustomInput
                              type="checkbox"
                              defaultChecked={filterVegetarianTypes.includes(vegetarianType[0])}
                              id={vegetarianType[0]}
                              label={vegetarianType[1].name}
                              onClick={() => handleVegetarianCheck(vegetarianType[0])}
                            />
                          </Col>
                        ))
                      }
                    </Row>
                  ) : null
                }
              </div>
              <div style={{ borderBottom: '0.5px solid gray', padding: '10px 0', }}>
                <div className="pointer" onClick={() => setExpand({ cuisine: false, vegetarianType: false, difficultyLevel: !expand.difficultyLevel, })}>
                  {!expand.difficultyLevel ?
                    <FontAwesomeIcon icon={faPlusCircle} style={{ marginRight: 10, verticalAlign: 'middle', }} />
                    : <FontAwesomeIcon icon={faMinusCircle} style={{ marginRight: 10, verticalAlign: 'middle', }} />}
                  Difficulty Levels
                </div>
                {
                  expand.difficultyLevel && difficultyLevels ? (
                    <Row className="p-2">
                      {
                        Object.entries(difficultyLevels).map((difficultyLevel, index) => (
                          <Col md="4" style={{ paddingTop: 5, paddingBottom: 5, }} key={index}>
                            <CustomInput
                              type="checkbox"
                              defaultChecked={filterDifficultyLevels.includes(difficultyLevel[0])}
                              id={difficultyLevel[0]}
                              label={difficultyLevel[1].name}
                              onClick={() => handleDifficultyCheck(difficultyLevel[0])}
                            />
                          </Col>
                        ))
                      }
                    </Row>
                  ) : null
                }
              </div>
            </TabPane>
            <TabPane tabId={2}>
              <p style={{ color: '#07122E', fontWeight: 'bold', marginTop: 40, }}>
                Time of Preparation
              </p>
              <InputRange
                maxValue={filterOptions.preparationTime.range.max}
                minValue={filterOptions.preparationTime.range.min}
                value={preparationRange}
                onChange={value => setPreparationRange(value)}
              />
              <p style={{ color: '#07122E', fontWeight: 'bold', marginTop: 40, }}>
                Number of serves per meal
              </p>
              <InputRange
                maxValue={filterOptions.serves.range.max}
                minValue={filterOptions.serves.range.min}
                value={servesRange}
                onChange={value => setServesRange(value)}
              />
              <p style={{ color: '#07122E', fontWeight: 'bold', marginTop: 40, }}>
                User Stars
              </p>
              <InputRange
                maxValue={filterOptions.userStars.range.max}
                minValue={filterOptions.userStars.range.min}
                value={votingRange}
                onChange={value => setVotingRange(value)}
              />
              {/* <p style={{ color: '#07122E', fontWeight: 'bold', marginTop: 40, }}>
                Evaluation Stars
              </p>
              <InputRange
                maxValue={filterOptions.evaluationStars.range.max}
                minValue={filterOptions.evaluationStars.range.min}
                value={evaluationRange}
                onChange={value => setEvaluationRange(value)}
              /> */}
            </TabPane>
            <TabPane tabId={3}>
              <p style={{ color: '#07122E', fontWeight: 'bold', marginTop: 40, }}>
                Energy
              </p>
              <InputRange
                maxValue={filterOptions.energy.range.max}
                minValue={filterOptions.energy.range.min}
                value={energyRange}
                onChange={value => setEnergyRange(value)}
              />
              <p style={{ color: '#07122E', fontWeight: 'bold', marginTop: 40, }}>
                Protein
              </p>
              <InputRange
                maxValue={filterOptions.protein.range.max}
                minValue={filterOptions.protein.range.min}
                value={proteinRange}
                onChange={value => setProteinRange(value)}
              />
              <p style={{ color: '#07122E', fontWeight: 'bold', marginTop: 40, }}>
                Fat
              </p>
              <InputRange
                maxValue={filterOptions.fat.range.max}
                minValue={filterOptions.fat.range.min}
                value={fatRange}
                onChange={value => setFatRange(value)}
              />
              <p style={{ color: '#07122E', fontWeight: 'bold', marginTop: 40, }}>
                Sugar
              </p>
              <InputRange
                maxValue={filterOptions.sugar.range.max}
                minValue={filterOptions.sugar.range.min}
                value={sugarRange}
                onChange={value => setSugarRange(value)}
              />
              <p style={{ color: '#07122E', fontWeight: 'bold', marginTop: 40, }}>
                Carbohydrate
              </p>
              <InputRange
                maxValue={filterOptions.carbohydrate.range.max}
                minValue={filterOptions.carbohydrate.range.min}
                value={carbohydrateRange}
                onChange={value => setCarbohydrateRange(value)}
              />
              <p style={{ color: '#07122E', fontWeight: 'bold', marginTop: 40, }}>
                Fiber
              </p>
              <InputRange
                maxValue={filterOptions.fiber.range.max}
                minValue={filterOptions.fiber.range.min}
                value={fiberRange}
                onChange={value => setFiberRange(value)}
              />
            </TabPane>
          </TabContent>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => dispatch(filterRecipes())}>
            Cancel
          </Button>{" "}
          <Button color="primary" onClick={() => handleFilter()}>
            OK
          </Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
}

export default Recipes;