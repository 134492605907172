import React, { useState, useEffect } from "react";
import classnames from "classnames";
import { useDispatch, useSelector } from 'react-redux';
import {
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Container,
  Spinner,
} from "reactstrap";
import { useHistory } from "react-router";

import BackButton from '../../components/BackButton';
import { ApiService } from "../../services/apiService";
import { showToastr } from '../../services/themeService';

import { sessionLogout } from '../../redux/actions/sessionActions';

import Image from '../../assets/img/tmp/man-19-50.png';


const RDDDetails = (props) => {

  const dispatch = useDispatch();
  const history = useHistory();

  const { id, type } = props.match.params;
  const page = props.history.location.state?.page;

  const rddInfo = useSelector((state) => state.api.rddInfo);
  const fields = useSelector((state) => state.api.fields);
  const calcTypes = useSelector((state) => state.api.calcTypes);
  const tabs = useSelector((state) => state.api.tabs);
  const groups = useSelector((state) => state.api.groups);

  const [rdd, setRDD] = useState(null);
  const [activeTab, setActiveTab] = useState(1);

  const defaultLangId = 1;

  

  useEffect(() => {
    
    
    console.log(rddInfo);
    console.log(id);
    console.log(type);

    if (!rddInfo || rddInfo.rdds.find(rdd => rdd.code == id && rdd.typeId == type) === undefined) {
      ApiService.getRDD(id, type)
        .then(data => {
          // if (data && data.message === "Unauthenticated.") {
          //   history.push('/admin');

          //   return;
          // }
          console.log("rdd:", data);
          setRDD(data);
        })
        .catch((err) => {
          console.log('catch');
          console.error(err);
          if (err.response.status === 401) {
            showToastr('warning', 'Authorization Error', err.response.data.message);
            dispatch(sessionLogout());

            history.push('/');
            return;
          }
        });
    } else {
      console.log("rdd already existing:", rddInfo);
      setRDD(rddInfo.rdds.find(rdd => rdd.code == id));
    }

  }, [rddInfo]);

  const getFieldNamed = (fieldContainer, fieldName) => {
    let fieldKey = Object.keys(fields).find(key => fields[key].name === fieldName);
    if (fieldKey === undefined)
      return undefined;
    
    let result = fieldContainer.fields.find(f => f.id == fieldKey);
    if (result === undefined)
      return undefined; 
    return result.value;
  }

  if (!rdd || !fields) {
    return (
      <Container fluid className="vh-50 d-flex justify-content-center align-items-center">
        <Spinner color="primary" size="lg" className="mr-2" />
      </Container>
    );
  }

  let tabArray = new Array();
  let groupArray = new Array();
  let fieldId, tabId, tabName;
  let groupId, groupName;
  rdd.fields.filter(field => {
    fieldId = field.id;
    tabId = fields[fieldId].tabId;
    tabName = tabs[tabId][defaultLangId];
    if (tabName === 'Base' || tabName === 'Micro' || tabName === 'Others' || tabArray.find(tab => tab.id === tabId) !== undefined)
      return false;

    groupArray = [];
    Object.values(fields).filter(field => field.tabId === tabId)
      .map(field => {
        groupId = field.groupId;
        groupName = groups[groupId][defaultLangId];

        if (groupArray.find(group => group.id === groupId) === undefined) {
          groupArray.push({
            id: groupId,
            name: groupName,
          });
        }
      });

    tabArray.push({
      id: tabId,
      name: tabName,
      group: groupArray,
    });

    return true;
  });


  return (
    <div style={{ padding: '0 30px' }}>
      <BackButton to={`/admin/rdds/page=${page ? page : 1}`} />
      <Nav tabs>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === 1 })}
            onClick={() => {
              setActiveTab(1);
            }}
          >
            RDD Base Information
          </NavLink>
        </NavItem>
        {
          tabArray.map((tab, index) => (
            <NavItem key={index}>
              <NavLink
                className={classnames({ active: activeTab === tab.id })}
                onClick={() => {
                  setActiveTab(tab.id);
                }}
              >
                {tab.name}
              </NavLink>
            </NavItem>
          ))
        }
      </Nav>

      <TabContent activeTab={activeTab} style={{ padding: '50px 0' }}>
        <TabPane tabId={1}>
          <div style={{ padding: 20, backgroundColor: 'white', borderRadius: 6, display: 'inline-block' }}>
            <img src={rdd.image ?? Image} style={{ borderRadius: 6, }}></img>
            <h3 className="tab-title mt-4 mb-3">
              {rdd.title}
            </h3>
            <div style={{ color: '#6B6C72', fontSize: 14, fontWeight: 400, }}>
              <p className="mb-3">
                <span style={{ color: '#3490DD', marginRight: 5, }}>
                  Gender:
                </span>
                <span>
                  {rdd.gender}
                </span>
              </p>
              <p className="mb-3">
                <span style={{ color: '#3490DD', marginRight: 5, }}>
                  Start Age:
                </span>
                <span>
                  {rdd.startAge}
                </span>
                <span style={{ color: '#3490DD', marginRight: 5, marginLeft: 30 }}>
                  End Age:
                </span>
                <span>
                  {rdd.endAge}
                </span>
              </p>
              <p className="mb-4">
                <span style={{ marginRight: 5, }}>
                  Type:
                </span>
                <span style={{ fontWeight: 'bold', }}>
                  {calcTypes[rdd.typeId].name}
                </span>
              </p>
              <p className="mb-1">
                Energy (kcal):
              </p>
              <p style={{ fontSize: 30, color: 'black', fontWeight: 'bold', }}>
                {getFieldNamed(rdd, "energy_cal")}
              </p>
            </div>
          </div>
        </TabPane>
        {
          tabArray.map((tab, index) => {
            return (
              <TabPane tabId={tab.id} key={index}>
                <div className="row m-0">
                  {
                    tab.group.map((group, index) => (
                      <div className="col-md-6" style={{ padding: '0 15px', }} key={index}>
                        <div style={{ padding: '20px 30px', backgroundColor: 'white', borderRadius: 6, color: '#6B6C72', fontSize: 14, fontWeight: 400, }}>
                          <p style={{ fontSize: 24, fontWeight: 600, color: 'black', padding: '0 30px' }}>
                            {group.name}
                          </p>
                          {
                            rdd.fields.map((field, index) => (
                              fields[field.id].tabId === tab.id && fields[field.id].groupId === group.id && field.value ? (
                                <div className="d-flex justify-content-between" style={{ padding: '15px 30px', borderBottom: '1px solid rgba(7, 18, 46, 0.1)', }} key={index}>
                                  <span style={field.fieldName === 'Protein' ? { fontSize: 16, fontWeight: 900, color: 'black', } : null}>
                                    {`${fields[field.id].display} (${fields[field.id].unit})`}
                                  </span>
                                  <span>
                                    {field.value}
                                  </span>
                                </div>
                              ) : null
                            ))
                          }
                        </div>
                      </div>
                    ))
                  }
                </div>
              </TabPane>
            );
          })
        }
      </TabContent>
    </div>
  );
}

export default RDDDetails;
