import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Table,
  Input,
  Container,
  Spinner,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
} from "reactstrap";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSearch,
  faPencilAlt,
  faTrashAlt,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";

import ImageUploader from '../../components/ImageUploader';
import { ApiService } from "../../services/apiService";
import Pagination from '../../components/Pagination';
import { ReactS3Client } from '../../config/aws-export';
import {
  setRDDInfo,
  noneRDDInfo,
} from "../../redux/actions/apiActions";
import FilterIcon from '../../assets/img/filter.png';
import Image from '../../assets/img/tmp/coconut-milk.png';
import { showToastr } from '../../services/themeService';

import { sessionLogout } from '../../redux/actions/sessionActions';

const genderOptions = [
  { value: 0, label: "Male" },
  { value: 1, label: "Female" },
];

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    textAlign: 'left',
  }),
  control: (provided, state) => ({
    // none of react-select's styles are passed to <Control />
    ...provided,
    width: 150,
  }),
}

const getFieldValue = (fieldid, rdd) => {
  let f = rdd?.fields;
  if (f) {
    let value = f.find(f => (f.id == fieldid));
    return value !== undefined ? value.value : "";
  }
  return "";
}


const RDDs = (props) => {

  const page = parseInt(props.match.params.page);

  const rddInfo = useSelector((state) => state.api.rddInfo);
  const fields = useSelector((state) => state.api.fields);
  const calcTypes = useSelector((state) => state.api.calcTypes);

  const history = useHistory();
  const dispatch = useDispatch();

  const [currentRDD, setCurrentRDD] = useState({});
  const [filter, setFilter] = useState('');
  const [editOpen, setEditOpen] = useState(false);
  const [createOpen, setCreateOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [updatedRDD, setUpdatedRDD] = useState({});

  const count = 10;

  

  useEffect(() => {
    
    handlePageChange(page);
  }, [page]);

  const toggle = (type) => {
    if (type === 'edit')
      setEditOpen(!editOpen);
    else if (type === 'create')
      setCreateOpen(!createOpen);
    else
      setDeleteOpen(!deleteOpen);
  }

  const handlePageChange = (val) => {
    dispatch(noneRDDInfo());

    ApiService.getRDDs(val, count, 'code', 'asc', filter)
      .then(data => {
        // if (data && data.message === "Unauthenticated.") {
        //   history.push('/admin');

        //   return;
        // }
        console.log('rdds: ', data);
        dispatch(setRDDInfo(data));
      })
      .catch((err) => {
        console.log('rdds error: ', err);
        if (err.response !== undefined && err.response.status === 401) {
          showToastr('warning', 'Authorization Error', err.response.data.message);
          dispatch(sessionLogout());

          history.push('/');
          return;
        }
      });
  }

  const handleEdit = (code) => {
    var rdds = JSON.parse(JSON.stringify(rddInfo.rdds));
    const rdd = rdds.find(rdd => rdd.code === code);
    console.log('currentRDD', rdd);
    setCurrentRDD(rdd);
    toggle('edit');
  }

  const handleCreate = () => {
    setCurrentRDD({});
    toggle('create');
  }

  const handleDelete = (code) => {
    setCurrentRDD(rddInfo.rdds.find(rdd => rdd.code === code));
    toggle('delete');
  }

  const handleConfirm = async (type) => {
    if (type === 'edit') {
      let fields = updatedRDD;

      if (currentRDD.image) {
        const fileName = Date.now() + '.' + currentRDD.image.name.split('.').pop();

        const data = await ReactS3Client.uploadFile(currentRDD.image, fileName);
        fields.image = data.location;
      }

      fields.typeId = 1;

      ApiService.updateRDD(currentRDD.code, fields)
        .then((data) => {
          if (data.errorType !== undefined) {
            console.log("rdd error:", data)
            showToastr("error", "Error", `Error while creating RDD: ${data.errorMessage}`);
          }
          else {
            console.log(data);

            var rdds = JSON.parse(JSON.stringify(rddInfo.rdds));
            let rdd = rdds.find(rdd => rdd.code == currentRDD.code);
            let index = rdds.indexOf(rdd);
            console.log(index);

            rdds[index] = data;

            dispatch(setRDDInfo({ ...rddInfo, rdds: rdds }));
            toggle('edit');
          }
        })
        .catch((err) => {
          console.error(err);
          if (err.response.status === 401) {
            showToastr('warning', 'Authorization Error', err.response.data.message);
            dispatch(sessionLogout());
            
            history.push('/');
            return;
          }
        });
        
    } else if (type === 'create') {
      if (currentRDD.image) {
        console.log("uploading image...");
        const fileName = Date.now() + '.' + currentRDD.image.name.split('.').pop();

        const data = await ReactS3Client.uploadFile(currentRDD.image, fileName);
        updatedRDD.fields.image = data.location;
        console.log("image location:", data.location);
      }

      ApiService.createRDD(updatedRDD)
        .then((data) => {
          if (data.errorType !== undefined) {
            console.log("rdd error:", data)
            showToastr("error", "Error", `Error while creating RDD: ${data.errorMessage}`);
          }
          else {
            console.log("rdd data:", data);

            var rdds = JSON.parse(JSON.stringify(rddInfo.rdds));
            rdds.push(data);
            dispatch(setRDDInfo({...rddInfo, rdds: rdds}));

            showToastr("info", "Success", "Successfully created RDD");
            toggle("create");
          }
        })
        .catch((err) => {
          console.error(err);
          if (err.response.status === 401) {
            showToastr('warning', 'Authorization Error', err.response.data.message);
            dispatch(sessionLogout());
            history.push('/');
            return;
          }
      });

    } else {
      // ApiService.deleteLanguage(session, currentLanguage.id)
      //   .then((data) => {
      //     console.log(data);
      //     let tmpLanguages = languages.slice();
      //     const curLanguage = languages.filter(language => language.id == currentLanguage.id);
      //     if (curLanguage.length > 0) {
      //       const index = tmpLanguages.indexOf(curLanguage[0]);
      //       console.log(curLanguage[0]);
      //       console.log(index);
      //       if (index > -1) {
      //         tmpLanguages.splice(index, 1);
      //       }
      //     }
      //     console.log('Delete');
      //     console.log(tmpLanguages);

      //     setLanguages(tmpLanguages);
      //   })
      //   .catch((err) => {
      //     console.error(err);
      //   });

      let code = currentRDD.code;

      ApiService.deleteRDD(code)
        .then((data) => {
          if (data.errorType !== undefined) {
            console.log("rdd error:", data)
            showToastr("error", "Error", `Error while deleting RDD: ${data.errorMessage}`);
          }
          else {
            console.log("data:", data);
            setCurrentRDD({});

            var rdds = JSON.parse(JSON.stringify(rddInfo.rdds)).filter(rdd => rdd.code != code);
            dispatch(setRDDInfo({...rddInfo, rdds: rdds}));

            showToastr("info", "Success", "Successfully deleted RDD");
          }
        })
        .catch((err) => {
          console.error(err);
          if (err.response.status === 401) {
            showToastr('warning', 'Authorization Error', err.response.data.message);
            dispatch(sessionLogout());

            history.push('/');
            return;
          }
      });

      toggle('delete');
    }
  }

  const handleValueChange = (type, val) => {
    let tmpUpdatedRDD = JSON.parse(JSON.stringify(updatedRDD));
    tmpUpdatedRDD[type] = val;

    setUpdatedRDD(tmpUpdatedRDD);

    setCurrentRDD({ ...currentRDD, [type]: val });
  }

  const getFieldNamed = (fieldContainer, fieldName) => {
    let fieldKey = Object.keys(fields).find(key => fields[key].name === fieldName);
    if (fieldKey === undefined)
      return undefined;
    
    let result = fieldContainer.fields.find(f => f.id == fieldKey);
    if (result === undefined)
      return undefined; 
    return result.value;
  }

  const handleFieldChange = (id, val) => {
    id = parseInt(id);
    let tmpUpdatedRDD = JSON.parse(JSON.stringify(updatedRDD));
    let tmpFields = tmpUpdatedRDD.fields;
    if (!tmpFields)
      tmpFields = [];

    if (!tmpFields.find(tmpField => tmpField.id == id)) {
      tmpFields.push({
        id: id,
        value: val,
      });
    } else {
      tmpFields.find(tmpField => tmpField.id == id).value = val;
    }

    tmpUpdatedRDD.fields = tmpFields;
    setUpdatedRDD(tmpUpdatedRDD);

    let curRDD = JSON.parse(JSON.stringify(currentRDD));
    let fields = curRDD.fields;

    if (fields) {
      let f = fields.find(field => field.id === id);
      if (f) {
        f.value = val;
      } else {
        fields.push({value: val, id: id});
      }
    }
    else {
      curRDD = {...curRDD, fields: [{value: val, id: id}]};
    }

    setCurrentRDD(curRDD);
  }

  const handleFilter = (e) => {
    handlePageChange(1);

    e.preventDefault();
  }


  if (!rddInfo || !calcTypes) {
    return (
      <Container fluid className="vh-50 d-flex justify-content-center align-items-center">
        <Spinner color="primary" size="lg" className="mr-2" />
      </Container>
    );
  }

  let typeOptions = [];
  for (const id in calcTypes) {
    typeOptions.push({
      value: id,
      label: calcTypes[id].name,
    });
  }

  const getRDDModal = (title, toggleName, isOpen) => {
    return (<Modal
      isOpen={isOpen}
      toggle={() => toggle(toggleName)}
      centered
    >
      <ModalHeader toggle={() => toggle(toggleName)}>
        {title}
      </ModalHeader>
      <ModalBody className="text-center m-3">
        <ImageUploader img={currentRDD.image ?? Image} onChange={(img) => setCurrentRDD({ ...currentRDD, image: img })} />
          {
            toggleName === "edit" ?
              (
                <p className="mb-3 mt-2">
                  <span style={{ color: '#3490DD', marginRight: 5, }}>
                    RDD Code:
                  </span>
                  <span>
                    {currentRDD.code}
                  </span>
                </p>
              ) : null
          }
        <div style={{ color: '#6B6C72', fontSize: 14, fontWeight: 400, maxHeight: 400, minHeight: 300, overflowY: 'scroll', padding: '0 15%', }}>
          {
            toggleName !== "edit" ?
              (
                <p className="m-0 d-flex justify-content-between align-items-center" style={{ padding: '10px 0', }}>
                  <span>
                    RDD Code:
                  </span>
                  <Input
                    type="text"
                    aria-label="Title"
                    className="p-0 text-center"
                    style={{ width: 150, height: 25, padding: '0 10px' }}
                    value={currentRDD.code || ''}
                    onChange={(val) => handleValueChange('code', val.target.value)}
                  />
                </p>
              ) : null
          }
          <p className="m-0 d-flex justify-content-between align-items-center" style={{ padding: '10px 0', }}>
            <span>
              Title
            </span>
            <Input
              type="text"
              aria-label="Title"
              className="p-0 text-center"
              style={{ width: 150, height: 25, padding: '0 10px' }}
              value={currentRDD.title || ''}
              onChange={(val) => handleValueChange('title', val.target.value)}
            />
          </p>
          <div className="m-0 d-flex justify-content-between align-items-center" style={{ borderTop: '1px solid #dee2e6', padding: '10px 0', }}>
            <span>
              Gender
            </span>
            <Select
              className="react-select-container"
              classNamePrefix="react-select"
              options={genderOptions}
              isSearchable={false}
              styles={customStyles}
              defaultValue={genderOptions.find(genderOption => genderOption.label === currentRDD.gender)}
              onChange={(item) => handleValueChange('gender', item.label)}
            />
          </div>
          {/*
            <div className="m-0 d-flex justify-content-between align-items-center" style={{ borderTop: '1px solid #dee2e6', padding: '10px 0', }}>
              <span>
                Type
              </span>
              <Select
                className="react-select-container"
                classNamePrefix="react-select"
                options={typeOptions}
                isSearchable={false}
                styles={customStyles}
                defaultValue={typeOptions.find(typeOption => typeOption.value === currentRDD.type ? currentRDD.type.id : 1)}
                onChange={(item) => handleValueChange('typeId', item.value)}
              />
            </div>
          */}
          <p className="m-0 d-flex justify-content-between align-items-center" style={{ borderTop: '1px solid #dee2e6', padding: '10px 0', }}>
            <span>
              Start Age
            </span>
            <Input
              type="text"
              aria-label="Start-Age"
              className="p-0 text-right"
              style={{ width: 70, height: 25, padding: '0 10px' }}
              value={currentRDD.startAge || ''}
              onChange={(val) => handleValueChange('startAge', val.target.value)}
            />
          </p>
          <p className="m-0 d-flex justify-content-between align-items-center" style={{ borderTop: '1px solid #dee2e6', padding: '10px 0', }}>
            <span>
              End Age
            </span>
            <Input
              type="text"
              aria-label="End-Age"
              className="p-0 text-right"
              style={{ width: 70, height: 25, padding: '0 10px' }}
              value={currentRDD.endAge || ''}
              onChange={(val) => handleValueChange('endAge', val.target.value)}
            />
          </p>
          {
            Object.keys(fields).map((field, index) => {
              return fields[field].visible ? (
                <div className="m-0 d-flex justify-content-between align-items-center" style={{ borderTop: '1px solid #dee2e6', padding: '10px 0', }} key={index}>
                  <span>
                    {`${fields[field].display} (${fields[field].unit})`}
                  </span>
                  <Input
                    type="text"
                    aria-label="Value"
                    className="p-0 text-right"
                    style={{ width: 70, height: 30, padding: '0 10px' }}
                    value={getFieldValue(field, currentRDD)}
                    onChange={(val) => handleFieldChange(field, val.target.value)}
                  />
                </div>
              ) : null
            })
            /*currentRDD.fields ?
              currentRDD.fields.map((field, index) => (
                field.value ? (
                  <p className="m-0 d-flex justify-content-between align-items-center" style={{ borderTop: '1px solid #dee2e6', padding: '10px 0', }} key={index}>
                    <span>
                      {`${fields[field.id].display} (${fields[field.id].unit})`}
                    </span>
                    <Input
                      type="text"
                      aria-label="Value"
                      className="p-0 text-right"
                      style={{ width: 70, height: 25, padding: '0 10px' }}
                      value={field.value}
                      onChange={(val) => handleFieldChange(field.id, val.target.value)}
                    />
                  </p>
                ) : null
              )) : null*/
          }
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={() => toggle(toggleName)}>
          Cancel
        </Button>{" "}
        <Button color="primary" onClick={() => handleConfirm(toggleName)}>
          OK
        </Button>
      </ModalFooter>
    </Modal>)
  }


  return (
    <div>
      <div className="mt-4 d-flex align-items-center justify-content-between">
        <div>
          <h3 style={{ color: '#3490DD', }}>RDD (Recommend Daily Doses)</h3>
          <p>Total {rddInfo.count}</p>
        </div>
        <div className="d-flex" style={{ height: 50, width: '60%', }}>
          <Form className="d-flex" style={{ width: '100%', }} onSubmit={e => handleFilter(e)}>
            <div style={{ display: 'flex', backgroundColor: 'white', alignItems: 'center', marginRight: 30, width: '100%', }}>
              <Input
                type="text"
                placeholder="Search"
                aria-label="Search"
                className="form-control-no-border mr-sm-2"
                value={filter}
                onChange={val => setFilter(val.target.value)}
              />
              <FontAwesomeIcon icon={faSearch} style={{ color: '#6B6C72' }} className="mr-3" />
            </div>
            <Button color="warning" style={{ width: 146, minWidth: 100, height: '100%', marginRight: 25, }}>
              <img src={FilterIcon} className="mr-2" /> Filter
            </Button>
          </Form>
          <Button color="primary" style={{ width: 161, minWidth: 120, height: '100%', }} onClick={handleCreate}>
            <FontAwesomeIcon icon={faPlus} className="mr-2" /> Insert New
          </Button>
        </div>
      </div>

      <Table responsive striped hover>
        <thead>
          <tr style={{ backgroundColor: '#6B6C72', color: 'white', }}>
            <th scope="col" style={{ borderRadius: '6px 0 0 0' }}>CodeRDD</th>
            <th scope="col">Title</th>
            <th scope="col">Gender</th>
            <th scope="col">Start Age</th>
            <th scope="col">End Age</th>
            <th scope="col">Type</th>
            <th scope="col">Energy (kcal)</th>
            <th scope="col" style={{ borderRadius: '0 6px 0 0' }}>Action</th>
          </tr>
        </thead>
        <tbody>
          {
            rddInfo.rdds.map((rdd, index) => (
              <tr onClick={() => history.push(`/admin/rdd/detail/${rdd.code}-${rdd.typeId}`, { page })} key={index}>
                <td>
                  {rdd.code}
                </td>
                <td>
                  {rdd.title}
                </td>
                <td>
                  {rdd.gender}
                </td>
                <td>
                  {rdd.startAge}
                </td>
                <td>
                  {rdd.endAge}
                </td>
                <td>
                  {calcTypes[rdd.typeId]?.name}
                </td>
                <td>
                  {getFieldNamed(rdd, "energy_cal")}
                </td>
                <td onClick={e => e.stopPropagation()}>
                  <FontAwesomeIcon className="table-action-button" icon={faPencilAlt} style={{ color: '#FC9C52', fontSize: 16, marginRight: 10, }} onClick={() => handleEdit(rdd.code)} />
                  <FontAwesomeIcon className="table-action-button" icon={faTrashAlt} style={{ color: '#FF2048', fontSize: 16, }} onClick={() => handleDelete(rdd.code)} />
                </td>
              </tr>
            ))
          }
        </tbody>
      </Table>

      <Pagination
        value={page}
        maxPage={rddInfo.pages}
        onChange={(val) => history.push(`/admin/rdds/page=${val}`)}
      />

      { getRDDModal("Edit RDD", "edit", editOpen) }
      { getRDDModal("Create RDD", "create", createOpen) }
      {/* <Modal
        isOpen={editOpen}
        toggle={() => toggle('edit')}
        centered
      >
        <ModalHeader toggle={() => toggle('edit')}>
          Edit RDD
        </ModalHeader>
        <ModalBody className="text-center m-3">
          <ImageUploader img={currentRDD.image ?? Image} onChange={(img) => setCurrentRDD({ ...currentRDD, image: img })} />
          <p className="mb-3 mt-2">
            <span style={{ color: '#3490DD', marginRight: 5, }}>
              RDD Code:
            </span>
            <span>
              {currentRDD.code}
            </span>
          </p>
          <div style={{ color: '#6B6C72', fontSize: 14, fontWeight: 400, maxHeight: 400, minHeight: 300, overflowY: 'scroll', padding: '0 15%', }}>
            <p className="m-0 d-flex justify-content-between align-items-center" style={{ padding: '10px 0', }}>
              <span>
                Title
              </span>
              <Input
                type="text"
                aria-label="Title"
                className="p-0 text-center"
                style={{ width: 150, height: 25, padding: '0 10px' }}
                value={currentRDD.title}
                onChange={(val) => handleValueChange('title', val.target.value)}
              />
            </p>
            <div className="m-0 d-flex justify-content-between align-items-center" style={{ borderTop: '1px solid #dee2e6', padding: '10px 0', }}>
              <span>
                Gender
              </span>
              <Select
                className="react-select-container"
                classNamePrefix="react-select"
                options={genderOptions}
                isSearchable={false}
                styles={customStyles}
                defaultValue={genderOptions.find(genderOption => genderOption.label === currentRDD.gender)}
                onChange={(item) => handleValueChange('gender', item.label)}
              />
            </div>
            <div className="m-0 d-flex justify-content-between align-items-center" style={{ borderTop: '1px solid #dee2e6', padding: '10px 0', }}>
              <span>
                Type
              </span>
              <Select
                className="react-select-container"
                classNamePrefix="react-select"
                options={typeOptions}
                isSearchable={false}
                styles={customStyles}
                defaultValue={typeOptions.find(typeOption => typeOption.value === currentRDD.type ? currentRDD.type.id : 1)}
                onChange={(item) => handleValueChange('typeId', item.value)}
              />
            </div>
            <p className="m-0 d-flex justify-content-between align-items-center" style={{ borderTop: '1px solid #dee2e6', padding: '10px 0', }}>
              <span>
                Start Age
              </span>
              <Input
                type="text"
                aria-label="Start-Age"
                className="p-0 text-right"
                style={{ width: 70, height: 25, padding: '0 10px' }}
                value={currentRDD.startAge}
                onChange={(val) => handleValueChange('startAge', val.target.value)}
              />
            </p>
            <p className="m-0 d-flex justify-content-between align-items-center" style={{ borderTop: '1px solid #dee2e6', padding: '10px 0', }}>
              <span>
                End Age
              </span>
              <Input
                type="text"
                aria-label="End-Age"
                className="p-0 text-right"
                style={{ width: 70, height: 25, padding: '0 10px' }}
                value={currentRDD.endAge}
                onChange={(val) => handleValueChange('endAge', val.target.value)}
              />
            </p>
            {
              currentRDD.fields ?
                currentRDD.fields.map((field, index) => (
                  field.value ? (
                    <p className="m-0 d-flex justify-content-between align-items-center" style={{ borderTop: '1px solid #dee2e6', padding: '10px 0', }} key={index}>
                      <span>
                        {`${fields[field.id].display} (${fields[field.id].unit})`}
                      </span>
                      <Input
                        type="text"
                        aria-label="Value"
                        className="p-0 text-right"
                        style={{ width: 70, height: 25, padding: '0 10px' }}
                        value={field.value}
                        onChange={(val) => handleFieldChange(field.id, val.target.value)}
                      />
                    </p>
                  ) : null
                )) : null
            }
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => toggle('edit')}>
            Cancel
          </Button>{" "}
          <Button color="primary" onClick={() => handleConfirm('edit')}>
            OK
          </Button>
        </ModalFooter>
      </Modal> */}

      <Modal
        isOpen={deleteOpen}
        toggle={() => toggle('delete')}
        centered
      >
        <ModalHeader toggle={() => toggle('delete')}>
          Delete RDD
        </ModalHeader>
        <ModalBody className="text-center m-3">
          <p className="mb-0">
            Are you sure want to delete this RDD?
          </p>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => toggle('delete')}>
            Cancel
          </Button>{" "}
          <Button color="danger" onClick={() => handleConfirm('delete')}>
            Delete
          </Button>
        </ModalFooter>
      </Modal>
    </div >
  );
}

export default RDDs;
